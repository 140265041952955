import { Badge, Button, Divider, Flex, Grid, Input, Text } from "@mantine/core";
import { IconCheck } from "@tabler/icons";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { tugrug } from "../../utils";
import { Form } from "../form";

type Props = {
  data: any;
  setIndex: any;
};

const schema = yup.object({});

type IFormData = {
  name: string;
};

export function ScfFundingSupplierLedInformationForm({ data, setIndex }: Props) {
  const navigate = useNavigate();
  const { productTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const [datas] = React.useState<IFormData>({
    name: "",
  });

  const onSubmit = () => {};

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={datas}>
      {() => {
        return (
          <>
            <Flex sx={{ position: "relative" }} direction="column" gap="md" mt={20}>
              <Grid>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Партнер ТТД">
                    <Text color="indigo" fw={600}>
                      {data?.anchor?.regNumber || "0000000"}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Партнер нэр">
                    <Flex mih={50} gap="md" justify="flex-start" align="flex-start" direction="row" wrap="wrap">
                      <Text fw={600} color="indigo">
                        {data?.anchor?.partner?.businessName},
                      </Text>
                      <Text fw={500} c="dimmed">
                        {data?.anchor?.partner?.refCode || "-"}
                      </Text>
                    </Flex>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Нийлүүлэгч бизнесийн нэр">
                    <Flex mih={50} gap="md" justify="flex-start" align="flex-start" direction="row" wrap="wrap">
                      <Text fw={600} color="indigo">
                        {data?.anchor?.profileName},
                      </Text>
                      <Text fw={500} c="dimmed">
                        {data?.anchor?.refCode || "-"}
                      </Text>
                    </Flex>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Хөтөлбөрийн нэр">
                    <Flex mih={50} gap="md" justify="flex-start" align="flex-start" direction="row" wrap="wrap">
                      <Text fw={600} color="indigo">
                        {data?.name || "-"},
                      </Text>
                      <Text fw={500} c="dimmed">
                        {data?.refCode || "-"}
                      </Text>
                    </Flex>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Бүтээгдэхүүний төрөл">
                    <Text color="indigo" fw={600}>
                      <Badge size="md" radius="sm" variant="outline">
                        {productTypes.find((item: IReference) => item.code === data.productType)?.name}
                      </Badge>
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Бүтээгдэхүүний нэр">
                    <Flex mih={50} gap="md" justify="flex-start" align="flex-start" direction="row" wrap="wrap">
                      <Text fw={600} color="indigo">
                        {data?.product?.name || "-"},
                      </Text>
                      <Text fw={500} c="dimmed">
                        {data?.product?.refCode || "-"}
                      </Text>
                    </Flex>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={12}>
                  <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} my="xs" label="Санхүүжилтийн лимит" />
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Нийт санхүүжилтийн лимит">
                    <Text color="indigo" fw={600}>
                      {tugrug(data?.programLimit || 0)}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Ашиглах боломжит лимит">
                    <Text color="indigo" fw={600}>
                      {tugrug(data.availableAmount || 0)}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Ашигласан лимит">
                    <Text color="indigo" fw={600}>
                      {tugrug(data.utilizedAmount || 0)}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Санхүүжилт хүссэн дүн">
                    <Flex style={{ alignItems: "center" }} gap="xs">
                      <>
                        <IconCheck size={20} color="green" />
                      </>
                      <Text color="indigo" fw={600}>
                        {tugrug(data.requestedAmount || 0)}
                      </Text>
                    </Flex>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Хүсэлт дэх нэхэмжлэх үлдэгдэл">
                    <Text color="indigo" fw={600}>
                      {tugrug(data.invAmount || 0)}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Хүсэлт дэх нэхэмжлэхийн тоо">
                    <Text color="indigo" fw={600}>
                      {data.invCount || 0}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={12}>
                  <Divider color="gray" />
                </Grid.Col>
              </Grid>
              <Flex direction="row" justify="space-between">
                <Button variant="default" onClick={() => navigate("/scf/funding-provided/SUPPLIER")}>
                  Буцах
                </Button>
                <Flex gap="md">
                  <Button onClick={() => setIndex("2")}>Үргэлжүүлэх</Button>
                </Flex>
              </Flex>
            </Flex>
          </>
        );
      }}
    </Form>
  );
}
