import { Avatar, Badge, Button, Flex, Grid, Input, Paper, Select } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { IconArrowNarrowLeft, IconCalendar } from "@tabler/icons";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import useSWR from "swr";
import { AccountApi, TransactionApi } from "../../apis";
import noimage from "../../assets/no-image.png";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { ITransaction } from "../../interfaces/ITransaction";
import { tugrug } from "../../utils";
import { dateSecFormat } from "../../utils/date";
import { renderName } from "../../utils/render-name";
import { PageLayout } from "../layout";
import { ColumnType, Table } from "../table";
import RenderText from "../ui/render-text";

const inOutTypes = [
  { code: "ALL", name: "Бүгд" },
  { code: "DEBIT", name: "Орлого" },
  { code: "CREDIT", name: "Зарлага" },
];

export const SfcDcfAccountTransactionList = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { paymentMethod, transactionTypes, currencies, transactionStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const breadcrumbs = useBreadcrumb(params.type);
  const noDate = new Date();
  const [valueStart, setValueStart] = React.useState<Date>();
  const [valueEnd, setValueEnd] = React.useState<Date>();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [filters, setFilter] = React.useState<any>({
    accountId: `${params.id}`,
    inOutType: "ALL",
    startDate: "",
    endDate: "",
    type: "",
    paymentMethod: "",
    status: "",
  });

  const { data } = useSWR(
    `/api/account/get/${params.id}`,
    async () => {
      if (
        (user?.permissions || []).filter((c) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_ACCOUNT_DISBURSEMENT" && c.isView).length > 0 ||
        (user?.permissions || []).filter((c) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_ACCOUNT_FEE" && c.isView).length > 0 ||
        (user?.permissions || []).filter((c) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_ACCOUNT_REPAYMENT" && c.isView).length > 0
      ) {
        let res = await AccountApi.get(`${params.id}`);
        return res;
      }
      return [];
    },
    {},
  );

  const columns = useHeader({
    paymentMethod,
    transactionTypes,
    currencies,
    transactionStatus,
  });

  return (
    <>
      <PageLayout
        title="Гүйлгээний түүх"
        subTitle="Сонгосон дансны орлого, зарлагын гүйлгээний түүх."
        breadcrumb={breadcrumbs}
        extra={[
          <Button size="sm" key={3} onClick={() => navigate(`/scf-dcf-product/accounting/${params.type}`)} variant="light" leftIcon={<IconArrowNarrowLeft />}>
            Данс жагсаалтад буцах 111
          </Button>,
          <Button
            key={1}
            onClick={() =>
              setFilter({
                accountId: `${params.id}`,
                inOutType: "ALL",
                startDate: null,
                endDate: null,
                type: null,
                paymentMethod: null,
                status: null,
              })
            }>
            Цэвэрлэх
          </Button>,
        ]}>
        <Paper p="sm" withBorder>
          <Grid>
            {(user?.permissions || []).filter((c) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_ACCOUNT_DISBURSEMENT" && c.isView).length > 0 ||
              (user?.permissions || []).filter((c) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_ACCOUNT_FEE" && c.isView).length > 0 ||
              ((user?.permissions || []).filter((c) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_ACCOUNT_REPAYMENT" && c.isView).length > 0 && (
                <Grid.Col xs={12} sm={6} lg={4}>
                  <Input.Wrapper label="Сонгосон дансны дугаар:">
                    <Input readOnly value={`${data?.number}, ${data?.name}`} placeholder="Данс, дугаар" />
                  </Input.Wrapper>
                </Grid.Col>
              ))}
            <Grid.Col xs={12} sm={6} lg={4}>
              <Input.Wrapper label="Гүйлгээний төрөл:">
                <Select
                  value={filters.type}
                  onChange={(e: any) => {
                    setFilter({ ...filters, type: e });
                  }}
                  clearable
                  placeholder="Сонгох"
                  data={transactionTypes.map((c) => ({
                    label: c.name,
                    value: c.code,
                  }))}
                />
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col xs={12} sm={6} lg={4}>
              <Grid>
                <Grid.Col md={6} sm={12}>
                  <DatePicker
                    maxDate={valueEnd !== null && undefined ? noDate : valueEnd}
                    value={filters.startDate}
                    onChange={(e: any) => {
                      setFilter({ ...filters, startDate: e });
                      setValueStart(e);
                    }}
                    label="Эхлэх огноо:"
                    placeholder="сонгох"
                    icon={<IconCalendar size={16} />}
                  />
                </Grid.Col>
                <Grid.Col md={6} sm={12}>
                  <DatePicker
                    minDate={valueStart}
                    maxDate={noDate}
                    value={filters.endDate}
                    onChange={(e: any) => {
                      setFilter({ ...filters, endDate: e });
                      setValueEnd(e);
                    }}
                    label="Дуусах огноо:"
                    placeholder="сонгох"
                    icon={<IconCalendar size={16} />}
                  />
                </Grid.Col>
              </Grid>
            </Grid.Col>
            <Grid.Col xs={12} sm={6} lg={4}>
              <Input.Wrapper label="Төлбөрийн хэрэгсэл:">
                <Select
                  value={filters.paymentMethod}
                  clearable
                  onChange={(e) => {
                    setFilter({ ...filters, paymentMethod: e });
                  }}
                  placeholder="Сонгох"
                  data={paymentMethod.map((c) => ({
                    label: c.name,
                    value: c.code,
                  }))}
                />
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col xs={12} sm={6} lg={4}>
              <Input.Wrapper label="Орлого, Зарлага:">
                <Select
                  value={filters.inOutType}
                  onChange={(e) => {
                    setFilter({ ...filters, inOutType: e || "ALL" });
                  }}
                  defaultValue="ALL"
                  clearable
                  placeholder="Сонгох"
                  data={inOutTypes.map((c) => ({
                    label: c.name,
                    value: c.code,
                  }))}
                />
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col xs={12} sm={6} lg={4}>
              <Input.Wrapper label="Гүйлгээний статус сонгох:">
                <Select
                  value={filters.status}
                  onChange={(e) => {
                    setFilter({ ...filters, status: e });
                  }}
                  clearable
                  placeholder="Сонгох"
                  data={transactionStatus.map((c) => ({
                    label: c.name,
                    value: c.code,
                  }))}
                />
              </Input.Wrapper>
            </Grid.Col>
          </Grid>
        </Paper>
        <Paper p="sm" withBorder mt="sm">
          <Table name="account.history.list" columns={columns} filters={filters} loadData={(data) => TransactionApi.list(data!)} />
        </Paper>
      </PageLayout>
    </>
  );
};

const useHeader = ({
  paymentMethod,
  transactionTypes,
  currencies,
  transactionStatus,
}: {
  paymentMethod: IReference[];
  transactionTypes: IReference[];
  currencies: IReference[];
  transactionStatus: IReference[];
}): ColumnType<ITransaction>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    title: "Огноо, цаг",
    dataIndex: "image",
    render: (record) => {
      return <RenderText text={dateSecFormat(record?.createdAt)} />;
    },
  },
  {
    title: "Лавлах код",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return <RenderText text={record?.refCode || "-"} />;
    },
  },
  {
    title: "Гүйлгээний төрөл",
    sorter: true,
    dataIndex: "type",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline">
          {transactionTypes.find((item: IReference) => item.code === record?.type)?.name}
        </Badge>
      );
    },
  },
  {
    title: "Төлбөрийн хэрэгсэл",
    sorter: true,
    dataIndex: "type",
    render: (record) => {
      if (record.paymentMethod !== null) {
        return (
          <Badge size="md" radius="sm" variant="outline">
            {(paymentMethod || []).find((item: IReference) => item.code === record?.paymentMethod)?.name || "-"}
          </Badge>
        );
      } else {
        return "-";
      }
    },
  },
  {
    title: "Гүйлгээ дүн",
    sorter: true,
    dataIndex: "totalAmount",
    render: (record) => {
      return <RenderText text={tugrug(record?.totalAmount || 0)} isAmount color={record?.inOutType === "CREDIT" ? "red.5" : "green.5"} />;
    },
  },
  {
    title: "Валют",
    sorter: true,
    dataIndex: "debitAccountCurrency",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline">
          {currencies.find((item: IReference) => item.code === record?.debitAccountCurrency)?.name ?? "-"}
        </Badge>
      );
    },
  },
  {
    title: "Гүйлгээний утга",
    sorter: true,
    dataIndex: "description",
    render: (record) => {
      return <RenderText text={record?.description || "-"} />;
    },
  },
  {
    title: "Гүйлгээний статус",
    sorter: true,
    dataIndex: "description",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            border: `${(transactionStatus || []).find((c: any) => c?.code === record.transactionStatus)?.color} 1px solid`,
            color: `${(transactionStatus || []).find((c: any) => c?.code === record.transactionStatus)?.color}`,
          }}>
          {(transactionStatus || []).find((item: IReference) => item.code === record.transactionStatus)?.name ?? "-"}
        </Badge>
      );
    },
  },
  {
    title: "Гүйлгээ хийсэн",
    sorter: true,
    dataIndex: "description",
    render: (record) => {
      return (
        <Flex align="center" gap="xs">
          <Avatar src={record?.user?.avatar !== null ? record?.user?.avatar : noimage} />
          <RenderText text={renderName(record?.user)} />
        </Flex>
      );
    },
  },
  {
    title: "Харьцсан дансны дугаар",
    sorter: true,
    dataIndex: "debitAccountNumber",
    render: (record) => {
      return <RenderText text={record?.debitAccountNumber || "-"} />;
    },
  },
  {
    title: "Харьцсан дансны нэр",
    sorter: true,
    dataIndex: "debitAccountName",
    render: (record) => {
      return <RenderText text={record?.debitAccountName || "-"} />;
    },
  },
  {
    title: "Харьцсан дансны банк",
    sorter: true,
    dataIndex: "debitAccountBank",
    render: (record) => {
      return <RenderText text={record?.debitAccountBank || "-"} />;
    },
  },
];

const useBreadcrumb = (type: any) => [
  {
    to: "/",
    label: "Үндсэн",
  },
  { label: "Санхүүжилтийн бүтээгдэхүүн" },
  {
    to: `/scf-dcf-product/accounting/${type}`,
    label: "Данс тохиргоо",
  },
  {
    label: "Гүйлгээний түүх",
  },
];
