import { Avatar, Badge, Button, Divider, Flex, Modal, Tooltip } from "@mantine/core";
import { IconCirclePlus, IconHistory, IconReceipt } from "@tabler/icons";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { AccountApi, GeneralApi } from "../../apis";
import noimage from "../../assets/no-image.png";
import { FormLayout } from "../../components/layout";
import { ColumnType, ITableRef, Table } from "../../components/table";
import { IAccount } from "../../interfaces/IAccount";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IPermissions } from "../../interfaces/IPermissions";
import { initGeneral } from "../../store/General";
import { dateSecFormat } from "../../utils/date";
import { renderName } from "../../utils/render-name";
import RenderText from "../ui/render-text";
import { AccountForm } from "./account-form";

export const FocusAccountList = () => {
  const ref = useRef<ITableRef>(null);
  const navigate = useNavigate();
  const [action, setAction] = React.useState<string[]>([]);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { currencies } = useSelector((state: { general: IGeneral }) => state.general);
  const dispatch = useDispatch();
  const columns = useHeader({
    currencies,
    user,
    onClick: (key, record) => {
      switch (key) {
        case "history": {
          navigate(`/scf-dcf-product/accounting/REPAYMENT/transaction-history/${record.id}`);
          break;
        }
        case "statement": {
          navigate(`/scf-dcf-product/accounting/REPAYMENT/statement/${record.id}`);
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
  };

  const reload = async () => {
    const resInit = await GeneralApi.init();
    dispatch(initGeneral(resInit));
    ref.current?.reload();
  };

  return (
    <>
      <FormLayout
        title="Эргэн төлөлт"
        subTitle="Эргэн төлөлт төвлөрүүлсэн дансны жагсаалт"
        extra={[
          <div key={1}>
            {(user?.permissions || []).filter((c) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_ACCOUNT_REPAYMENT" && c.isCreate).length > 0 && (
              <Button
                size="sm"
                key={1}
                onClick={() => {
                  setAction(["CREATE", "REPAYMENT"]);
                }}
                variant="light"
                leftIcon={<IconCirclePlus />}>
                Данс нэмэх
              </Button>
            )}
          </div>,
        ]}>
        <Divider mb="lg" />
        <Table
          ref={ref}
          name="account.repayment.list"
          columns={columns}
          filters={{ query: "", type: "REPAYMENT" }}
          loadData={(data) => AccountApi.list(data!)}
        />
      </FormLayout>
      <Modal opened={action[0] === "CREATE" || action[0] === "UPDATE"} onClose={() => setAction([])} withCloseButton={false} centered>
        <AccountForm title="Эргэн төлөлтийн данс" subTitle="Эргэн төлөлтийн данс нэмэх" action={action} reload={reload} onCancel={onCancel} />
      </Modal>
    </>
  );
};

const useHeader = ({ onClick, currencies, user }: { onClick: (key: string, record: IAccount) => void; currencies: any; user: any }): ColumnType<IAccount>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="xs">
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_ACCOUNT_REPAYMENT_TRX_LIST" && c.isCreate)
            .length > 0 ? (
            <Tooltip label="Гүйлгээний түүх" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("history", record)}>
                <IconHistory />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="Гүйлгээний түүх" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("", record)} disabled>
                <IconHistory />
              </Button>
            </Tooltip>
          )}
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_ACCOUNT_REPAYMENT_STATEMENT").length > 0 ? (
            <Tooltip label="Хуулга харах" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("statement", record)}>
                <IconReceipt />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="Хуулга харах" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("", record)} disabled>
                <IconReceipt />
              </Button>
            </Tooltip>
          )}
        </Flex>
      );
    },
  },
  {
    title: "Банкны нэр",
    sorter: true,
    dataIndex: "bankName",
    render: (record) => {
      return <RenderText text={record.bankName || "-"} />;
    },
  },
  {
    title: "Дансны дугаар",
    sorter: true,
    dataIndex: "number",
    render: (record) => {
      return <RenderText text={record.number || "-"} />;
    },
  },
  {
    title: "Дансны нэр",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return <RenderText text={record.name || "-"} />;
    },
  },
  {
    title: "Оноосон нэр",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return <RenderText text={record.shortName || "-"} />;
    },
  },
  {
    title: "Валют",
    sorter: true,
    dataIndex: "currency",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline">
          {currencies.find((c: any) => c.code === record.currency)?.name || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Статус",
    sorter: true,
    dataIndex: "accountStatus",
    render: (record) => {
      const isActive = record.accountStatus === "ACTIVE";
      const label = isActive ? "Идэвхтэй" : "Идэвхгүй";
      return (
        <Badge color={isActive ? "green.5" : "red.5"} size="md" radius="sm" variant="outline">
          {label}
        </Badge>
      );
    },
  },
  {
    title: "Бүртгэсэн",
    sorter: true,
    dataIndex: "",
    render: (record) => {
      return (
        <Flex align="center" gap="xs">
          <Avatar src={record?.regUser?.avatar !== null ? record?.regUser?.avatar : noimage} />
          <RenderText text={renderName(record?.regUser)} />
        </Flex>
      );
    },
  },
  {
    title: "Бүртгэсэн огноо",
    sorter: true,
    dataIndex: "",
    render: (record) => {
      return <RenderText text={dateSecFormat(record.createdAt)} />;
    },
  },
  {
    title: "Идэвхжсэн огноо",
    sorter: true,
    dataIndex: "quantity",
    render: (record) => {
      return <RenderText text={dateSecFormat(record.updatedAt)} />;
    },
  },
];
