import { Avatar, Flex } from "@mantine/core";
import React from "react";
import { useSelector } from "react-redux";
import { ObservationApi } from "../../apis";
import noimage from "../../assets/no-image.png";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReminder } from "../../interfaces/IReminder";
import { IReference } from "../../models/General";
import { renderName } from "../../utils/render-name";
import { ColumnType, Table } from "../table";
import RenderText from "../ui/render-text";

type Props = {
  action: any;
};

export function PaymentReminderHistoryList({ action }: Props) {
  const { reminderConnectTypes, reminderTypes, reminderLevels, reminderResponses, reminderResults } = useSelector(
    (state: { general: IGeneral }) => state.general,
  );

  const [filters] = React.useState({ repaymentId: action && action[1]?.id, isEmail: `${false}` });

  const columns = useHeader({
    reminderConnectTypes,
    reminderTypes,
    reminderLevels,
    reminderResponses,
    reminderResults,
  });

  return <Table name="collection-observation-mail.list" columns={columns} filters={filters} loadData={(data) => ObservationApi.reminder(data!)} />;
}

const useHeader = ({
  reminderConnectTypes,
  reminderTypes,
  reminderLevels,
  reminderResponses,
  reminderResults,
}: {
  reminderConnectTypes: any;
  reminderTypes: any;
  reminderLevels: any;
  reminderResponses: any;
  reminderResults: any;
}): ColumnType<IReminder>[] => [
  {
    title: "#",
    width: 30,
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    title: "Холбогдсон төрөл",
    dataIndex: "connectType",
    render: (record) => {
      return <RenderText text={reminderConnectTypes.find((item2: IReference) => item2.code === record.connectType)?.name ?? "-"} />;
    },
  },
  {
    title: "Сануулгын төрөл",
    dataIndex: "reminderType",
    render: (record) => {
      return <RenderText text={reminderTypes.find((item2: IReference) => item2?.code === record?.reminderType)?.name || "-"} />;
    },
  },
  {
    title: "Сануулга зэрэглэл",
    dataIndex: "reminderLevel",
    render: (record) => {
      return <RenderText text={reminderLevels.find((item2: IReference) => item2?.code === record?.reminderLevel)?.name || "-"} />;
    },
  },
  {
    title: "Амлалтын хариу",
    dataIndex: "response",
    render: (record) => {
      return <RenderText text={reminderResponses.find((item2: IReference) => item2?.code === record?.response)?.name || "-"} />;
    },
  },
  {
    title: "Харилцагчид очих тэмдэглэл",
    dataIndex: "clientText",
    render: (record) => {
      return <RenderText text={record.clientText || "-"} style={{ width: 250, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} />;
    },
  },
  {
    title: "Дотоод тэмдэглэл",
    dataIndex: "internalText",
    render: (record) => {
      return <RenderText text={record.internalText || "-"} style={{ width: 250, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} />;
    },
  },
  {
    title: "Холбогдсон үр дүн",
    dataIndex: "result",
    render: (record) => {
      return <RenderText text={reminderResults.find((item2: IReference) => item2?.code === record?.result)?.name || "-"} />;
    },
  },
  {
    title: "Холбоо барьсан",
    dataIndex: "",
    render: (record) => {
      return (
        <Flex align="center" gap="xs">
          <Avatar src={record?.remindedUser?.avatar !== null && record?.remindedUser !== null ? record?.remindedUser?.avatar : noimage} />
          <RenderText text={renderName(record?.remindedUser)} />
        </Flex>
      );
    },
  },
];
