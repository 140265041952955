import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import React from "react";

const containerStyle = {
  width: "100%",
  height: "100%",
};

// SUKHTALBAI
const DEFAULT_CENTER = {
  lat: 47.9179864,
  lng: 106.9175798,
};

type Props = {
  center?: any;
  setMarked?: any;
  setFieldValue?: any;
};

const GoogleMapPin = ({
  center,
  setFieldValue,
}: // pinDatas,
// setMarked,
Props) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAMJL2fW018Xr_zmKEPeFFKq7qOoASYTSI",
  });

  const [, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map: any) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback() {
    setMap(null);
  }, []);

  const handleMapClick = (e: any) => {
    const closureLat = e.latLng.lat();
    const closureLng = e.latLng.lng();

    if (setFieldValue) {
      setFieldValue("locationLat", closureLat);
      setFieldValue("locationLng", closureLng);
    }
  };

  return isLoaded ? (
    <>
      <GoogleMap
        onClick={handleMapClick}
        mapContainerStyle={containerStyle}
        center={center?.lat && center.lng ? center : DEFAULT_CENTER}
        zoom={14}
        onLoad={onLoad}
        onUnmount={onUnmount}>
        <Marker
          position={
            center?.lat && center?.lng
              ? {
                  lat: center?.lat,
                  lng: center?.lng,
                }
              : DEFAULT_CENTER
          }
        />
      </GoogleMap>
    </>
  ) : (
    <></>
  );
};

export default React.memo(GoogleMapPin);
