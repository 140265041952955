import { Avatar, Box, Checkbox, Flex } from "@mantine/core";
import { LbfProgramApi } from "../../apis";
import noimage from "../../assets/no-image.png";
import { renderName } from "../../utils/render-name";
import { ColumnType, Table } from "../table";
import RenderText from "../ui/render-text";

export function FundingProgramBuyerLedAncorList({ filters, values, setFieldValue }: { filters: any; values: any; setFieldValue: any }) {
  const columns = useHeader({ values, setFieldValue });

  return <Table name="lbf-program.sme.add.anchor.list" columns={columns} filters={filters} loadData={(data) => LbfProgramApi.anchorList(data!)} />;
}

const useHeader = ({ values, setFieldValue }: any): ColumnType<any>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    title: "Сонгох",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Checkbox
          checked={record?.id === values?.anchor?.id}
          onChange={(e) => {
            if (record?.id === values?.anchor?.id) {
              setFieldValue("businessId", undefined);
              setFieldValue("outAccId", undefined);
              setFieldValue("inAccId", undefined);
              setFieldValue("anchor", undefined);
              setFieldValue("bankAccounts", undefined);
              setFieldValue("checkedAnchor", undefined);
            } else {
              setFieldValue("businessId", e?.currentTarget?.value);
              setFieldValue("anchor", record);
              setFieldValue("bankAccounts", record?.bankAccounts);
              setFieldValue("outAccId", undefined);
              setFieldValue("inAccId", undefined);
              setFieldValue("checkedAnchor", `${record?.profileName || "-"}, #${record?.refCode || "-"}`);
            }
          }}
          value={record?.id}
        />
      );
    },
  },
  {
    title: "Партнер ТТД",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return <RenderText text={record.regNumber || "-"} />;
    },
  },
  {
    title: "Партнер",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Box>
          <RenderText text={record.partnerName || "-"} />
          <RenderText text={record.partnerEmail || "-"} color="#141517" />
        </Box>
      );
    },
  },
  {
    title: "Бизнес код",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return <RenderText text={`#${record.refCode || "-"}}`} />;
    },
  },
  {
    title: "Худалдан авагч бизнес",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return <RenderText text={record.profileName || "-"} />;
    },
  },
  {
    title: "Санхүү",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Flex align="center" gap="xs">
          <Avatar src={record?.finUser?.avatar !== null ? record?.finUser?.avatar : noimage} />
          <RenderText text={renderName(record?.finUser)} />
        </Flex>
      );
    },
  },
  {
    title: "Санхүү имэйл",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return <RenderText text={record?.finUser?.email || "-"} />;
    },
  },
  {
    title: "Санхүү утас",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return <RenderText text={record?.finUser?.phone} />;
    },
  },
];
