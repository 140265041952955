import { Button, Divider, Flex, Grid, Text } from "@mantine/core";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { percent, percentZero } from "../../utils";
import { Form } from "../form";
import { NumberCurrencyField } from "../form/number-currency-field";
import { NumberPercentField } from "../form/number-percent-field";
import { RadioGroupField } from "../form/radio-group-field";
import { SelectField } from "../form/select-field";
import { SwitchField } from "../form/switch-field";
import { TextField } from "../form/text-field";

const schema = yup.object({
  borrowerType: yup.string().required("Заавал бөглөнө!").nullable(),
  suppInterestType: yup.string().required("Заавал бөглөнө!").nullable(),
  suppInterestPercent: yup.string().matches(percent, "Та дахин нэг шалгана уу!").typeError("Тоо оруулна уу.").required("Заавал бөглөнө!"),
  suppInterestMinAmount: yup.string().typeError("Тоо оруулна уу!").required("Заавал бөглөнө!").nullable(),
  suppAppFee: yup.string().typeError("Тоо оруулна уу!").required("Заавал бөглөнө!").nullable(),
  suppDisbursementFee: yup.string().matches(percentZero, "Та дахин шалгана уу!").typeError("Тоо оруулна уу!").required("Заавал бөглөнө!").nullable(),
  recourseDays: yup.number().when(["recourseTerm"], (recourseTerm) => {
    if (recourseTerm) {
      return yup.number().typeError("Тоо оруулна уу!").max(999, "Та 999-с бага утга уруулна уу!").required("Заавал бөглөнө!").nullable();
    }
    return yup.string().nullable();
  }),
  suppTerminatePeriod: yup.number().typeError("Тоо оруулна уу!").max(999, "Та 999-с бага утга уруулна уу!").required("Заавал бөглөнө!").nullable(),

  buyerInterestPercent: yup.number().when(["borrowerType"], (borrowerType) => {
    if (borrowerType === "BUYER") {
      return yup.string().matches(percent, "Та дахин нэг шалгана уу!").typeError("Тоо оруулна уу.").required("Заавал бөглөнө!");
    }
    return yup.string().nullable();
  }),
  buyerInterestType: yup.number().when(["borrowerType"], (borrowerType) => {
    if (borrowerType === "BUYER") {
      return yup.string().required("Заавал бөглөнө!").nullable();
    }
    return yup.string().nullable();
  }),
  buyerInterestMinAmount: yup.number().when(["borrowerType"], (borrowerType) => {
    if (borrowerType === "BUYER") {
      return yup.string().typeError("Тоо оруулна уу!").required("Заавал бөглөнө!").nullable();
    }
    return yup.string().nullable();
  }),
  buyerDisbursementFee: yup.number().when(["borrowerType"], (borrowerType) => {
    if (borrowerType === "BUYER") {
      return yup.string().matches(percentZero, "Та дахин шалгана уу!").typeError("Тоо оруулна уу!").required("Заавал бөглөнө!").nullable();
    }
    return yup.string().nullable();
  }),
  buyerAppFee: yup.number().when(["borrowerType"], (borrowerType) => {
    if (borrowerType === "BUYER") {
      return yup.string().typeError("Тоо оруулна уу!").required("Заавал бөглөнө!").nullable();
    }
    return yup.string().nullable();
  }),
  buyerTermDays: yup.number().when(["borrowerType"], (borrowerType) => {
    if (borrowerType === "BUYER") {
      return yup.number().typeError("Тоо оруулна уу!").max(999, "Та 999-с бага утга уруулна уу!").required("Заавал бөглөнө!").nullable();
    }
    return yup.string().nullable();
  }),
});

export type LbfProductFinanceData = {
  borrowerType: string;
  suppInterestPercent: number;
  suppInterestType: string;
  suppInterestMinAmount: number;
  suppDisbursementFee: number;
  suppAppFee: number;
  recourseTerm: boolean;
  recourseDays: number;
  suppTerminatePeriod: number;
  toReview: boolean;

  buyerInterestPercent: number;
  buyerInterestDays: number;
  buyerInterestMinAmount: number;
  buyerDisbursementFee: number;
  buyerAppFee: number;
  buyerTermDays: number;
};

export function ProductBuyerLedFinanceForm({ onSubmit: _onSubmit, action }: { onSubmit: (values: LbfProductFinanceData, type: string) => void; action: any }) {
  const navigate = useNavigate();
  const [type, setType] = React.useState<string>("");
  const { borrowerTypes, lbfProductInterestTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const [data] = React.useState<LbfProductFinanceData>({
    borrowerType: "",
    suppInterestPercent: "",
    suppInterestType: "",
    suppInterestMinAmount: "",
    suppDisbursementFee: "",
    suppAppFee: "",
    recourseTerm: false,
    recourseDays: "",
    suppTerminatePeriod: "",
    buyerInterestPercent: "",
    buyerInterestDays: "",
    buyerInterestMinAmount: "",
    buyerDisbursementFee: "",
    buyerAppFee: "",
    buyerTermDays: "",
    toReview: false,
    ...(action && action[0] === "update"
      ? {
          ...action[1],
          borrowerType: action && !action[1].borrowerType ? "SUPPLIER_ONLY" : action[1].borrowerType,
          suppInterestMinAmount: action[1].suppInterestMinAmount ? parseInt(action[1].suppInterestMinAmount, 10) : "",
          suppDisbursementFee: action[1].suppDisbursementFee ? parseInt(action[1].suppDisbursementFee, 10) : "",
          suppAppFee: action[1].suppAppFee ? parseInt(action[1].suppAppFee, 10) : "",
          recourseDays: action[1].recourseDays ? parseInt(action[1].recourseDays, 10) : "",
          suppTerminatePeriod: action[1].suppTerminatePeriod ? parseInt(action[1].suppTerminatePeriod, 10) : "",

          buyerInterestMinAmount: action[1].buyerInterestMinAmount ? parseInt(action[1].buyerInterestMinAmount, 10) : "",
          buyerDisbursementFee: action[1].buyerDisbursementFee ? parseInt(action[1].buyerDisbursementFee, 10) : "",
          buyerAppFee: action[1].buyerAppFee ? parseInt(action[1].buyerAppFee, 10) : "",
          buyerTermDays: action[1].buyerTermDays ? parseInt(action[1].buyerTermDays, 10) : "",
        }
      : {}),
  });

  const onSubmit = (value: LbfProductFinanceData) => {
    _onSubmit(value, type);
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values }) => {
        return (
          <Flex sx={{ position: "relative" }} direction="column" gap="md">
            <Grid>
              <Grid.Col span={12} mt="xs">
                <RadioGroupField
                  label="Санхүүжилт авах тал"
                  name="borrowerType"
                  options={(borrowerTypes || []).map((c: any) => ({
                    label: c.blName,
                    value: c.code,
                  }))}
                />
              </Grid.Col>

              <Grid.Col span={12} mt="xs">
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="НИЙЛҮҮЛЭГЧ ТӨЛӨХ ХҮҮ, ШИМТГЭЛ" />
              </Grid.Col>

              <Grid.Col span={4}>
                <NumberPercentField
                  name="suppInterestPercent"
                  label="Санхүүжилтийн хүү:"
                  placeholder="Хувийг оруулна уу"
                  max={100}
                  min={0}
                  precision={2}
                  required
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <SelectField
                  name="suppInterestType"
                  label="Хүү тооцох хоног:"
                  placeholder="сонгох"
                  options={(lbfProductInterestTypes || []).map((c: IReference) => ({
                    label: c.name,
                    value: c.code,
                  }))}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <NumberCurrencyField
                  precision={2}
                  min={0}
                  max={999999999999}
                  name="suppInterestMinAmount"
                  label="Миннимум шимтгэлийн дүн:"
                  placeholder="Дүнг оруулна уу"
                  required
                />
              </Grid.Col>

              <Grid.Col span={4}>
                <NumberPercentField
                  name="suppDisbursementFee"
                  label="Олголтын шимтгэл:"
                  placeholder="Хувийг оруулна уу"
                  max={100}
                  min={0}
                  precision={2}
                  required
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <NumberCurrencyField
                  precision={2}
                  min={0}
                  max={999999999999}
                  name="suppAppFee"
                  label="Өргөдлийн хураамж:"
                  placeholder="Үнийн дүнг оруулна уу"
                  required
                />
              </Grid.Col>
              <Grid.Col span={4}></Grid.Col>

              <Grid.Col span={4}>
                <Flex direction="column" gap={3} mt={4}>
                  <Text fz={14} fw={500} c="#212529">
                    Буцаан дуудах эсэх:
                  </Text>
                  <SwitchField name="recourseTerm" size="lg" />
                </Flex>
              </Grid.Col>
              <Grid.Col span={4}>
                <TextField
                  name="recourseDays"
                  label="Буцаан дуудах хугацаа:"
                  placeholder="Хоногийг оруулна уу"
                  disabled={values.recourseTerm ? false : true}
                  rightSection={
                    <Text c="dimmed" style={{ marginRight: "25px", fontSize: 15, color: "gray", opacity: 0.8 }}>
                      {"Хоног"}
                    </Text>
                  }
                  withAsterisk={values.recourseTerm ? true : false}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextField
                  name="suppTerminatePeriod"
                  label="Санхүүжилт зогсоох хугацаа:"
                  placeholder="Хоногийг оруулна уу"
                  rightSection={
                    <Text c="dimmed" style={{ marginRight: "25px", fontSize: 15, color: "gray", opacity: 0.8 }}>
                      {"Хоног"}
                    </Text>
                  }
                />
              </Grid.Col>

              {values?.borrowerType === "BUYER" && (
                <>
                  <Grid.Col span={12} mt="xs">
                    <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ХУДАЛДАН АВАГЧИЙН ТӨЛӨХ, ШИМТГЭЛ" />
                  </Grid.Col>

                  <Grid.Col span={4}>
                    <NumberPercentField
                      name="buyerInterestPercent"
                      label="Санхүүжилтийн хүү:"
                      placeholder="Хувийг оруулна уу"
                      max={100}
                      min={0}
                      precision={2}
                      required
                    />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <SelectField
                      name="buyerInterestType"
                      label="Хүү тооцох хоног:"
                      placeholder="сонгох"
                      options={(lbfProductInterestTypes || []).map((c: IReference) => ({
                        label: c.name,
                        value: c.code,
                      }))}
                    />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <NumberCurrencyField
                      precision={2}
                      min={0}
                      max={999999999999}
                      name="buyerInterestMinAmount"
                      label="Миннимум шимтгэлийн дүн:"
                      placeholder="Дүнг оруулна уу"
                      required
                    />
                  </Grid.Col>

                  <Grid.Col span={4}>
                    <NumberPercentField
                      name="buyerDisbursementFee"
                      label="Олголтын шимтгэл:"
                      placeholder="Хувийг оруулна уу"
                      max={100}
                      min={0}
                      precision={2}
                      required
                    />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <NumberCurrencyField
                      precision={2}
                      min={0}
                      max={999999999999}
                      name="buyerAppFee"
                      label="Өргөдлийн хураамж:"
                      placeholder="Үнийн дүнг оруулна уу"
                      required
                    />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <TextField
                      name="buyerTermDays"
                      label="Худалдан авагчид өгөх хоног:"
                      placeholder="Хоногийг оруулна уу"
                      rightSection={
                        <Text c="dimmed" style={{ marginRight: "25px", fontSize: 15, color: "gray", opacity: 0.8 }}>
                          {"Хоног"}
                        </Text>
                      }
                    />
                  </Grid.Col>
                </>
              )}
            </Grid>
            <Divider color="gray" />
            <Flex direction="row" justify="space-between">
              <Button variant="default" onClick={() => navigate("/cf/product/BUYER")}>
                Болих
              </Button>
              <Flex gap="md">
                <Button variant="outline" type="submit" onClick={() => setType("SAVE")}>
                  Хадгалах
                </Button>
                <Button type="submit" onClick={() => setType("REVIEWED")}>
                  Хяналтад илгээх
                </Button>
              </Flex>
            </Flex>
          </Flex>
        );
      }}
    </Form>
  );
}
