import { Badge, Box, Button, Divider, Flex, LoadingOverlay, Modal, Tooltip } from "@mantine/core";
import { IconMailForward, IconSettings } from "@tabler/icons";
import React from "react";
import { useSelector } from "react-redux";
import { RequestApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IPermissions } from "../../interfaces/IPermissions";
import { IStaffRole } from "../../interfaces/IStaffRole";
import { IReference } from "../../models/General";
import { Dialog } from "../../utils/confirm-modal";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { ColumnType, ITableRef, Table } from "../table";
import RenderText from "../ui/render-text";
import { AncorDetailRoleForm } from "./role-form";

export function AncorDetailRoleList({ data, onSuccess }: { data: any; onSuccess?: () => void }) {
  const ref = React.useRef<ITableRef>(null);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [action, setAction] = React.useState<string[]>([]);
  const { clientStaffLoginTypes, clientFinanceRoleStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [loading, setLoading] = React.useState<boolean>(false);

  const columns = useHeader({
    data,
    user,
    clientStaffLoginTypes,
    clientFinanceRoleStatus,
    onClick: (key, item) => {
      switch (key) {
        case "success": {
          setAction(["success", item as any, data.id]);
          break;
        }
        case "invite": {
          Dialog.confirm("Та хэрэглэгчид урилга илгээх гэж байна. Та итгэлтэй байна уу!", async (key) => {
            switch (key) {
              case "confirm": {
                setLoading(true);
                try {
                  await RequestApi.userInvite(data.id as string, {
                    userId: item?.id,
                  });
                  Message.success("Бүртгэл баталгаажуулах и-мэйл илгээгдлээ.");
                } catch (err) {
                  Message.error((err as HttpHandler)?.message!);
                }
                try {
                } catch (err) {
                  Message.error((err as HttpHandler)?.message!);
                }
                onSuccess && onSuccess();
                setLoading(false);
                break;
              }
              default:
            }
          });
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
  };

  if (loading) return <LoadingOverlay visible={loading} />;

  return (
    <>
      <Divider color="gray" mt="md" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="БАНКТАЙ ХАРИЛЦАХ АЖИЛТАН" mb="xs" />
      <Table ref={ref} name="ancor.detail.supplier.list" columns={columns} dataSource={data?.users?.rows} pagination={false} />
      <Modal withCloseButton={false} opened={action[0] === "success"} onClose={() => setAction([])} size={"1200px"} centered>
        <AncorDetailRoleForm reloadTab={onSuccess} action={action} onCancel={onCancel} />
      </Modal>
    </>
  );
}

const useHeader = ({
  onClick,
  data,
  user,
  clientStaffLoginTypes,
  clientFinanceRoleStatus,
}: {
  onClick: (key: string, record: IStaffRole) => void;
  data: any;
  user: any;
  clientStaffLoginTypes: IReference[];
  clientFinanceRoleStatus: IReference[];
}): ColumnType<IStaffRole>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => {
      return <RenderText text={index + 1} />;
    },
  },
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="xs">
          {((user.permissions || []).filter((c: IPermissions) => c.module === "CLIENT" && c.code === "CLIENT_ANCHOR_USER_RESPOND").length > 0 ||
            (user.permissions || []).filter((c: IPermissions) => c.module === "CLIENT" && c.code === "CLIENT_SME_USER_RESPOND").length > 0) &&
          record?.financeRoleStatus === "ASSIGNED" ? (
            <Tooltip label="Эрх зөвшөөрөх" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("success", record)}>
                <IconSettings />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="Эрх зөвшөөрөх" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("", record)} disabled>
                <IconSettings />
              </Button>
            </Tooltip>
          )}
          {((user.permissions || []).filter((c: IPermissions) => c.module === "CLIENT" && c.code === "CLIENT_SME_USER_INVITE").length > 0 ||
            (user.permissions || []).filter((c: IPermissions) => c.module === "CLIENT" && c.code === "CLIENT_ANCHOR_USER_INVITE").length > 0) &&
          record?.financeRoleStatus === "APPROVED" &&
          !record.isEmailVerified ? (
            <Tooltip label="Урилга илгээх" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("invite", record)}>
                <IconMailForward />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="Урилга илгээх" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("", record)} disabled>
                <IconMailForward />
              </Button>
            </Tooltip>
          )}
        </Flex>
      );
    },
  },
  {
    title: "ТТД",
    sorter: true,
    dataIndex: "profileName",
    render: () => {
      return <RenderText text={data?.regNumber || "-"} />;
    },
  },
  {
    title: "Партнерийн нэр",
    sorter: true,
    dataIndex: "finUser",
    render: (_record) => {
      return (
        <Box>
          <RenderText text={data?.businessName || "-"} />
          <RenderText text={data?.refCode || "-"} color="#144e9d" />
        </Box>
      );
    },
  },
  {
    title: "Регистрийн №",
    sorter: true,
    dataIndex: "profileName",
    render: (record) => {
      return <RenderText text={record?.registerNo || "-"} />;
    },
  },
  {
    title: "Хэрэглэгчийн CIF",
    sorter: true,
    dataIndex: "profileName",
    render: (record) => {
      return <RenderText text={record?.staffCif || "-"} />;
    },
  },
  {
    title: "Ургийн овог",
    sorter: true,
    dataIndex: "profileName",
    render: (record) => {
      return <RenderText text={record?.familyName || "-"} />;
    },
  },
  {
    title: "Овог",
    sorter: true,
    dataIndex: "profileName",
    render: (record) => {
      return <RenderText text={record?.lastName || "-"} />;
    },
  },
  {
    title: "Нэр",
    sorter: true,
    dataIndex: "profileName",
    render: (record) => {
      return <RenderText text={record?.firstName || "-"} />;
    },
  },
  {
    title: "Имэйл",
    sorter: true,
    dataIndex: "partnerEmail",
    render: (record) => {
      return <RenderText text={record?.email || "-"} />;
    },
  },
  {
    title: "Утас",
    sorter: true,
    dataIndex: "partnerPhone",
    render: (record) => {
      return <RenderText text={record?.phone || "-"} />;
    },
  },
  {
    title: "Эрх статус",
    sorter: true,
    dataIndex: "partnerPhone",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            border: `${(clientFinanceRoleStatus || []).find((c: IReference) => c?.code === record.financeRoleStatus)?.color} 1px solid`,
            color: `${(clientFinanceRoleStatus || []).find((c: IReference) => c?.code === record.financeRoleStatus)?.color}`,
          }}>
          {(clientFinanceRoleStatus || []).find((c: IReference) => c?.code === record.financeRoleStatus)?.name ?? "-"}
        </Badge>
      );
    },
  },
  {
    title: "Төлөв",
    sorter: true,
    dataIndex: "partnerPhone",
    render: (record) => {
      return <RenderText text={record?.dehubStatus || "-"} />;
    },
  },
  {
    title: "Finance user",
    sorter: true,
    dataIndex: "partnerPhone",
    render: (record) => {
      return <RenderText text={record?.username || "-"} />;
    },
  },
  {
    title: "Login type",
    sorter: true,
    dataIndex: "partnerPhone",
    render: (record) => {
      return <RenderText text={clientStaffLoginTypes?.find((item: IReference) => item.code === record.staffLoginType)?.name || "-"} />;
    },
  },
  {
    title: "Имэйл баталгаажилт",
    sorter: true,
    dataIndex: "partnerPhone",
    render: (record) => {
      const isEmailVerified = record?.isEmailVerified;
      const label = isEmailVerified ? "Тийм" : "Үгүй";
      return (
        <Badge size="md" radius="sm" variant="outline" color={isEmailVerified ? "green.5" : "red.5"}>
          {label}
        </Badge>
      );
    },
  },
  {
    title: "Утас баталгаажилт",
    sorter: true,
    dataIndex: "partnerPhone",
    render: (record) => {
      const isPhoneVerified = record?.isEmailVerified;
      const label = isPhoneVerified ? "Тийм" : "Үгүй";
      return (
        <Badge size="md" radius="sm" variant="outline" color={isPhoneVerified ? "green.5" : "red.5"}>
          {label}
        </Badge>
      );
    },
  },
  {
    title: "Нууц үг",
    sorter: true,
    dataIndex: "partnerPhone",
    render: (record) => {
      return <RenderText text={record?.password || "-"} />;
    },
  },
];
