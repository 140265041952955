import { Avatar, Badge, Box, Button, Drawer, Flex, SegmentedControl, Space, Text, Tooltip } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { IconInfoSquare } from "@tabler/icons";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { CompletedRequestApi } from "../../apis";
import noimage from "../../assets/no-image.png";
import { IAuth } from "../../interfaces/IAuth";
import { IFundingRequest } from "../../interfaces/IFundingRequest";
import { IGeneral } from "../../interfaces/IGeneral";
import { IPermissions } from "../../interfaces/IPermissions";
import { IReference } from "../../models/General";
import { ScfFundingProvidedDetail } from "../../pages/scf/funding-provided/detail-tabs";
import { tugrug } from "../../utils";
import { dateSecFormat } from "../../utils/date";
import { renderName } from "../../utils/render-name";
import { PageFilter } from "../layout";
import { ColumnType, ITableRef, Table } from "../table";
import RenderText from "../ui/render-text";

const res = [
  {
    name: "Бүгд",
    code: "",
    color: "yellow",
  },
  {
    name: "Buyer",
    code: "BUYER",
    color: "green",
  },
  {
    name: "Supplier",
    code: "SUPPLIER",
    color: "blue",
  },
];

export const ScfFundingProvidedSupplierList = () => {
  const ref = useRef<ITableRef>(null);
  const { currencies, requestStatus, fundingInstructionStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [status, setStatus] = useState("");
  const [statusDebounced] = useDebouncedValue(status, 400);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [action, setAction] = useState<string[]>([]);

  const columns = useHeader({
    user,
    currencies,
    requestStatus,
    fundingInstructionStatus,
    tugrug,
    onClick: (key, record) => {
      switch (key) {
        case "detail": {
          setAction(["detail", record.id]);
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
  };

  return (
    <>
      <PageFilter
        left={[
          <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap" key={1}>
            <Text size={14} color="#868E96">
              Олголтын төрөл
            </Text>
            <SegmentedControl
              onChange={(e) => setStatus(e)}
              value={status}
              data={(res || []).map((item: IReference, index: number) => {
                return {
                  value: item.code || "",
                  label: (
                    <Badge variant="dot" color={item.color} style={{ border: "none" }} key={`${index}`}>
                      {item.name}
                    </Badge>
                  ),
                };
              })}
            />
          </Flex>,
        ]}
      />
      <Space h={20} />
      <Table
        ref={ref}
        name="scf.funding.funding.provided.list"
        columns={columns}
        filters={{ query: "", productType: "SUPPLIER_LED", type: statusDebounced }}
        loadData={(data) => CompletedRequestApi.list(data!)}
      />

      <Drawer
        opened={action[0] === "detail"}
        onClose={() => {
          setAction([]);
        }}
        position="right"
        withCloseButton={false}
        styles={{ drawer: { overflowY: "scroll" } }}
        size="80%">
        <ScfFundingProvidedDetail action={action} onCancel={onCancel} setAction={setAction} />
      </Drawer>
    </>
  );
};

const useHeader = ({
  user,
  requestStatus,
  fundingInstructionStatus,
  tugrug,
  currencies,
  onClick,
}: {
  user: any;
  requestStatus: IReference[];
  fundingInstructionStatus: IReference[];
  tugrug: any;
  currencies: any;
  onClick: (key: string, record: IFundingRequest) => void;
}): ColumnType<IFundingRequest>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="xs">
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "SCF" && c.code === "SCF_REQUEST_MANAGEMENT_BL").length > 0 ? (
            <Tooltip label="Дэлгэрэнгүй" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
                <IconInfoSquare />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="Дэлгэрэнгүй" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("", record)} disabled>
                <IconInfoSquare />
              </Button>
            </Tooltip>
          )}
        </Flex>
      );
    },
  },
  {
    title: "Хүсэлтийн #",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Box>
          <RenderText text={record.refCode || "-"} />
          <RenderText text={dateSecFormat(record.createdAt)} color="#3e166e" />
        </Box>
      );
    },
  },
  {
    title: "Санхүүжилт авсан",
    sorter: true,
    dataIndex: "requestedBusiness",
    render: (record) => {
      return (
        <Box>
          <RenderText text={record?.requestedBusiness?.profileName || "-"} />
          <RenderText text={record?.requestedBusiness?.refCode || "-"} color="#3e166e" />
        </Box>
      );
    },
  },
  {
    title: "Нийлүүлэгч",
    sorter: true,
    dataIndex: "requestedBusiness",
    render: (record) => {
      return (
        <Box>
          <RenderText text={record?.requestedBusiness?.partner?.businessName || "-"} />
          <RenderText text={record?.requestedBusiness?.partner?.refCode || "-"} color="#3e166e" />
        </Box>
      );
    },
  },
  {
    title: "Хөтөлбөр нэр",
    sorter: true,
    dataIndex: "program",
    render: (record) => {
      return (
        <Box>
          <RenderText text={record?.program?.name || "-"} />
          <RenderText text={record?.program?.refCode || "-"} color="#3e166e" />
        </Box>
      );
    },
  },
  {
    title: "Бүтээгдэхүүн",
    sorter: true,
    dataIndex: "product",
    render: (record) => {
      return (
        <Box>
          <RenderText text={record?.product?.name || "-"} />
          <RenderText text={record?.product?.refCode || "-"} color="#3e166e" />
        </Box>
      );
    },
  },
  {
    title: "Хүсэлтийн дүн",
    sorter: true,
    dataIndex: "requestedAmount",
    render: (record) => {
      return <RenderText text={tugrug(record.requestedAmount || "0")} />;
    },
  },
  {
    title: "Валют",
    sorter: true,
    dataIndex: "currency",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline">
          {currencies.find((item: IReference) => item.code === record?.currency)?.name ?? "-"}
        </Badge>
      );
    },
  },
  {
    title: "Хүсэлт төлөв",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            border: `${(requestStatus || []).find((c: any) => c?.code === record.requestStatus)?.color} 1px solid`,
            color: `${(requestStatus || []).find((c: any) => c?.code === record.requestStatus)?.color}`,
          }}>
          {(requestStatus || []).find((c: IReference) => c.code === record.requestStatus)?.name ?? "-"}
        </Badge>
      );
    },
  },
  {
    title: "Хоног",
    sorter: true,
    dataIndex: "financePeriod",
    render: (record) => {
      return <RenderText text={record.financePeriod || "0"} />;
    },
  },
  {
    title: "Санхүүжилт шимтгэл",
    sorter: true,
    dataIndex: "calculatedFeeAmount",
    render: (record) => {
      return <RenderText text={tugrug(record.calculatedFeeAmount || "0")} isAmount />;
    },
  },
  {
    title: "Банк шимтгэл",
    sorter: true,
    dataIndex: "bankFeeAmount",
    render: (record) => {
      return <RenderText text={tugrug(record.bankFeeAmount || "0")} isAmount />;
    },
  },
  {
    title: "Нэхэмжлэх",
    sorter: true,
    dataIndex: "invRefCode",
    render: (record) => {
      return <RenderText text={record.invRefCode || "-"} />;
    },
  },
  {
    title: "Нэхэмжлэх үлдэгдэл",
    sorter: true,
    dataIndex: "invAmountToPay",
    render: (record) => {
      return <RenderText text={tugrug(record.invAmountToPay || "0")} isAmount />;
    },
  },
  {
    title: "Нэхэмжлэх огноо",
    sorter: true,
    dataIndex: "invCreatedAt",
    render: (record) => {
      return <RenderText text={dateSecFormat(record.invCreatedAt)} />;
    },
  },
  {
    title: "Баталсан огноо",
    sorter: true,
    dataIndex: "invConfirmedDate",
    render: (record) => {
      return <RenderText text={dateSecFormat(record.invConfirmedDate)} />;
    },
  },
  {
    title: "Нэхэмжлэх баталсан",
    sorter: true,
    dataIndex: "invConfirmedUser",
    render: (record) => {
      return (
        <Flex align="center" gap="xs">
          <Avatar src={record?.invConfirmedUser?.avatar !== null ? record?.invConfirmedUser?.avatar : noimage} />
          <RenderText text={renderName(record?.invConfirmedUser)} />
        </Flex>
      );
    },
  },
  {
    title: "Худ.авагч бизнес",
    sorter: true,
    dataIndex: "invReceiverBusiness",
    render: (record) => {
      return (
        <Box>
          <RenderText text={record.type === "SUPPLIER" ? record?.invReceiverBusiness?.profileName : record?.requestedBusiness?.profileName} />
          <RenderText text={record.type === "SUPPLIER" ? record?.invReceiverBusiness?.refCode : record?.requestedBusiness?.refCode} color="#3e166e" />
        </Box>
      );
    },
  },
  {
    title: "Худалдан авагч",
    sorter: true,
    dataIndex: "partner",
    render: (record) => {
      return (
        <Box>
          <RenderText
            text={record.type === "SUPPLIER" ? record?.invReceiverBusiness?.partner?.businessName : record?.requestedBusiness?.partner?.businessName}
          />
          <RenderText
            text={record.type === "SUPPLIER" ? record?.invReceiverBusiness?.partner?.refCode : record?.requestedBusiness?.partner?.refCode}
            color="#3e166e"
          />
        </Box>
      );
    },
  },
  {
    title: "Хүсэлт илгээсэн",
    sorter: true,
    dataIndex: "requestedUser",
    render: (record) => {
      return (
        <Flex align="center" gap="xs">
          <Avatar src={record?.requestedUser?.avatar !== null ? record?.requestedUser?.avatar : noimage} />
          <RenderText text={renderName(record?.requestedUser)} />
        </Flex>
      );
    },
  },
  {
    title: "Олголтын төлөв",
    sorter: true,
    dataIndex: "fundingInstructionStatus",
    render: (_record) => {
      return (
        <Badge size="md" radius="sm" variant="outline" color={(fundingInstructionStatus || []).find((item: IReference) => item.code === "DISBURSED")?.color}>
          {(fundingInstructionStatus || []).find((item: IReference) => item.code === "DISBURSED")?.name ?? "-"}
        </Badge>
      );
    },
  },
  {
    title: "Банк ажилтан",
    sorter: true,
    dataIndex: "respondedUser",
    render: (record) => {
      return (
        <Flex align="center" gap="xs">
          <Avatar src={record?.respondedUser?.avatar !== null ? record?.respondedUser?.avatar : noimage} />
          <RenderText text={renderName(record?.respondedUser)} />
        </Flex>
      );
    },
  },
  {
    title: "Олгосон дүн",
    sorter: true,
    dataIndex: "approvedAmount",
    render: (record) => {
      return <RenderText text={tugrug(record.approvedAmount || "0")} isAmount />;
    },
  },
  {
    title: "Шийдвэр огноо",
    sorter: true,
    dataIndex: "respondedDate",
    render: (record) => {
      return <RenderText text={dateSecFormat(record?.respondedDate)} />;
    },
  },
  {
    title: "Олгосон огноо",
    sorter: true,
    dataIndex: "disbursedDate",
    render: (record) => {
      return <RenderText text={dateSecFormat(record?.disbursedDate)} />;
    },
  },
  {
    title: "Эргэн төлөх дүн",
    sorter: true,
    dataIndex: "repaymentAmount",
    render: (record) => {
      return <RenderText text={tugrug(record.repaymentAmount || "0")} isAmount />;
    },
  },
  {
    title: "Эргэн төлөх огноо",
    sorter: true,
    dataIndex: "repaymentDate",
    render: (record) => {
      return <RenderText text={dateSecFormat(record?.repaymentInv?.repaymentDate)} />;
    },
  },
  {
    title: "Эргэн төлөх нэхэмжлэх",
    sorter: true,
    dataIndex: "repaymentInv",
    render: (record) => {
      return <RenderText text={record?.repaymentInv?.refCode || "-"} />;
    },
  },
];
