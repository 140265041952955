import { Avatar, Box, Checkbox, Flex } from "@mantine/core";
import { IconPhoto } from "@tabler/icons";
import React from "react";
import { LbfParticipantApi } from "../../apis";
import noimage from "../../assets/no-image.png";
import { IParticipant } from "../../interfaces/IParticipant";
import { renderName } from "../../utils/render-name";
import { ColumnType, Table } from "../table";
import RenderText from "../ui/render-text";

export function FundingProgramSupplierLedSmeStep({ filters, setSelectedSmes }: any) {
  const [selection, setSelection] = React.useState<any[]>([]);

  const columns = useHeader({
    onChangeChecked: (checked: boolean, record: any) => {
      let selecteds = selection;

      if (checked) {
        selecteds = [...selecteds, record];
      } else {
        selecteds = selecteds.filter((s) => s.id !== record.id);
      }

      setSelection(selecteds);
      setSelectedSmes(selecteds);
    },
  });

  return <Table name="sme.program.onboarding.list" columns={columns} filters={filters} loadData={(data) => LbfParticipantApi.selectList(data!)} />;
}

const useHeader = ({ onChangeChecked }: any): ColumnType<IParticipant>[] => [
  {
    title: "",
    width: "30px",
    render: (record, index) => <Checkbox name={`smes[${index}].checked`} onChange={(e) => onChangeChecked(e.target.checked, record)} />,
  },
  {
    title: "#",
    width: "30px",
    render: (_r, index) => {
      return <RenderText text={index + 1} />;
    },
  },
  {
    title: "CIF",
    sorter: true,
    dataIndex: "clientCode",
    render: (record) => {
      return <RenderText text={record?.clientCode || "-"} />;
    },
  },
  {
    title: "ТТД",
    sorter: true,
    dataIndex: "regNumber",
    render: (record) => {
      return <RenderText text={record?.regNumber} />;
    },
  },
  {
    title: "Партнер нэр",
    sorter: true,
    dataIndex: "partnerName",
    render: (record) => {
      return (
        <Box>
          <RenderText text={record?.partnerName || "-"} />
          <RenderText text={record?.partnerRefCode || "-"} color="#3e166e" />
        </Box>
      );
    },
  },
  {
    title: "Худалдан авагчийн нэр",
    sorter: true,
    dataIndex: "partnerName",
    render: (record) => {
      return (
        <Box>
          <RenderText text={record?.profileName || "-"} />
          <RenderText text={record?.refCode || "-"} color="#3e166e" />
        </Box>
      );
    },
  },
  {
    title: "Худалдан авагчийн санхүү",
    sorter: true,
    dataIndex: "partnerName",
    render: (record) => {
      return (
        <Flex align="center" gap="xs">
          <Avatar src={record?.finUser?.avatar !== null ? record?.finUser?.avatar : noimage} size={42}>
            <IconPhoto />
          </Avatar>
          <Box>
            <RenderText text={renderName(record?.finUser)} />
            <RenderText text={record?.finUser?.phone || "-"} />
          </Box>
        </Flex>
      );
    },
  },
  {
    title: "Худалдан авагчийн имэйл",
    sorter: true,
    dataIndex: "partnerName",
    render: (record) => {
      return <RenderText text={record?.finUser?.email || "-"} />;
    },
  },
  {
    title: "Худалдан авагчийн орлогын данс",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Flex align="center" gap="xs">
          <Avatar src={record?.smeInAcc?.icon !== null ? record?.smeInAcc?.icon : noimage} size={42}>
            <IconPhoto />
          </Avatar>
          <Box>
            <RenderText text={record?.smeInAcc?.bankName || "-"} color="#3e166e" />
            <RenderText text={record?.smeInAcc?.number || "-"} />
          </Box>
        </Flex>
      );
    },
  },
  {
    title: "Худалдан авагчийн зарлагын данс",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Flex align="center" gap="xs">
          <Avatar src={record?.smeOutAcc?.icon !== null ? record?.smeOutAcc?.icon : noimage} size={42}>
            <IconPhoto />
          </Avatar>
          <Box>
            <RenderText text={record?.smeOutAcc?.bankName || "-"} color="#3e166e" />
            <RenderText text={record?.smeOutAcc?.number || "-"} />
          </Box>
        </Flex>
      );
    },
  },
];
