/* eslint-disable react-hooks/exhaustive-deps */
import { Button, CloseButton, Divider, Flex, LoadingOverlay, TextInput } from "@mantine/core";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { AccountApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { bankAccountRegex, regNumber } from "../../utils";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { FormLayout } from "../layout";

const schema = yup.object({
  regNumber: yup.string().matches(regNumber, "ТТД дахин шалгана уу!").required("Заавал бөглөнө!").nullable().trim(),
  number: yup.string().matches(bankAccountRegex, "Та дахин нэг шалгана уу!").required("Заавал бөглөнө!").nullable(),

  accountStatus: yup.string().when(["checked"], (checked) => {
    if (checked) {
      return yup.string().required("Заавал бөглөнө!").nullable().trim();
    }
    return yup.string().nullable();
  }),
  shortName: yup.string().when(["checked"], (checked) => {
    if (checked) {
      return yup.string().max(100, "Хэтэрхий урт байна!").required("Заавал бөглөнө!").nullable().trim();
    }
    return yup.string().nullable();
  }),
});

type Props = {
  action: string[];
  title: string;
  subTitle: string;
  onCancel?: () => void;
  reload: () => void;
};

type IFormData = {
  type: string;
  number: string;
  regNumber: string;
  name: string;
  shortName: string;
  accountStatus: string;
  checked: boolean;
};

export function AccountForm({ onCancel, title, subTitle, action, reload }: Props) {
  const { accountStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [loading, setLoading] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [check, setCheck] = React.useState<any>([]);
  const ref = useRef<any>(null);
  const [data] = React.useState<IFormData>({
    type: "",
    number: "",
    regNumber: "",
    checked: false,
    name: "",

    shortName: "",
    accountStatus: "",
  });

  const onCheck = async (value: any) => {
    if (value?.number && value?.regNumber) {
      setIsLoading(true);
      try {
        let res = await AccountApi.checked({
          type: action && action[1],
          number: value?.number,
          regNumber: value?.regNumber,
        });

        setCheck(["CONFIRM", res]);
        setIsLoading(false);
      } catch (err) {
        Message.error((err as HttpHandler)?.message! || "Алдаа гарлаа");
        setLoading(false);
      }
    }
  };

  const onSubmit = async (value: any) => {
    if (value?.number && value?.shortName && value?.regNumber && value?.accountStatus) {
      setLoading(true);
      try {
        await AccountApi.create({
          type: action && action[1],
          number: value.number,
          shortName: value.shortName,
          regNumber: value.regNumber,
          accountStatus: value.accountStatus,
        });
        Message.success("Та амжилттай шинэ данс үүсэглээ.");

        reload();
        setCheck([]);
        onCancel && onCancel();
      } catch (err) {
        Message.error((err as HttpHandler)?.message!);
      }
    }
    setLoading(false);
  };

  return (
    <Form ref={ref} validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values, setFieldValue }) => {
        return (
          <FormLayout
            title={title}
            subTitle={subTitle}
            my={0}
            extra={[
              <CloseButton
                key="cancel"
                onClick={() => {
                  onCancel && onCancel();
                }}
              />,
            ]}>
            <LoadingOverlay visible={loading || isLoading} />
            <Divider mb="lg" />
            <Flex gap={15} direction="column">
              <TextField
                name="regNumber"
                label="ТТД: "
                placeholder="ТТД: "
                disabled={check[0] === "CONFIRM"}
                withAsterisk={check[0] === "CONFIRM" ? false : true}
              />
              <TextField
                name="number"
                label="Дансны дугаар"
                placeholder="Дансны дугаар оруулна уу"
                disabled={check[0] === "CONFIRM"}
                withAsterisk={check[0] === "CONFIRM" ? false : true}
              />
              {check[0] === "CONFIRM" && (
                <>
                  <TextInput value={check[1]?.accountName} label="Дансны нэр" placeholder="нэр" disabled />
                  <TextField name="shortName" label="Оноосон нэр" placeholder="Оноосон нэр" />
                  <SelectField
                    name="accountStatus"
                    label="Статус:"
                    placeholder="Сонгох"
                    options={(accountStatus || []).map((c) => ({
                      label: c.name,
                      value: c.code,
                    }))}
                  />
                </>
              )}
            </Flex>
            <Divider mb="lg" mt="lg" />
            <Flex mt={20} direction="row" justify="space-between">
              <Button key={1} variant="default" onClick={() => onCancel && onCancel()}>
                Болих
              </Button>
              {check[0] === "CONFIRM" ? (
                <Button key={2} type="submit" onClick={() => setFieldValue("checked", true)}>
                  Хадгалах
                </Button>
              ) : (
                <Button key={2} type="submit" onClick={() => onCheck(values)}>
                  Баталгаажуулах
                </Button>
              )}
            </Flex>
          </FormLayout>
        );
      }}
    </Form>
  );
}
