import { Avatar, Badge, Box, Button, Divider, Flex, Grid, Tabs, Text, createStyles } from "@mantine/core";
import { IconFileText, IconMail, IconMapPin, IconPhoneCall, IconShoppingCart, IconTruckLoading, IconUserCheck, IconWorld } from "@tabler/icons";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import useSwr from "swr";
import { AncorApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { AncorDetailBuyerList } from "../ancor-detail/buyer-list";
import { AncorDetailInformation } from "../ancor-detail/information-list";
import { AncorDetailRoleList } from "../ancor-detail/role-list";
import { AncorDetailSupplierList } from "../ancor-detail/supplier-list";
import { PageLayout } from "../layout";
import StarMap from "../star";

export function SmeDetail() {
  const params = useParams();
  const breadcrumbs = useBreadcrumb();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const [activeTab, setActiveTab] = React.useState<string>("INFO");
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const { data, mutate } = useSwr(
    `/api/client/anchor/partner/sme/${params.id}`,
    async () => {
      if ((user?.permissions || []).filter((c) => c.module === "CLIENT" && c.code === "CLIENT_SME").length > 0) {
        let res = await AncorApi.partnerGet(`${params.id}`);
        return res;
      }
      return [];
    },
    {},
  );

  return (
    <PageLayout
      title="Харилцагчийн мэдээлэл"
      subTitle="Харилцагчийн дэлгэрэнгүй мэдээлэл"
      breadcrumb={breadcrumbs}
      extra={[
        <Button size="sm" key={1} onClick={() => navigate("/customer/anchor")}>
          {"Буцах"}
        </Button>,
      ]}>
      <Flex direction="column" gap="md">
        <Grid gutter="md">
          <Grid.Col sm={0} md={3}>
            <Box px={20} py={16} className={classes.gridBox}>
              <Flex align="center" direction="column" gap="md" justify="center">
                <Box sx={{ position: "relative" }}>
                  <Avatar alt="" color={"gray"} variant="gradient" radius={100} size={87} src={data?.logo || ""} />
                </Box>
                <Text fw={600} fz="xl" c="indigo">
                  {data?.businessName || "-"}
                </Text>
                <Text fw={700} fz="sm" c="gray">
                  ТТД: {data?.regNumber || "-"}
                </Text>
                <div className={classes.stars}>
                  <StarMap reviewScore={3 || 0} /> {3 > 0 ? 11248 : null}
                </div>
                <Box w={"100%"}>
                  <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} w="100%" label="Холбоо барих" />
                  <Flex w={"100%"} align={"center"} justify="center" gap="sm" direction={"column"} mt={12}>
                    <div className={classes.preInfo}>
                      <div className={classes.preInfoIcon}>
                        <IconWorld />
                      </div>
                      <Text lineClamp={3} fw={500} fz={16} c="gray" ta="start">
                        {data?.web || "-"}
                      </Text>
                    </div>
                    <div className={classes.preInfo}>
                      <div className={classes.preInfoIcon}>
                        <IconMail />
                      </div>
                      <Text lineClamp={3} fw={500} fz={16} c="gray" ta="start">
                        {data?.email || "-"}
                      </Text>
                    </div>
                    <div className={classes.preInfo}>
                      <div className={classes.preInfoIcon}>
                        <IconPhoneCall />
                      </div>
                      <Text lineClamp={3} fw={500} fz={16} c="gray" ta="start">
                        {data?.phone || "-"}
                      </Text>
                    </div>
                    <div className={classes.preInfo}>
                      <div className={classes.preInfoIcon}>
                        <IconMapPin />
                      </div>
                      <Text lineClamp={3} fw={500} fz={16} c="gray" ta="start">
                        {data?.address || "-"}
                      </Text>
                    </div>
                  </Flex>
                </Box>
                <Box w={"100%"}>
                  <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} w="100%" label="Buyer бизнес" mb="xs" />
                  <Flex w={"100%"} align={"center"} justify="center" gap="sm" direction={"column"}>
                    {data?.businesses?.length > 0 || (data?.businesses || []).find((c: any) => c?.type === "BUYER") ? (
                      (data?.businesses || [])
                        .filter((c: any) => c?.type === "BUYER")
                        .map((item: any, index: any) => (
                          <div key={index} className={classes.preInfo}>
                            <Badge radius="sm" key={index} variant="outline" mr="xs">
                              {item?.profileName}
                            </Badge>
                          </div>
                        ))
                    ) : (
                      <div className={classes.preInfo}>
                        <Text lineClamp={3} fw={500} fz={16} c="gray" ta="start">
                          Өгөгдөл олдсонгүй.
                        </Text>
                      </div>
                    )}
                  </Flex>
                </Box>
                <Box w={"100%"}>
                  <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} w="100%" label="Supplier бизнес" />
                  <Flex w={"100%"} align={"center"} justify="center" gap="sm" direction={"column"}>
                    {data?.businesses?.length > 0 || (data?.businesses || [])?.find((c: any) => c.type === "SUPPLIER") ? (
                      (data?.businesses || [])
                        .filter((c: any) => c?.type === "SUPPLIER")
                        .map((item: any, index: any) => (
                          <div key={index} className={classes.preInfo}>
                            <Badge radius="sm" key={index} variant="outline" mr="xs">
                              {item?.profileName}
                            </Badge>
                          </div>
                        ))
                    ) : (
                      <div className={classes.preInfo}>
                        <Text lineClamp={3} fw={500} fz={16} c="gray" ta="start">
                          Өгөгдөл олдсонгүй.
                        </Text>
                      </div>
                    )}
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Grid.Col>
          <Grid.Col sm={12} md={9}>
            <Box px={20} py={16} className={classes.gridBox}>
              <Tabs h="100%" value={activeTab} onTabChange={(value: any) => setActiveTab(value)}>
                <Tabs.List>
                  <Tabs.Tab value="INFO" icon={<IconFileText color={activeTab === "INFO" ? "#3e166e" : "#44566C"} />}>
                    <Text size="sm" weight={500} c="#44566C">
                      Үндсэн мэдээлэл
                    </Text>
                  </Tabs.Tab>
                  <Tabs.Tab value="ROLE" icon={<IconUserCheck color={activeTab === "ROLE" ? "#3e166e" : "#44566C"} />}>
                    <Text size="sm" weight={500} c="#44566C">
                      Ажилтны эрх
                    </Text>
                  </Tabs.Tab>
                  <Tabs.Tab value="BUYER" icon={<IconShoppingCart color={activeTab === "BUYER" ? "#3e166e" : "#44566C"} />}>
                    <Text size="sm" weight={500} c="#44566C">
                      Buyer бизнес
                    </Text>
                  </Tabs.Tab>
                  <Tabs.Tab value="SUPPLIER" icon={<IconTruckLoading color={activeTab === "SUPPLIER" ? "#3e166e" : "#44566C"} />}>
                    <Text size="sm" weight={500} c="#44566C">
                      Supplier бизнес
                    </Text>
                  </Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="INFO">
                  <AncorDetailInformation item={data} />
                </Tabs.Panel>
                <Tabs.Panel value="ROLE">
                  <AncorDetailRoleList data={data} onSuccess={mutate} />
                </Tabs.Panel>
                <Tabs.Panel value="BUYER">
                  <AncorDetailBuyerList data={data?.businesses?.filter((c: any) => c?.type === "BUYER")} />
                </Tabs.Panel>
                <Tabs.Panel value="SUPPLIER">
                  <AncorDetailSupplierList data={data?.businesses?.filter((c: any) => c?.type === "SUPPLIER")} />
                </Tabs.Panel>
              </Tabs>
            </Box>
          </Grid.Col>
        </Grid>
      </Flex>
    </PageLayout>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  { label: "Харилцагч удирдлага" },
  {
    to: "/customer/sme-customer",
    label: "SME харилцагчид",
  },
  {
    label: "Харилцагчийн мэдээлэл",
  },
];

const useStyles = createStyles((theme) => ({
  root: {
    padding: theme.spacing.xl * 1.5,
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
  gridBox: {
    height: "100%",
    border: `1px solid ${theme.colors.gray[2]}`,
    borderRadius: theme.radius.md,
    backgroundColor: theme.white,
  },
  systemUserBtn: {
    height: "48px",
  },

  stars: { display: "flex", alignItems: "center", color: "#868e96", fontWeight: 700, fontSize: 14 },
  adminIcon: {
    color: theme.colors.red[8],
  },
  preInfo: {
    display: "flex",
    padding: "0px 0px",
    width: "100%",
    gap: "12px",
    color: theme.colors.gray[6],
  },
  preInfoIcon: {
    height: 24,
    width: 24,
    padding: 0,
  },
  preInfoButton: {
    display: "flex",
    padding: "0px 0px",
    width: "100%",
    gap: "12px",
    cursor: "pointer",
    color: theme.colors.gray[6],
    backgroundColor: "white",
    "&:hover": {
      color: theme.colors.indigo[8],
      backgroundColor: "white",
    },
  },
}));
