import { Box } from "@mantine/core";
import { useNavigate, useParams } from "react-router";
import { IPayments } from "../../interfaces/IPayments";
import { tugrug } from "../../utils";
import { dateSecFormat } from "../../utils/date";
import { ColumnType, Table } from "../table";
import RenderText from "../ui/render-text";

export function AccountSupplierHistory({ data }: { data: IPayments }) {
  const params = useParams();
  const navigate = useNavigate();

  const columns = useHeader({
    onClick: (key, record) => {
      switch (key) {
        case "detail": {
          navigate(`/cf/funding-request/${params.type || "SUPPLIER"}/request`);
          break;
        }
      }
    },
  });

  return (
    <div style={{ marginTop: 10 }}>
      <Table name="buyer-led-program-credit.list" columns={columns} dataSource={data} pagination={false} />
    </div>
  );
}

const useHeader = ({ onClick }: { onClick: (key: string, record: any) => void }): ColumnType<IPayments>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => {
      return <RenderText text={index + 1} />;
    },
  },
  {
    title: "Гүйлгээний огноо",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Box>
          <RenderText text={record?.refCode || "-"} />
          <RenderText text={dateSecFormat(record?.createdAt)} color="#3e166e" />
        </Box>
      );
    },
  },
  {
    title: "Харьцсан данс",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Box>
          <RenderText text={record?.payerAccName || "-"} />
          <RenderText text={record?.payerAccNum || "-"} color="#3e166e" />
        </Box>
      );
    },
  },
  {
    title: "Төлсөн дүн",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return <RenderText text={tugrug(record?.amount || 0)} isAmount />;
    },
  },
  {
    title: "Төлвөл зохих",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return <RenderText text={tugrug(record?.firstAmount || 0)} isAmount />;
    },
  },
  {
    title: "Үлдэгдэл",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return <RenderText text={tugrug(record?.lastAmount || 0)} isAmount />;
    },
  },
  {
    title: "Гүйлгээний утга",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return <RenderText text={record?.description || "-"} />;
    },
  },
];
