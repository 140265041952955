import { Button, Divider, Flex, Grid, Input, LoadingOverlay, Modal, Text, TextInput } from "@mantine/core";
import React from "react";
import { useSelector } from "react-redux";
import useSwr from "swr";
import * as yup from "yup";
import { ReminderApi } from "../../apis";
import useUser from "../../hooks/user";
import { IGeneral } from "../../interfaces/IGeneral";
import { dateSecFormat } from "../../utils/date";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { SelectField } from "../form/select-field";
import { TextareaField } from "../form/textarea-field";
import { PaymentStaffForm } from "./staff-form";

const schema = yup.object({
  receiverUserId: yup.string().required("Заавал бөглөнө!").nullable(),
  reminderType: yup.string().required("Заавал бөглөнө!").nullable(),
  reminderLevel: yup.string().required("Заавал бөглөнө!").nullable(),
  connectType: yup.string().required("Заавал бөглөнө!").nullable(),
  result: yup.string().required("Заавал бөглөнө!").nullable(),
  response: yup.string().required("Заавал бөглөнө!").nullable(),
  clientText: yup.string().max(1000, "Хэтэрхий урт байна.").required("Заавал бөглөнө!").nullable(),
  internalText: yup.string().max(1000, "Хэтэрхий урт байна.").required("Заавал бөглөнө!").nullable(),
});

type Props = {
  onCancel?: () => void;
  action: any;
  reload?: any;
};

type IFormData = {
  receiverUserId: string;
  isExistUser: boolean;
  phone: string;
  email: string;
  reminderType: string;
  reminderLevel: string;
  connectType: string;
  result: string;
  response: string;
  clientText: string;
  internalText: string;
};

export function PaymentReminderForm({ action, onCancel, reload }: Props) {
  const { user } = useUser();
  const nowMoment = new Date();
  const [staffQuery] = React.useState<any>("");
  const [actions, setActions] = React.useState<string[]>([]);
  const [staffUsers, setStaffUsers] = React.useState<string>("");
  const [staffUsersId, setStaffUsersId] = React.useState<string>("");

  const { reminderTypes, reminderResults, reminderLevels, reminderResponses, reminderConnectTypes } = useSelector(
    (state: { general: IGeneral }) => state.general,
  );
  const [loading, setLoading] = React.useState<boolean>(false);
  const [datas] = React.useState<IFormData>({
    receiverUserId: "",
    isExistUser: false,
    phone: "",
    email: "",
    reminderType: "",
    reminderLevel: "",
    connectType: "",
    result: "",
    response: "",
    clientText: "",
    internalText: "",

    ...(action ? action : {}),
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      await ReminderApi.create({
        repaymentId: action[1]?.id,
        receiverUserId: values?.receiverUserId,
        isExistUser: true,
        phone: staffDatas?.phone,
        email: staffDatas?.email,
        reminderType: values?.reminderType,
        reminderLevel: values?.reminderLevel,
        connectType: values?.connectType,
        result: values?.result,
        response: values?.response,
        clientText: values?.clientText,
        internalText: values?.internalText,
      });
      Message.success("Төлөлтийн сануулсан тэмдэглэл амжилттай илгээсэн.");
      setLoading(false);
      reload();
      onCancel && onCancel();
    } catch (err) {
      setLoading(false);
      Message.error((err as HttpHandler)?.message!);
    }
  };

  const onCancels = async () => {
    setActions([]);
  };

  const { data: staffData } = useSwr(
    `/collection/reminder/staff?${staffQuery}`,
    async () => {
      if ((user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_OVERDUE").length > 0) {
        try {
          let res = await ReminderApi.select({ query: staffQuery, repaymentId: action[1]?.id });
          return res;
        } catch (err) {
          Message.error((err as HttpHandler)?.message!);
          return err;
        }
      }
      return [];
    },
    {},
  );

  const { data: staffDatas } = useSwr(
    `/collection/reminder/staff?${staffUsersId}`,
    async () => {
      if ((user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_OVERDUE").length > 0) {
        try {
          if (!staffUsersId) {
            return [];
          }
          let res = await ReminderApi.get(staffUsers);
          return res;
        } catch (err) {
          Message.error((err as HttpHandler)?.message!);
          return err;
        }
      }
      return [];
    },
    {},
  );

  return (
    <>
      <Form validationSchema={schema} onSubmit={onSubmit} initialValues={datas}>
        {({ values, setFieldValue }) => {
          return (
            <Flex sx={{ position: "relative" }} direction="column" gap="md">
              <LoadingOverlay visible={loading} />
              <Grid>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Хугацаа хэтрэлт дугаар">
                    <Text color="indigo" fw={600}>
                      {action[1]?.refCode || "-"}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Эргэн төлөлт нэхэмжлэх">
                    <Text color="indigo" fw={600}>
                      {action[1]?.payerBusiness?.refCode || "-"}, {action[1]?.payerBusiness?.profileName}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Төлөлт сануулсан">
                    <Text color="indigo" fw={600}>
                      {user?.lastName} {user?.firstName || "-"}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col span={4}>
                  <Input.Wrapper label="Партнерийн нэр">
                    <Text color="indigo" fw={600}>
                      {action[1]?.payerBusiness?.partner?.refCode}, {action[1]?.payerBusiness?.partner?.businessName}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Бизнесийн нэр">
                    <Text color="indigo" fw={600}>
                      {action[1]?.payerBusiness?.refCode}, {action[1]?.payerBusiness?.profileName}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Сануулга огноо цаг">
                    <Text color="indigo" fw={600}>
                      {dateSecFormat(nowMoment)}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>

                {(user?.permissions || []).filter((c) => c.module === "COLLECTION" && c.code === "COLLECTION_OVERDUE").length > 0 && (
                  <Grid.Col span={4}>
                    <SelectField
                      name="receiverUserId"
                      label="Холбоо барьсан ажилтны нэр"
                      placeholder="Сонгох"
                      clearable
                      creatable
                      searchable
                      onChange={(e: any) => {
                        setStaffUsers(e);
                        setStaffUsersId(e);
                      }}
                      options={(staffData || [])?.map((item: any) => {
                        return {
                          value: item?.id,
                          label: item?.fullName,
                        };
                      })}
                    />
                  </Grid.Col>
                )}

                <Grid.Col span={4}>
                  <TextInput
                    label="Холбоо барьсан ажилтны утас:"
                    value={values?.receiverUserId === undefined ? "" : staffDatas?.phone}
                    placeholder="Оруулна уу"
                    disabled
                  />
                </Grid.Col>

                <Grid.Col span={4}>
                  <TextInput
                    label="Холбоо барьсан ажилтны имэйл:"
                    value={values?.receiverUserId === undefined ? "" : staffDatas?.email}
                    placeholder="Оруулна уу"
                    disabled
                  />
                </Grid.Col>

                <Grid.Col span={4}>
                  <SelectField
                    label="Сануулгын төрөл"
                    placeholder="Сонгох"
                    name="reminderType"
                    options={(reminderTypes || []).map((val) => ({ label: val.name, value: val.code }))}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <SelectField
                    label="Сануулга зэрэглэл"
                    placeholder="Сонгох"
                    name="reminderLevel"
                    options={(reminderLevels || []).map((val) => ({ label: val.name, value: val.code }))}
                  />
                </Grid.Col>

                <Grid.Col span={4}>
                  <SelectField
                    label="Холбогдсон төрөл"
                    placeholder="Сонгох"
                    name="connectType"
                    onChange={() => {
                      setFieldValue("result", undefined);
                    }}
                    options={(reminderConnectTypes || []).map((val) => ({ label: val.name, value: val.code }))}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <SelectField
                    label="Холбогдсон үр дүн"
                    placeholder="Сонгох"
                    name="result"
                    disabled={!values.connectType}
                    options={(reminderResults || []).filter((c) => c?.connectType === values?.connectType).map((val) => ({ label: val.name, value: val.code }))}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <SelectField
                    label="Амлалтын хариу"
                    placeholder="Сонгох"
                    name="response"
                    options={(reminderResponses || []).map((val) => ({ label: val.name, value: val.code }))}
                  />
                </Grid.Col>

                <Grid.Col span={12}>
                  <TextareaField name="clientText" label="Харилцагчид очих тэмдэглэл:" placeholder="Тайлбар оруулна уу." />
                </Grid.Col>
                <Grid.Col span={12}>
                  <TextareaField name="internalText" label="Дотоод тэмдэглэл:" placeholder="Тайлбар оруулна уу." />
                </Grid.Col>
              </Grid>
              <Divider />
              <Flex direction="row" justify="space-between">
                <Button variant="default" onClick={() => onCancel && onCancel()}>
                  Болих
                </Button>
                <Flex gap="md">
                  <Button variant="outline" type="submit">
                    Хадгалах
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          );
        }}
      </Form>
      <Modal opened={actions[0] === "STAFF"} onClose={() => setActions([])} withCloseButton={false} centered>
        <PaymentStaffForm onCancel={onCancels} action={[]} />
      </Modal>
    </>
  );
}
