import { Alert, Button, CloseButton, Flex, Grid, LoadingOverlay } from "@mantine/core";
import React from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { AuthApi, LbfProductApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { OtpField } from "../form/otp-field";
import { SelectField } from "../form/select-field";
import { TextareaField } from "../form/textarea-field";
import { FormLayout } from "../layout";

const schema = yup.object({
  pin: yup.string().min(6, "Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
  type: yup.string().required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  pin: string;
  statusDesc: string;
  type: string;
};
export function ProductBuyerLedActiveForm({
  title,
  onCancel,
  action,
  subTitle,
  payload,
  reload,
}: {
  title: string;
  subTitle: string;
  onCancel: () => void;
  action: string[];
  payload: any;
  reload: () => void;
}) {
  const { lbfProductActiveTypes, lbfProductInactiveTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data] = React.useState<IFormData>({
    pin: "",
    statusDesc: "",
    type: "",
  });

  const onSubmit = async (value: IFormData) => {
    setLoading(true);
    try {
      await AuthApi.checkPin({ pin: value.pin });

      if (action[0] === "ACTIVE") {
        await LbfProductApi.active(payload.id, { activeType: value.type, statusDesc: value.statusDesc });
        Message.success("Амжилттай бүтээгдэхүүний идэвхтэй болгосон");
      } else {
        await LbfProductApi.inactive(payload.id, { inactiveType: value.type, statusDesc: value.statusDesc });
        Message.success("Амжилттай бүтээгдэхүүний идэвхгүй болгосон");
      }

      reload();
      onCancel();
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ errors }) => {
        return (
          <FormLayout title={title} subTitle={subTitle} my={0} extra={[<CloseButton key="cancel" onClick={() => onCancel()} />]}>
            <LoadingOverlay visible={loading} />
            <Grid>
              {action[0] === "ACTIVE" ? (
                <>
                  <Grid.Col span={12}>
                    <Alert
                      styles={{ message: { color: "#44566c", fontWeight: 500 } }}
                      radius="md"
                      color="indigo"
                      withCloseButton={false}
                      title="Таны анхааралд">
                      {`Та ${payload?.refCode} кодтой SCF бүтээгдэхүүнийг идэвхтэй болгох гэж байна.`}
                    </Alert>
                  </Grid.Col>

                  <Grid.Col span={12}>
                    <SelectField
                      label="Идэвхжүүлэх төрөл"
                      name="type"
                      placeholder="Сонгох"
                      options={(lbfProductActiveTypes || []).map((type) => ({ label: type.name, value: type.code }))}
                    />
                  </Grid.Col>
                </>
              ) : (
                <>
                  <Grid.Col span={12}>
                    <Alert
                      styles={{ message: { color: "#44566c", fontWeight: 500 } }}
                      radius="md"
                      color="indigo"
                      withCloseButton={false}
                      title="Таны анхааралд">
                      {`Та ${payload?.refCode} кодтой SCF бүтээгдэхүүнийг идэвхгүй болгох гэж байна.`}
                    </Alert>
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <SelectField
                      label="Идэвхгүй болгох төрөл"
                      name="type"
                      placeholder="Сонгох"
                      options={(lbfProductInactiveTypes || []).map((type) => ({ label: type.name, value: type.code }))}
                    />
                  </Grid.Col>
                </>
              )}

              <Grid.Col span={12}>
                <OtpField name="pin" label="Үйлдлийг баталгаажуулах бол ПИН кодоо оруулна уу." placeholder="" required />
              </Grid.Col>
              <Grid.Col span={12}>
                <TextareaField name="statusDesc" label="Тайлбар бичнэ үү." placeholder="Тайлбар оруулах..." maxLength={255} withAsterisk={false} />
              </Grid.Col>
            </Grid>
            <Flex mt={20} direction="row" justify="space-between">
              <Button variant="default" onClick={() => onCancel()}>
                Болих
              </Button>
              <Flex gap="md">
                <Button variant="outline" type="submit" color={action[0] === "ACTIVE" ? "green" : "red"}>
                  {action[0] === "ACTIVE" ? "Идэвхжүүлэх" : "Идэвхгүй болгох"}
                </Button>
              </Flex>
            </Flex>
          </FormLayout>
        );
      }}
    </Form>
  );
}
