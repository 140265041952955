import { Avatar, Badge, Button, Divider, Drawer, Tooltip } from "@mantine/core";
import { IconInfoSquare } from "@tabler/icons";
import React from "react";
import noimage from "../../assets/no-image.png";
import { IPartnerBuyer } from "../../interfaces/IPartnerBuyer";
import { ColumnType, Table } from "../table";
import RenderText from "../ui/render-text";
import { AncorDetailBuyerForm } from "./buyer-form";

export function AncorDetailBuyerList({ data }: { data: any }) {
  const [action, setAction] = React.useState<string[]>([]);

  const columns = useHeader({
    onClick: (key, record) => {
      switch (key) {
        case "detail": {
          setAction(["opened", record.id]);
          break;
        }
      }
    },
  });

  return (
    <>
      <Divider color="gray" mt="md" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="БҮРТГЭЛТЭЙ BUYER БИЗНЕС" mb="xs" />
      <Table name="ancor.detail.buyers.list" columns={columns} dataSource={data || []} />
      <Drawer
        opened={action[0] === "opened"}
        onClose={() => setAction([])}
        withCloseButton={false}
        position="right"
        size="60%"
        styles={{ drawer: { overflowY: "scroll" } }}>
        <AncorDetailBuyerForm action={action} />
      </Drawer>
    </>
  );
}

const useHeader = ({ onClick }: { onClick: (key: string, record: IPartnerBuyer) => void }): ColumnType<IPartnerBuyer>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Tooltip label="Дэлгэрэнгүй" position="bottom">
          <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
            <IconInfoSquare />
          </Button>
        </Tooltip>
      );
    },
  },
  {
    title: "Лого",
    sorter: true,
    dataIndex: "logo",
    render: (record) => {
      return <Avatar src={record?.logo !== null ? record?.logo : noimage} alt="" color="indigo" />;
    },
  },
  {
    title: "Бизнесийн төрөл",
    sorter: true,
    dataIndex: "type",
    render: (record) => {
      const isBuyer = record.type === "BUYER";
      const label = isBuyer ? "Худалдан авагч" : "Нийлүүлэгч";
      return (
        <Badge size="md" radius="sm" variant="outline">
          {label}
        </Badge>
      );
    },
  },
  {
    title: "Бизнес Ref#",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return <RenderText text={record.refCode || "-"} />;
    },
  },
  {
    title: "Бизнес нэр",
    sorter: true,
    dataIndex: "profileName",
    render: (record) => {
      return <RenderText text={record.profileName || "-"} />;
    },
  },
  {
    title: "Имэйл",
    sorter: true,
    dataIndex: "partnerEmail",
    render: (record) => {
      return <RenderText text={record.partnerEmail || "-"} />;
    },
  },
  {
    title: "Утас",
    sorter: true,
    dataIndex: "partnerPhone",
    render: (record) => {
      return <RenderText text={record.partnerPhone || "-"} />;
    },
  },
];
