import { Flex } from "@mantine/core";
import { useSelector } from "react-redux";
import { ProductHistoryApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { ISystemHistory } from "../../interfaces/ISystemHistory";
import { IReference } from "../../models/General";
import { dateSecFormat } from "../../utils/date";
import { renderName } from "../../utils/render-name";
import { ColumnType, Table } from "../table";
import RenderText from "../ui/render-text";

export function HistoryList({ data }: { data: string }) {
  const { productHistoryTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const columns = useHeader({ productHistoryTypes });

  return (
    <Flex sx={{ position: "relative" }} direction="column" mt={15}>
      <Table
        name="scf-funding-supplier-history.list"
        columns={columns}
        filters={{ type: "", actionUserId: "", productId: `${data}` }}
        loadData={(data) => ProductHistoryApi.list(data!)}
      />
    </Flex>
  );
}

const useHeader = ({ productHistoryTypes }: { productHistoryTypes: IReference[] }): ColumnType<ISystemHistory>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    title: "Код",
    sorter: true,
    dataIndex: "productRefCode",
    render: (record) => {
      return <RenderText text={record?.productRefCode || "-"} />;
    },
  },
  {
    title: "Системд хийсэн үйлдэл",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return <RenderText text={productHistoryTypes.find((c: any) => c?.code === record?.type)?.name || "-"} />;
    },
  },
  {
    title: "Төрөл",
    sorter: true,
    dataIndex: "type",
    render: (record) => {
      return <RenderText text={record.type ?? "-"} />;
    },
  },
  {
    title: "Тайлбар",
    sorter: true,
    dataIndex: "description",
    render: (record) => {
      return <RenderText text={record.description || "-"} />;
    },
  },
  {
    title: "Огноо, цаг",
    sorter: true,
    dataIndex: "createdAt",
    render: (record) => {
      return <RenderText text={dateSecFormat(record.createdAt)} />;
    },
  },
  {
    title: "Хэрэглэгч",
    sorter: true,
    dataIndex: "actionUser",
    render: (record) => {
      return <RenderText text={renderName(record?.actionUser)} />;
    },
  },
];
