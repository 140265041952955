import { Avatar, Badge, Box, Button, Divider, Flex, Grid, Group, Input, Text } from "@mantine/core";
import { IconDownload } from "@tabler/icons";
import React from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import noimage from "../../assets/no-image.png";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { tugrug } from "../../utils";
import { dateSecFormat } from "../../utils/date";
import { Form } from "../form";

const schema = yup.object({});

type IFormData = {
  name: string;
};

type Props = {
  action: any;
  onCancel: () => void;
  setTabIndex: any;
};

export function ScfFinancePotentialInvoiceForm({ onCancel, action, setTabIndex }: Props) {
  const { invoiceStatus, invoiceOverdueStatus, invoicePaymentStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [data] = React.useState<IFormData>({
    name: "",
  });

  const onSubmit = () => {};

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {() => {
        return (
          <Flex sx={{ position: "relative" }} direction="column" gap="md">
            <Grid>
              <Grid.Col span={12} mt="xs">
                <Divider color="gray" mt="xs" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ЕРӨНХИЙ МЭДЭЭЛЭЛ" />
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэхийн код">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.refCode || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэх илгээсэн">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.senderUser?.firstName || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэх баталсан">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.receiverFinUser?.firstName || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Баталсан дүн">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(action?.confirmedAmount || 0)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Төлсөн дүн">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(action?.paidAmount || 0)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэхийн үлдэгдэл">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(action?.amountToPay || 0)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэх төлөв">
                  <Box style={{ alignItems: "center" }}>
                    <Badge
                      size="md"
                      radius="sm"
                      variant="outline"
                      style={{
                        border: `${(invoiceStatus || []).find((c: any) => c?.code === action?.invoiceStatus)?.color} 1px solid`,
                        color: `${(invoiceStatus || []).find((c: any) => c?.code === action?.invoiceStatus)?.color}`,
                      }}>
                      {(invoiceStatus || []).find((item: IReference) => item.code === action?.invoiceStatus)?.name}
                    </Badge>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Төлбөрийн төлөв">
                  <Box style={{ alignItems: "center" }}>
                    <Badge
                      size="md"
                      radius="sm"
                      variant="outline"
                      style={{
                        border: `${(invoicePaymentStatus || []).find((c: any) => c?.code === action?.paymentStatus)?.color} 1px solid`,
                        color: `${(invoicePaymentStatus || []).find((c: any) => c?.code === action?.paymentStatus)?.color}`,
                      }}>
                      {(invoicePaymentStatus || []).find((item: IReference) => item.code === action?.paymentStatus)?.name}
                    </Badge>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Хугацаа хэтрэлтийн төлөв">
                  <Box style={{ alignItems: "center" }}>
                    <Badge
                      size="md"
                      radius="sm"
                      variant="outline"
                      style={{
                        border: `${(invoiceOverdueStatus || []).find((c: any) => c?.code === action?.overdueStatus)?.color} 1px solid`,
                        color: `${(invoiceOverdueStatus || []).find((c: any) => c?.code === action?.overdueStatus)?.color}`,
                      }}>
                      {(invoiceOverdueStatus || []).find((item: IReference) => item.code === action?.overdueStatus)?.name}
                    </Badge>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Төлбөрийн нөхцөл">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.paymentTermDesc || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэх төлөх огноо">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {dateSecFormat(action?.paymentDate)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэхийн баримт">
                  <Box style={{ alignItems: "center" }}>
                    <Button variant="subtle" size="xs" leftIcon={<IconDownload size={18} />}>
                      INV_3222.PDF
                    </Button>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="НИЙЛҮҮЛЭГЧ ТАЛЫН МЭДЭЭЛЭЛ" />
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Партнер нэр:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.type === "SALES" ? action?.senderBusiness?.partner?.businessName || "-" : action?.receiverBusiness?.partner?.businessName || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Татвар төлөгчийн дугаар:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.type === "SALES" ? action?.senderBusiness?.regNumber || "-" : action?.receiverBusiness?.regNumber || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нийлүүлэгч бизнес:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.type === "SALES" ? action?.senderBusiness?.profileName || "-" : action?.receiverBusiness?.profileName || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Нийлүүлэгчийн данс:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.type === "SALES" ? action?.senderAcc?.number : action?.receiverAcc?.number}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Борлуулалтын захиалга:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.orderSalesCode || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Санхүүгийн ажилтан:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.type === "SALES" ? action?.senderFinUser?.lastName : action?.receiverFinUser?.lastName}{" "}
                      {action?.type === "SALES" ? action?.senderFinUser?.firstName : action?.receiverFinUser?.firstName}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ХУДАЛДАН АВАГЧИЙН МЭДЭЭЛЭЛ" />
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Худалдан авагч бизнес">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.receiverBusiness?.profileName || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Худалдан авалтын захиалга">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.orderPurchaseCode || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Ажилтан">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.receiverFinUser?.lastName || "-"} {action?.receiverFinUser?.firstName || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Худалдан авагч данс">
                  <Box style={{ alignItems: "center" }}>
                    <Group>
                      <Avatar src={action?.receiverAcc?.icon || noimage} radius="xl" />
                      <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action?.receiverAcc?.number || "-"}
                      </Text>
                    </Group>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} />
              </Grid.Col>
            </Grid>
            <Flex direction="row" justify="space-between">
              <Button variant="default" onClick={() => onCancel && onCancel()}>
                Болих
              </Button>
              <Flex gap="md">
                <Button
                  variant="filled"
                  disabled
                  onClick={() => {
                    setTabIndex("2");
                  }}
                  type="submit">
                  Үргэлжлүүлэх
                </Button>
              </Flex>
            </Flex>
          </Flex>
        );
      }}
    </Form>
  );
}
