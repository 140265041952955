import { Button, Flex, Grid, LoadingOverlay } from "@mantine/core";
import React from "react";
import * as yup from "yup";
import { AuthApi } from "../../apis";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { TextField } from "../form/text-field";

const schema = (isEdit: boolean) =>
  isEdit
    ? yup.object({
        oldPin: yup.string().required("Заавал бөглөнө!"),
        newPin: yup
          .string()
          .matches(/^[0-9]{6}$/, "Пин код 6 оронтой тоо байна")
          .required("Нууц үг оруулана уу"),
        checkPin: yup
          .string()
          .required("Нууц үг оруулана уу")
          .oneOf([yup.ref("newPin")], "Нууц үг таарахгүй байна"),
      })
    : yup.object({
        newPin: yup
          .string()
          .matches(/^[0-9]{6}$/, "Пин код 6 оронтой тоо байна")
          .required("Нууц үг оруулана уу"),
        checkPin: yup
          .string()
          .required("Нууц үг оруулана уу")
          .oneOf([yup.ref("newPin")], "Нууц үг таарахгүй байна"),
      });

export function ChangePinForm({ onClose, isEdit, reload }: { onClose: () => void; isEdit: boolean; reload: () => void }) {
  const [loading, setLoading] = React.useState<boolean>(false);

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      if (isEdit) {
        await AuthApi.changePin({ oldPin: values.oldPin, pin: values.newPin });
      } else {
        await AuthApi.createPin({ pin: values.newPin });
      }
      setLoading(false);
      Message.success("Таны хүсэлт амжилттай");
      reload();
      onClose();
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
      setLoading(false);
    }
  };

  const [data] = React.useState<any>({
    oldPin: undefined,
    newPin: undefined,
    checkPin: undefined,
  });

  return (
    <Form initialValues={data} validationSchema={schema(isEdit)} onSubmit={onSubmit}>
      {() => {
        return (
          <Grid sx={{ position: "relative" }}>
            <LoadingOverlay visible={loading} />

            {isEdit ? (
              <Grid.Col span={12}>
                <TextField name="oldPin" label="Хуучин пин код" placeholder="Хуучин пин код" />
              </Grid.Col>
            ) : null}
            <Grid.Col span={12}>
              <TextField name="newPin" label="Шинэ пин код" placeholder="Шинэ пин код" />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextField name="checkPin" label="Давтан пин код" placeholder="Давтан пин код" />
            </Grid.Col>
            <Grid.Col span={12}>
              <></>
              <Flex w="100%" direction="row" justify="space-between" mt={20}>
                <Button variant="default" onClick={() => onClose && onClose()}>
                  Болих
                </Button>
                <Button variant="outline" color="teal" type="submit">
                  {isEdit ? " ПИН Код солих" : " ПИН Код үүсгэх"}
                </Button>
              </Flex>
            </Grid.Col>
          </Grid>
        );
      }}
    </Form>
  );
}
