import { Avatar, Badge, Flex } from "@mantine/core";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { IHistory } from "../../interfaces/IHistory";
import { IReference } from "../../interfaces/IReference";
import { tugrug } from "../../utils";
import { dateSecFormat } from "../../utils/date";
import { renderName } from "../../utils/render-name";
import { ColumnType, Table } from "../table";
import RenderText from "../ui/render-text";

type Props = {
  action: any;
  onCancel: () => void;
};

export function ScfFinanceRefundHistoryList({ action, onCancel }: Props) {
  const { paymentMethod, repaymentHistoryPaymentTypes, repaymentOverDueStatus, repaymentStatus } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({
    paymentMethod,
    repaymentOverDueStatus,
    repaymentStatus,
    repaymentHistoryPaymentTypes,
    onClick: (key, item) => {},
  });

  return <Table name="user.control.list" columns={columns} filters={{}} dataSource={action?.histories} />;
}

type HeaderProps = {
  onClick: (key: string, record: IHistory) => void;
  paymentMethod: any;
  repaymentOverDueStatus: IReference[];
  repaymentStatus: any;
  repaymentHistoryPaymentTypes: any;
};

const useHeader = ({ onClick, paymentMethod, repaymentOverDueStatus, repaymentStatus, repaymentHistoryPaymentTypes }: HeaderProps): ColumnType<IHistory>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    title: "Огноо, цаг",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return <RenderText text={dateSecFormat(record.createdAt)} />;
    },
  },
  {
    title: "Нэхэмжлэх дугаар",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return <RenderText text={record.repaymentRefCode || "-"} />;
    },
  },
  {
    title: "Гүйлгээний лавлах",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return <RenderText text={record.trxRefCode || "-"} />;
    },
  },
  {
    title: "Нэхэмжлэх эхний үлдэгдэл",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return <RenderText text={tugrug(record.firstAmount) || tugrug(0)} isAmount />;
    },
  },
  {
    title: "Гүйлгээ төлбөрийн дүн",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return <RenderText text={tugrug(record.trxAmount) || tugrug(0)} isAmount />;
    },
  },
  {
    title: "Нэхэмжлэх эцсийн үлдэгдэл",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return <RenderText text={tugrug(record.lastAmount) || tugrug(0)} isAmount />;
    },
  },
  {
    title: "Гүйлгээний утга",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return <RenderText text={record.trxDesc || "-"} />;
    },
  },
  {
    title: "Төлсөн дансны дугаар",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return <RenderText text={record?.creditAcc?.number || "-"} />;
    },
  },
  {
    title: "Төлсөн дансны нэр",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return <RenderText text={record.creditAcc?.name || "-"} />;
    },
  },
  {
    title: "Хүлээн авсан дансны дугаар",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return <RenderText text={record?.debitAcc?.number || "-"} />;
    },
  },
  {
    title: "Хүлээн авсан дансны нэр",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return <RenderText text={record?.debitAcc?.name || "-"} />;
    },
  },
  {
    title: "Төлбөрийн хэрэгсэл",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return <RenderText text={paymentMethod.find((item: IReference) => item.code === record?.paymentMethod)?.name || "-"} />;
    },
  },
  {
    title: "Төлбөрийн төрөл",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return <RenderText text={repaymentHistoryPaymentTypes.find((item: IReference) => item.code === record?.paymentType)?.name || "-"} />;
    },
  },
  {
    title: "Төлбөрийн төлөв",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return <RenderText text={repaymentOverDueStatus.find((c: any) => c.code === record?.repaymentOverDueStatus)?.name || "-"} />;
    },
  },
  {
    title: "Нэхэмжлэхийн төлөв",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            border: `${(repaymentStatus || []).find((item: IReference) => item.code === record.repaymentStatus)?.color} 1px solid`,
            color: `${(repaymentStatus || []).find((item: IReference) => item.code === record.repaymentStatus)?.color}`,
          }}>
          {(repaymentStatus || []).find((c: any) => c.code === record.repaymentStatus)?.name || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Төлбөр төлсөн",
    sorter: true,
    dataIndex: "sku",
    align: "right",
    render: (record) => {
      return (
        <Flex gap="sm">
          <Avatar src={`${record?.actionUser?.avatar}`} />
          <RenderText text={renderName(record?.actionUser)} />ƒ
        </Flex>
      );
    },
  },
];
