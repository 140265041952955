import { Badge, Box, Button, Divider, Flex, Grid, Input, Text } from "@mantine/core";
import { IconDownload } from "@tabler/icons";
import React from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { LbfRequest } from "../../models/LbfRequest";
import { tugrug } from "../../utils";
import { dateSecFormat } from "../../utils/date";
import { Form } from "../form";

const schema = yup.object({});

export function FundingRequestBuyerInvoiceForm({ data, onNext, onBack }: { data: LbfRequest; onNext: () => void; onBack: () => void }) {
  const { invoiceOverdueStatus, invoiceStatus, invoicePaymentStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [initial] = React.useState<any>({});

  return (
    <Form validationSchema={schema} onSubmit={() => {}} initialValues={initial}>
      {() => {
        return (
          <Flex sx={{ position: "relative" }} direction="column">
            <Grid mt="xs">
              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ЕРӨНХИЙ МЭДЭЭЛЭЛ" />
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэхийн код:">
                  <Box color="indigo" mt={4}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {data?.invoice?.refCode || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэх илгээсэн:">
                  <Flex justify="flex-start" align="flex-start" direction="row" wrap="wrap" gap={5}>
                    <Text size="sm" weight={500} c="#3e166e">
                      {data?.invoice?.supplierFinUser?.lastName ? data?.invoice?.supplierFinUser?.lastName[0] : ""}
                      {data?.invoice?.supplierFinUser?.lastName && "."} {data?.invoice?.supplierFinUser?.firstName || "-"},
                    </Text>
                    <Text color="indigo" fz="sm" fw={600}>
                      {data?.requestedUser?.email || "-"}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэх баталсан:">
                  <Flex justify="flex-start" align="flex-start" direction="row" wrap="wrap" gap={5}>
                    <Text size="sm" weight={500} c="#3e166e">
                      {data?.invoice?.buyerFinUser?.lastName ? data?.invoice?.buyerFinUser?.lastName[0] : ""}
                      {data?.invoice?.buyerFinUser?.lastName && "."} {data?.invoice?.buyerFinUser?.firstName || "-"},
                    </Text>
                    <Text color="indigo" fz="sm" fw={600}>
                      {data?.invoice?.buyerFinUser?.email || "-"}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Баталсан дүн:">
                  <Box color="indigo" mt={4}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(data?.invoice?.confirmedAmount || 0)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Төлсөн дүн:">
                  <Box color="indigo" mt={4}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(data?.invoice?.paidAmount || 0)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэхийн үлдэгдэл:">
                  <Box color="indigo" mt={4}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(data?.invoice?.amountToPay || 0)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэх төлөв:">
                  <br />
                  <Badge
                    size="md"
                    radius="sm"
                    variant="outline"
                    style={{
                      border: `${(invoiceStatus || []).find((c: any) => c?.code === data?.invoice?.invoiceStatus)?.color} 1px solid`,
                      color: `${(invoiceStatus || []).find((c: any) => c?.code === data?.invoice?.invoiceStatus)?.color}`,
                    }}>
                    {(invoiceStatus || []).find((c: IReference) => c.code === data?.invoice?.invoiceStatus)?.name}
                  </Badge>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Төлбөрийн төлөв:">
                  <br />
                  <Badge
                    size="md"
                    radius="sm"
                    variant="outline"
                    style={{
                      border: `${(invoicePaymentStatus || []).find((c: IReference) => c?.code === data?.invoice?.paymentStatus)?.color} 1px solid`,
                      color: `${(invoicePaymentStatus || []).find((c: IReference) => c?.code === data?.invoice?.paymentStatus)?.color}`,
                    }}>
                    {(invoicePaymentStatus || []).find((c: IReference) => c.code === data?.invoice?.paymentStatus)?.name}
                  </Badge>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Хугацаа хэтрэлтийн төлөв:">
                  <br />
                  <Badge
                    size="md"
                    radius="sm"
                    variant="outline"
                    style={{
                      border: `${(invoiceOverdueStatus || []).find((c: any) => c?.code === data?.invoice?.overdueStatus)?.color} 1px solid`,
                      color: `${(invoiceOverdueStatus || []).find((c: any) => c?.code === data?.invoice?.overdueStatus)?.color}`,
                    }}>
                    {(invoiceOverdueStatus || []).find((c: any) => c?.code === data?.invoice?.overdueStatus)?.name}
                  </Badge>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Төлбөрийн нөхцөл:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {data?.invoice?.paymentTermDesc || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэх төлөх огноо:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {dateSecFormat(data?.invoice?.paymentDate)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэхийн баримт:">
                  <Box style={{ alignItems: "center" }}>
                    <Button
                      component="a"
                      href={""}
                      target="_blank"
                      rel="noreferrer"
                      variant="subtle"
                      color="indigo"
                      size="sm"
                      leftIcon={<IconDownload size={18} color="#4263EB" />}>
                      INV_320382.PDF
                    </Button>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="НИЙЛҮҮЛЭГЧ ТАЛЫН МЭДЭЭЛЭЛ" />
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Партнер нэр:">
                  <Flex justify="flex-start" align="flex-start" direction="row" wrap="wrap" gap={5}>
                    <Text size="sm" weight={500} c="#3e166e">
                      {data?.invoice?.supplier?.partner?.refCode || "-"},
                    </Text>
                    <Text color="indigo" fz="sm" fw={600}>
                      {data?.invoice?.supplier?.partner?.businessName || "-"}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Татвар төлөгчийн дугаар:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {data?.invoice?.supplier?.partner?.regNumber || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нийлүүлэгч бизнес:">
                  <Flex justify="flex-start" align="flex-start" direction="row" wrap="wrap" gap={5}>
                    <Text size="sm" weight={500} c="#3e166e">
                      {data?.invoice?.supplier?.refCode || "-"},
                    </Text>
                    <Text color="indigo" fz="sm" fw={600}>
                      {data?.invoice?.supplier?.profileName || "-"}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Нийлүүлэгчийн данс:">
                  <Flex justify="flex-start" align="flex-start" direction="row" wrap="wrap" gap={5}>
                    <Text size="sm" weight={500} c="#3e166e">
                      {data?.invoice?.supplierAccount?.number || "-"},
                    </Text>
                    <Text color="indigo" fz="sm" fw={600}>
                      {data?.invoice?.supplierAccount?.name || "-"},
                    </Text>
                    <Text color="indigo" fz="sm" fw={600}>
                      {data?.invoice?.supplierAccount?.bankName || "-"}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Борлуулалтын захиалга:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {data?.invoice?.orderSalesCode || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Санхүүгийн ажилтан:">
                  <Flex justify="flex-start" align="flex-start" direction="row" wrap="wrap" gap={5}>
                    <Text size="sm" weight={500} c="#3e166e">
                      {data?.invoice?.supplierFinUser?.lastName ? data?.invoice?.supplierFinUser?.lastName[0] : ""}
                      {data?.invoice?.supplierFinUser?.lastName && "."} {data?.invoice?.supplierFinUser?.firstName},
                    </Text>
                    <Text color="indigo" fz="sm" fw={600}>
                      {data?.invoice?.supplierFinUser?.phone || "-"}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ХУДАЛДАН АВАГЧИЙН МЭДЭЭЛЭЛ" />
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Партнерийн нэр:">
                  <Flex justify="flex-start" align="flex-start" direction="row" wrap="wrap" gap={5}>
                    <Text size="sm" weight={500} c="#3e166e">
                      {data?.invoice?.buyer?.partner?.refCode || "-"},
                    </Text>
                    <Text color="indigo" fz="sm" fw={600}>
                      {data?.invoice?.buyer?.partner?.businessName || "-"}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Татвар төлөгчийн дугаар:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {data?.invoice?.buyer?.partner?.regNumber || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Худалдан авагч бизнес:">
                  <Flex justify="flex-start" align="flex-start" direction="row" wrap="wrap" gap={5}>
                    <Text size="sm" weight={500} c="#3e166e">
                      {data?.invoice?.buyer?.refCode || "-"},
                    </Text>
                    <Text color="indigo" fz="sm" fw={600}>
                      {data?.invoice?.buyer?.profileName || "-"}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Худалдан авагчын данс:">
                  <Flex justify="flex-start" align="flex-start" direction="row" wrap="wrap" gap={5}>
                    <Text size="sm" weight={500} c="#3e166e">
                      {data?.invoice?.buyerAccount?.number || "-"},
                    </Text>
                    <Text color="indigo" fz="sm" fw={600}>
                      {data?.invoice?.buyerAccount?.name || "-"},
                    </Text>
                    <Text color="indigo" fz="sm" fw={600}>
                      {data?.invoice?.buyerAccount?.bankName || "-"}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Худалдан авалтын захиалга:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {data?.invoice?.orderPurchaseCode || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Санхүүгийн ажилтан:">
                  <Flex justify="flex-start" align="flex-start" direction="row" wrap="wrap" gap={5}>
                    <Text size="sm" weight={500} c="#3e166e">
                      {data?.invoice?.buyerFinUser?.lastName ? data?.invoice?.buyerFinUser?.lastName[0] : ""}
                      {data?.invoice?.buyerFinUser?.lastName && "."} {data?.invoice?.buyerFinUser?.firstName},
                    </Text>
                    <Text color="indigo" fz="sm" fw={600}>
                      {data?.invoice?.buyerFinUser?.phone || "-"}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} />
              </Grid.Col>
            </Grid>
            <Flex direction="row" justify="space-between" mt={10}>
              <Button variant="default" onClick={() => onBack()}>
                Болих
              </Button>
              <Flex gap="md">
                <Button
                  variant="filled"
                  onClick={() => {
                    onNext();
                  }}
                  type="submit">
                  Үргэлжлүүлэх
                </Button>
              </Flex>
            </Flex>
          </Flex>
        );
      }}
    </Form>
  );
}
