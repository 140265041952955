import { Switch } from "@mantine/core";
import { useField } from ".";

type Props = {
  name: string;
  label?: string | undefined;
  disabled?: boolean;
  nullable?: boolean;
  checked?: boolean | undefined;
  size?: any;
  position?: "right" | "left";
  children?: any;
  onChangeCustom?: any;
  readOnly?: boolean;
};

export function SwitchField({ name, label, disabled = false, nullable, size, position, onChangeCustom, readOnly }: Props) {
  const { value, error, onChange } = useField(name);

  return (
    <Switch
      label={label}
      disabled={disabled}
      error={error}
      size={size}
      labelPosition={position}
      checked={value}
      onLabel="Тийм"
      offLabel="Үгүй"
      onChange={(e) => {
        if (!readOnly) {
          onChange(e.currentTarget.checked);
          onChangeCustom && onChangeCustom(e.currentTarget.checked);
        }
      }}
      required={nullable ? false : true}
    />
  );
}
