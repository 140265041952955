import { Button, CloseButton, Divider, Flex, Grid, Text } from "@mantine/core";
import React from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { AuthApi, ProgramApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { IScfProgram } from "../../interfaces/IScfProgram";
import { IReference } from "../../models/General";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { OtpField } from "../form/otp-field";
import { SelectField } from "../form/select-field";
import { TextareaField } from "../form/textarea-field";
import { FormLayout } from "../layout";

const schema = yup.object({
  pin: yup.string().min(6, "Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
  inactiveType: yup.string().required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  pin?: string;
  statusDesc: string;
  inactiveType: string;
};

export function BuyerLedInActiveForm({ onCancel, reload, data }: { onCancel: () => void; data: IScfProgram[]; reload: () => void }) {
  const [loading, setLoading] = React.useState(false);
  const { programInactiveTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const [initialData] = React.useState<IFormData>({
    pin: "",
    statusDesc: "",
    inactiveType: "",
  });

  const active = async (values: IFormData) => {
    setLoading(true);

    try {
      if (data[1]?.id) {
        // SCF_PROGRAM_STATUS_CHANGE_BL
        // SCF_PROGRAM_STATUS_CHANGE_SL
        await ProgramApi.inactive(data[1].id, {
          inactiveType: values.inactiveType,
          statusDesc: values.statusDesc,
        });
        Message.success("Таны хүсэлт амжилттай.");
        reload();
      }
      onCancel();
    } catch (error) {
      Message.error((error as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      let res = await AuthApi.checkPin({ pin: values.pin });
      if (res?.success) {
        active({
          inactiveType: values.inactiveType,
          statusDesc: values.statusDesc,
        });
      }
    } catch (error) {
      Message.error((error as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <FormLayout title="Хөтөлбөр идэвхгүй болгох" subTitle="Хөтөлбөр идэвхгүй болгох." extra={[<CloseButton key="cancel" onClick={() => onCancel()} />]}>
      <Form validationSchema={schema} onSubmit={onSubmit} initialValues={initialData}>
        {() => {
          return (
            <>
              <Grid>
                <Grid.Col span={12}>
                  <Divider />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Text size="md" weight={500} c="#44566C" ta="center">
                    Та {data[1]?.refCode || "-"} дугаартай Supply Chain Finance хөтөлбөрийг идэвхгүй болгох гэж байна.
                  </Text>
                </Grid.Col>
                <Grid.Col span={12}>
                  <SelectField
                    name="inactiveType"
                    label="Идэвхгүй болгох төрөл:"
                    placeholder="Идэвхгүй болгох төрөл сонгох"
                    options={(programInactiveTypes || []).map((item: IReference) => {
                      return {
                        label: item.name || "",
                        value: item.code || "",
                      };
                    })}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <TextareaField name="statusDesc" label="Тайлбар:" placeholder="Тайлбар энд бичих" maxLength={255} withAsterisk={false} />
                </Grid.Col>
                <Grid.Col span={12}>
                  <OtpField name="pin" label="Баталгаажуулах пин:" placeholder="******" />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Text size="md" weight={500} c="#44566C" ta="center">
                    Танд амжилт хүсье!
                  </Text>
                </Grid.Col>
              </Grid>
              <Divider mt="lg" />
              <Flex mt={20} direction="row" justify="space-between">
                <Button variant="outline" onClick={() => onCancel && onCancel()} key={0}>
                  Болих
                </Button>
                <Button type="submit" loading={loading} key={1} variant="filled">
                  Идэвхгүй болгох
                </Button>
              </Flex>
            </>
          );
        }}
      </Form>
    </FormLayout>
  );
}
