import { ActionIcon, Avatar, Header as BaseHeader, createStyles, Group, Menu, Popover, Text, UnstyledButton } from "@mantine/core";
import { IconChevronDown, IconGridDots, IconLogout, IconNotebook } from "@tabler/icons";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AuthApi } from "../../apis";
import { ReactComponent as Logo } from "../../assets/DigitalEra.svg";
import noimage from "../../assets/no-image.png";
import { NavigationItem } from "../../constants/navigation";
import useUser from "../../hooks/user";
import { authChange, authMe } from "../../store/Auth";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { ActionsGrid } from "../popnav";

export const Header = ({ section }: { section?: NavigationItem }) => {
  const navigate = useNavigate();
  const { user } = useUser();
  const dispatch = useDispatch();
  const { classes, theme } = useStyles({ color: section?.color || "blue" });

  const LogOut = async () => {
    try {
      await AuthApi.logout();

      const removeToken = { accessToken: null };
      const removeUser = {};
      dispatch(authChange(removeToken as any));
      dispatch(authMe(removeUser as any));
      navigate("/auth/login");
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
  };

  return (
    <BaseHeader className={classes.header} height={{ base: 62, md: 68 }}>
      <div className={classes.row}>
        <div className={classes.logoAndText}>
          <Link to="/">
            <div className={classes.logo}>
              <Logo />
            </div>
          </Link>
          {section ? <span className={classes.headText}>{section.title}</span> : null}
        </div>
        <Group>
          <Popover
            radius={theme.radius.sm}
            shadow="sm"
            classNames={{
              dropdown: classes.popover,
            }}>
            <Popover.Target>
              <ActionIcon variant="transparent" color="gray.0">
                <IconGridDots size={86} stroke="2" />
              </ActionIcon>
            </Popover.Target>
            <Popover.Dropdown>
              <ActionsGrid />
            </Popover.Dropdown>
          </Popover>
          <Menu width={260} position="bottom-end" transition="pop-top-right">
            <Menu.Target>
              <UnstyledButton className={classes.user}>
                <Group>
                  <Avatar src={user?.avatar || noimage} radius="xl" />
                  <div style={{ flex: 1 }}>
                    <Group>
                      <Text size="sm" weight={500}>
                        {user?.lastName !== null ? user?.lastName : ""} {user?.firstName !== null ? user?.firstName : "-"}
                      </Text>
                    </Group>
                    <Text size="sm" weight={500} c="#c3cad4">
                      {user?.email || "-"}
                    </Text>
                  </div>

                  <IconChevronDown size={14} stroke={1.5} />
                </Group>
              </UnstyledButton>
            </Menu.Target>

            <Menu.Dropdown className={classes.dropDown}>
              <Menu.Item onClick={() => navigate("/settings/profile")} icon={<IconNotebook size={20} color={"#3e166e"} stroke={1.5} />}>
                Миний мэдээлэл
              </Menu.Item>
              <Menu.Item color="red" onClick={() => LogOut()} icon={<IconLogout size={20} stroke={1.5} />}>
                Системээс гарах
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </div>
      <div className={classes.headerLine} />
    </BaseHeader>
  );
};

const useStyles = createStyles((theme, { color }: { color: string }) => ({
  header: {
    display: "flex",
    flexDirection: "column",
    justifyItems: "center",
    alignItems: "center",
    backgroundColor: "#34105e",
    border: 0,
  },
  row: {
    width: "100%",
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    zIndex: 10,
  },
  background: {
    position: "absolute",
    opacity: 0.8,
    bottom: 0,
    zIndex: -1,
  },
  headText: {
    color: theme.white,
    fontSize: "1.2rem",
    fontWeight: 500,
  },
  logoAndText: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 20,
  },
  logo: {
    display: "flex",
    width: "160px",
    justifyContent: "end",
    alignItems: "end",
    marginBottom: "0",
    paddingLeft: "20px",
  },
  headerLine: {
    height: 2,
    background: theme.colors.golomt[6],
    width: "100%",
    marginTop: 6,
  },
  popover: {
    marginTop: 20,
    padding: 0,
    borderRadius: 8,
    border: `1px solid ${theme.colors.golomt[8]}`,
    background: "#3e166e",
  },
  dropDown: {
    marginTop: 5,
  },
  user: {
    color: theme.white,
    height: 60,
    padding: theme.spacing.xs,
    "&:hover": {
      borderRadius: 8,
      backgroundColor: "#00000040",
    },
  },
}));
