import { Button, CloseButton, Divider, Flex, Grid, Input, Text } from "@mantine/core";
import React from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { IGeneral } from "../../interfaces/IGeneral";
import { dateTimeFormat } from "../../utils/date";
import { Form } from "../form";
import { SelectField } from "../form/select-field";
import { SwitchField } from "../form/switch-field";
import { TextField } from "../form/text-field";
import { FormLayout } from "../layout";

const schema = yup.object({
  bankName: yup.string().required("Заавал бөглөнө!").nullable(),
});

type Props = {
  onCancel?: () => void;
  action: any[];
  payload?: any;
  reload?: any;
  setAction?: any;
  setChange?: any;
};

type IFormData = {
  bankName: string;
  number: string;
  name: string;
  currency: string;
  isDefault: boolean;
  verify: boolean;
};

export function BankAccountForm({ onCancel, payload, action, reload, setAction, setChange }: Props) {
  const { currencies } = useSelector((state: { general: IGeneral }) => state.general);
  const [data] = React.useState<IFormData>({
    bankName: "",
    number: "",
    name: "",
    currency: "",
    isDefault: false,

    ...(action && action[0] === "detail" ? action[1] : {}),
  });

  const onSubmit = async (data: any, send: boolean) => {
    if (send) setAction(["pin", data]);
  };

  return (
    <FormLayout title="B2B данс" subTitle="Анкорын хамтрагч бизнесүүд" my={0} extra={[<CloseButton key="cancel" onClick={() => setAction([])} />]}>
      <Form validationSchema={schema} onSubmit={() => {}} initialValues={data}>
        {({ values }) => {
          return (
            <>
              <Flex sx={{ position: "relative" }} direction="column" gap="md">
                <Grid>
                  <Grid.Col span={12}>
                    <Divider />
                  </Grid.Col>

                  <Grid.Col span={4}>
                    <Input.Wrapper label="Партнер нэр">
                      <Text color="indigo" fw={600}>
                        {action[1]?.partner?.businessName || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Input.Wrapper label="Татвар төлөгчийн дугаар">
                      <Text color="indigo" fw={600}>
                        {action[1]?.business?.regNumber || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Input.Wrapper label="Бизнесийн нэр">
                      <Text color="indigo" fw={600}>
                        {(action && action[1]?.business?.profileName) || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>

                  <Grid.Col span={4}>
                    <Input.Wrapper label="Огноо, цаг">
                      <Text color="indigo" fw={600}>
                        {`${dateTimeFormat(action && action[1]?.createdAt)}`}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Input.Wrapper label="Бүртгэсэн хэрэглэгч">
                      <Text color="indigo" fw={600}>
                        {(action && action[1]?.regUser?.lastName) || "-"} {(action && action[1]?.regUser?.firstName) || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Input.Wrapper label="Хэрэглэгчийн мэдээлэл">
                      <Text color="indigo" fw={600}>
                        {(action && action[1]?.regUser?.email) || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>

                  <Grid.Col span={4}>
                    <TextField name="bankName" label="Банк" placeholder="Банкны нэр" disabled />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <TextField name="number" label="Дансны дугаар" placeholder="Дансны дугаар" disabled />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <TextField name="name" label="Дансны нэр" placeholder="Дансны нэр" disabled />
                  </Grid.Col>

                  <Grid.Col span={4}>
                    <SelectField
                      name="currency"
                      label="Дансны нэр"
                      placeholder="Дансны нэр"
                      options={currencies.map((c: any) => ({ label: c.name, value: c.code }))}
                      disabled
                    />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Flex direction="column" gap={3} mt={4}>
                      <Text fz={14} fw={500} c="#212529">
                        Үндсэн данс эсэх: <span style={{ color: "red", marginLeft: 2, fontSize: 14 }}>*</span>
                      </Text>
                      <SwitchField name="isDefault" size="lg" />
                    </Flex>
                  </Grid.Col>
                </Grid>

                <Flex mt={20} direction="row" justify="space-between">
                  <Button variant="default" onClick={() => onCancel && onCancel()}>
                    Болих
                  </Button>
                  <Flex gap="md">
                    <Button
                      variant="outline"
                      type="submit"
                      onClick={() => {
                        onSubmit(values, true);
                        setChange(["cancel"]);
                      }}
                      disabled={action[1]?.isVerified && action[1]?.isActive}>
                      Татгалзах
                    </Button>
                    <Button
                      type="submit"
                      onClick={() => {
                        onSubmit(values, true);
                        setChange(["confirm"]);
                      }}
                      disabled={action[1]?.isVerified && action[1]?.isActive}>
                      Баталгаажуулах
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            </>
          );
        }}
      </Form>
    </FormLayout>
  );
}
