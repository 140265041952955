import { ReferenceApi } from "../../apis";
import { IEconomicalSector } from "../../interfaces/IEconomicalSector";
import { ColumnType, Table } from "../table";
import RenderText from "../ui/render-text";

export function ScfDcfReferenceLevel2() {
  const columns = useHeader();

  return (
    <Table name="scf-program-reference-level-2.list" columns={columns} filters={{ query: "", level: "2" }} loadData={(data) => ReferenceApi.list(data!)} />
  );
}

const useHeader = (): ColumnType<IEconomicalSector>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    title: "Лавлах код",
    sorter: true,
    dataIndex: "code",
    render: (record) => {
      return <RenderText text={record?.code || "-"} />;
    },
  },
  {
    title: "Лавлах нэр",
    sorter: true,
    dataIndex: "name",
    width: 450,
    render: (record) => {
      return <RenderText text={record?.name || "-"} />;
    },
  },
  {
    title: "Түвшин",
    sorter: true,
    dataIndex: "level",
    width: 240,
    render: (record) => {
      return <RenderText text={record?.level || "-"} />;
    },
  },
  {
    title: "Үндсэн ангилал",
    sorter: true,
    dataIndex: "parent",
    render: (record) => {
      return <RenderText text={record?.parent?.name || "-"} />;
    },
  },
];
