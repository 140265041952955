import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { LbfProgramApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { ISystemHistory } from "../../interfaces/ISystemHistory";
import { IReference } from "../../models/General";
import { dateSecFormat } from "../../utils/date";
import { renderName } from "../../utils/render-name";
import { ColumnType, Table } from "../table";
import RenderText from "../ui/render-text";

export function FundingProgramBuyerLedHistoryList() {
  const params = useParams();
  const { programHistoryTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const columns = useHeader({ programHistoryTypes });

  return (
    <Table
      name="buyer-led-program-history.list"
      columns={columns}
      filters={{ type: "", actionUserId: "", lbfProgramId: `${params.id}` }}
      loadData={(data) => LbfProgramApi.history(data!)}
    />
  );
}

const useHeader = ({ programHistoryTypes }: { programHistoryTypes: IReference[] }): ColumnType<ISystemHistory>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    title: "Код",
    sorter: true,
    dataIndex: "productRefCode",
    render: (record) => {
      return <RenderText text={record?.programRefCode || "-"} />;
    },
  },
  {
    title: "Системд хийсэн үйлдэл",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return <RenderText text={(programHistoryTypes || []).find((c: IReference) => c?.code === record?.type)?.name || "-"} />;
    },
  },
  {
    title: "Төрөл",
    sorter: true,
    dataIndex: "type",
    render: (record) => {
      return <RenderText text={record.type ?? "-"} />;
    },
  },
  {
    title: "Тайлбар",
    sorter: true,
    dataIndex: "description",
    render: (record) => {
      return <RenderText text={record.description || "-"} />;
    },
  },
  {
    title: "Огноо, цаг",
    sorter: true,
    dataIndex: "createdAt",
    render: (record) => {
      return <RenderText text={dateSecFormat(record.createdAt)} />;
    },
  },
  {
    title: "Хэрэглэгч",
    sorter: true,
    dataIndex: "actionUser",
    render: (record) => {
      return <RenderText text={renderName(record?.actionUser)} />;
    },
  },
];
