import { Badge } from "@mantine/core";
import { useSelector } from "react-redux";
import { IAccount } from "../../interfaces/IAccount";
import { IGeneral } from "../../interfaces/IGeneral";
import { ColumnType, Table } from "../table";
import RenderText from "../ui/render-text";

type Props = {
  data: any;
};

export function AncorDetailAccountList({ data }: Props) {
  const { currencies } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({
    currencies,
    onClick: (key, item) => {},
  });

  return <Table name="ancor.detail.buyers.account.list" columns={columns} filters={{}} dataSource={data} pagination={false} />;
}

const useHeader = ({ onClick, currencies }: { onClick: (key: string, record: IAccount) => void; currencies: any }): ColumnType<IAccount>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    title: "Товч нэр",
    sorter: true,
    dataIndex: "status",
    render: (record) => {
      return <RenderText text={record?.shortName || "-"} />;
    },
  },
  {
    title: "Дансны нэр",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return <RenderText text={record.name || "-"} />;
    },
  },
  {
    title: "Дансны дугаар",
    sorter: true,
    dataIndex: "number",
    render: (record) => {
      return <RenderText text={record.number || "-"} />;
    },
  },
  {
    title: "Банк",
    sorter: true,
    dataIndex: "bankName",
    render: (record) => {
      return <RenderText text={record.bankName || "-"} />;
    },
  },
  {
    title: "Валют",
    sorter: true,
    dataIndex: "currency",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline">
          {currencies.find((c: any) => c.code === record.currency)?.name || "-"}
        </Badge>
      );
    },
  },
  // {
  //   title: "Тайлбар",
  //   sorter: true,
  //   dataIndex: "programCount",
  //   render: (record) => {
  //     return (
  //       <Text size="sm" weight={500} c="#44566C">
  //         {"Тайлбар "}
  //       </Text>
  //     );
  //   },
  // },
];
