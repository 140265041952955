import { Avatar, Badge, Box, Button, Divider, Drawer, Flex, Grid, Input, Select, Tooltip } from "@mantine/core";
import { DateRangePicker } from "@mantine/dates";
import { IconCalendar, IconCheck, IconInfoSquare, IconListSearch, IconMinus } from "@tabler/icons";
import React, { useId, useRef } from "react";
import { IMaskInput } from "react-imask";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { RequestApi } from "../../apis";
import noimage from "../../assets/no-image.png";
import { IFundingRequest } from "../../interfaces/IFundingRequest";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { tugrug } from "../../utils";
import { dateSecFormat } from "../../utils/date";
import { renderName } from "../../utils/render-name";
import { ScfSupplierFinanceRequestTabs } from "../scf-request";
import { ColumnType, ITableRef, Table } from "../table";
import RenderText from "../ui/render-text";

export function ScfFundingSupplierLedRequestList({ setIndex, data }: { data: any; setIndex: any }) {
  const id = useId();
  const params = useParams();
  const navigate = useNavigate();
  const ref = useRef<ITableRef>(null);
  const { requestStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [action, setAction] = React.useState<string[]>([]);
  const [filters, setFilter] = React.useState<any>({
    parentId: `${params.id}`,
    status: "",
    responseStartDate: "",
    responseEndDate: "",
    minAmount: "",
    maxAmount: "",
    businessId: "",
    businessRegNumber: "",
  });

  const columns = useHeader({
    requestStatus,
    onClick: (key, record) => {
      switch (key) {
        case "detail": {
          setAction(["detail", record as any]);
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
  };

  const onChangeReg = (e: any) => {
    if (e.length === 7 || e.length === 0) {
      setFilter({ ...filters, businessRegNumber: e });
    }
  };

  return (
    <>
      <Flex sx={{ position: "relative" }} direction="column" mt={20}>
        <Grid>
          <Grid.Col span={4}>
            <Select
              label="Хүсэлтийн төлөв"
              onChange={(e: any) => {
                setFilter({ ...filters, status: e });
              }}
              key={1}
              placeholder="Сонгох"
              clearable
              data={(requestStatus || []).map((c) => ({
                label: c.name,
                value: c.code,
              }))}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <DateRangePicker
              value={[filters.responseStartDate, filters.responseEndDate]}
              onChange={(e) => {
                setFilter({ ...filters, responseStartDate: e[0] && e[0], responseEndDate: e[1] && e[1] });
              }}
              label="Хариу өгөх огноо:"
              placeholder="сонгох"
              icon={<IconCalendar size={16} />}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Select label="Хүсэлтийн дүн" onChange={() => {}} key={3} placeholder="Талбараар" clearable data={[]} />
          </Grid.Col>
          <Grid.Col span={4}>
            <Select label="Хөтөлбөрийн нэр" onChange={() => {}} key={4} placeholder="Талбараар" clearable data={[]} />
          </Grid.Col>
          <Grid.Col span={4}>
            <Select label="Худалдан авагчийн" onChange={() => {}} key={5} placeholder="Талбараар" clearable data={[]} />
          </Grid.Col>
          <Grid.Col span={4}>
            <Input.Wrapper id={id} label="Харилцагчийн ТТД-аар хайх" onChange={(e: any) => onChangeReg(e.target.value)}>
              <Input<any> component={IMaskInput} mask="0000000" id={id} placeholder="ТТД - дугаараар хайх" rightSection={<IconListSearch stroke={0.8} />} />
            </Input.Wrapper>
          </Grid.Col>
        </Grid>

        <Divider my="sm" mb="sm" mt="sm" />
        <Table ref={ref} name="scf.funding.supplier.request.list" columns={columns} filters={filters} loadData={(data) => RequestApi.request(data!)} />
        <Divider my="sm" mb="sm" mt="sm" />
        <Flex direction="row" justify="space-between">
          <Button
            variant="default"
            onClick={() => {
              setIndex("1");
              navigate(`/scf/funding-provided/SUPPLIER/${params.id}/1`);
            }}>
            Буцах
          </Button>
          <Flex gap="md">
            <Button onClick={() => navigate("/scf/funding-provided")}>Жагсаалтруу шилжих</Button>
          </Flex>
        </Flex>
      </Flex>
      <Drawer
        opened={action && action[0] === "detail"}
        onClose={() => onCancel()}
        withCloseButton={false}
        position="right"
        size="80%"
        styles={{ drawer: { overflowY: "scroll" } }}>
        <ScfSupplierFinanceRequestTabs action={action} onCancel={onCancel} setAction={setAction} />
      </Drawer>
    </>
  );
}

const useHeader = ({
  onClick,
  requestStatus,
}: {
  onClick: (key: string, record: IFundingRequest) => void;
  requestStatus: IReference[];
}): ColumnType<IFundingRequest>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="xs">
          {record.settlementStatus !== "NONE" ? (
            <Tooltip label="Дэлгэрэнгүй" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
                <IconInfoSquare />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="Дэлгэр11энгүй" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("", record)}>
                <IconInfoSquare stroke={0.4} />
              </Button>
            </Tooltip>
          )}
        </Flex>
      );
    },
  },
  {
    title: "Хүсэлтийн #",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Box>
          <RenderText text={record.refCode || "-"} />
          <RenderText text={dateSecFormat(record.createdAt)} />
        </Box>
      );
    },
  },
  {
    title: "Хүсэлтийн дүн",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return <RenderText text={tugrug(record.requestedAmount || 0)} isAmount />;
    },
  },
  {
    title: "Хүсэлт төлөв",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            border: `${(requestStatus || []).find((c: any) => c?.code === record?.requestStatus)?.color} 1px solid`,
            color: `${(requestStatus || []).find((c: any) => c?.code === record?.requestStatus)?.color}`,
          }}>
          {(requestStatus || []).find((c: any) => c?.code === record?.requestStatus)?.name || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Эргэн төлөх",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return <RenderText text={dateSecFormat(record.repaymentDate)} />;
    },
  },
  {
    title: "Хүү тооцож эхлэх",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return <RenderText text={dateSecFormat(record.interestStartDate)} />;
    },
  },
  {
    title: "Хүү тооцох хоног",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return <RenderText text={record.financePeriod || "0"} />;
    },
  },
  {
    title: "Тооцсон SCF хүү",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Flex align="center" gap="xs">
          <IconCheck color="green" size={16} />
          <RenderText text={tugrug(record.calculatedFeeAmount || 0)} isAmount />
        </Flex>
      );
    },
  },
  {
    title: "Олголтын шимтгэл",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return <RenderText text={tugrug(record.disbursementFee || 0)} isAmount />;
    },
  },
  {
    title: "Өргөдлийн хураамж",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return <RenderText text={tugrug(record.appFee || 0)} isAmount />;
    },
  },
  {
    title: "Гүйлгээний хураамж",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return <RenderText text={tugrug(record.trxFee || 0)} isAmount />;
    },
  },
  {
    title: "Худалдан авагч",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Box>
          <RenderText text={record?.invoice?.receiverBusiness?.partner?.refCode || "-"} />
          <RenderText text={record?.invoice?.receiverBusiness?.partner?.businessName || "-"} color="#141517" />
        </Box>
      );
    },
  },
  {
    title: "Худ.авагч бизнес",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Box>
          <RenderText text={`${record?.invoice?.receiverBusiness?.refCode || "-"}, ${record?.invoice?.receiverBusiness?.regNumber || "-"}`} />
          <RenderText text={record?.invoice?.receiverBusiness?.profileName || "-"} color="#141517" />
        </Box>
      );
    },
  },
  {
    title: "Нэхэмжлэх",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Box>
          <RenderText text={record.invRefCode || "-"} />
          <RenderText text={dateSecFormat(record.invCreatedAt)} />
        </Box>
      );
    },
  },
  {
    title: "Нэхэмжлэх үлдэгдэл",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return <RenderText text={tugrug(record.invAmountToPay || 0)} isAmount />;
    },
  },
  {
    title: "Нэхэмжлэх тенор",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Flex align="center" gap="xs">
          {record.tenorAcceptable ? <IconCheck color="green" size={16} /> : <IconMinus color="red" size={16} />}
          <RenderText text={record.remainingDays || "0"} />
        </Flex>
      );
    },
  },
  {
    title: "Буцаан дуудах огноо",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return <RenderText text={record.recourseTerm ? dateSecFormat(record.recourseDate) : "Хүсэлтийг автоматаар зөвшөөрсөн"} />;
    },
  },
  {
    title: "Санхүүжилт зогсоох огноо",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return <RenderText text={dateSecFormat(record.restrictDate)} />;
    },
  },
  {
    title: "Хүсэлт илгээсэн",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Flex align="center" gap="xs">
          <Avatar src={record?.requestedUser?.avatar !== null ? record?.requestedUser?.avatar : noimage} />
          <RenderText text={renderName(record?.requestedUser)} />
        </Flex>
      );
    },
  },
  {
    title: "Хариу өгөх огноо",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return <RenderText text={dateSecFormat(record.responseDate)} />;
    },
  },
];
