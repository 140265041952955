import { ActionIcon, Badge, Box, Button, Checkbox, Divider, Flex, Grid, Group, Input, LoadingOverlay, Text } from "@mantine/core";
import { IconCheck, IconDownload, IconX } from "@tabler/icons";
import React from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { tugrug } from "../../utils";
import { dateSecFormat } from "../../utils/date";
import { Form } from "../form";

const schema = yup.object({});

type IFormData = {
  name: string;
};

type Props = {
  action: any;
  onCancel: () => void;
  setTabIndex: any;
};

export function ScfFundingRequestForm({ action, onCancel, setTabIndex }: Props) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { currencies, productSuppFeeRules, productBuyerFeeRules, productSuppPenaltyTypes, requestStatus } = useSelector(
    (state: { general: IGeneral }) => state.general,
  );
  const [data] = React.useState<IFormData>({
    name: "",
  });

  const onSubmit = () => {};

  const onExport = (e: string) => {
    setLoading(true);
    window.open(e, "_blank");
    setLoading(false);
  };

  if (loading) return <LoadingOverlay visible={loading} />;

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {() => {
        return (
          <Flex sx={{ position: "relative" }} direction="column" gap="md">
            <Grid>
              <Grid.Col span={12} mt="xs">
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ХҮСЭЛТИЙН МЭДЭЭЛЭЛ" />
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Хүсэлтийн код:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.refCode}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Хүсэлтийн төрөл:">
                  <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                    {action?.type === "SUPPLIER" ? "Supplier-Эрт санхүүжилт" : "Buyer-Дараа төлөлт"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Хүсэлт гаргасан бизнес:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.requestedBusiness?.profileName || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Хүсэлтийн огноо:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {dateSecFormat(action?.createdAt)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Хүсэлт гаргасан хэрэглэгч:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.requestedUser?.lastName || "-"} {action?.requestedUser?.firstName}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Хүсэлтийн төлөв:">
                  <br />
                  <Badge
                    size="md"
                    radius="sm"
                    variant="outline"
                    style={{
                      border: `${(requestStatus || []).find((c: any) => c?.code === action?.requestStatus)?.color} 1px solid`,
                      color: `${(requestStatus || []).find((c: any) => c?.code === action?.requestStatus)?.color}`,
                    }}>
                    {(requestStatus || []).find((c: IReference) => c.code === action?.requestStatus)?.name}
                  </Badge>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Хөтөлбөрийн нэр:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.program?.name}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Бүтээгдэхүүний нэр:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.product?.name}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Санхүүжилт хүссэн дүн:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(action?.requestedAmount || "0")}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Санхүүжилтийн валют:">
                  <div>
                    <Badge radius="sm" variant="outline">
                      {currencies.find((item) => item?.code === action?.currency)?.name || "-"}
                    </Badge>
                  </div>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Санхүүжилтийн доод, дээд хэмжээ:">
                  <Flex gap="xs" align="center">
                    {action?.finMinAmount <= action?.finMaxAmount ? <IconCheck size={20} color="green" /> : <IconX size={20} color="red" />}
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(action?.finMinAmount || "0")} {"-"} {tugrug(action?.finMaxAmount || "0")}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Санхүүжилтийн шимтгэл:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(action?.calculatedFeeAmount || "0")}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Санхүүжилт авах хугацаа/хоног/:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.financePeriod || 0}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Шимтгэл тооцсон хувь/хоногт/:">
                  <Flex gap="xs" align="center">
                    {<IconCheck size={20} color="green" />}
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.calculatedFeePercent} {"%"}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Өргөдлийн хураамж:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(action?.appFee || "0")}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Олголтын шимтгэл:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(action?.disbursementFee || "0")}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Нийт шимтгэлийн дүн:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(action?.totalScfFeeAmount || "0")}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Шимтгэл суутгах дүрэм:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {(action?.type === "SUPPLIER"
                        ? productSuppFeeRules.find((c: IReference) => c.code === action?.feeRule)?.name
                        : productBuyerFeeRules.find((c: IReference) => c.code === action?.feeRule)?.name) || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Шимтгэлийн доод дүн:">
                  <Flex gap="xs" align="center">
                    {action?.type === "SUPPLIER" ? (
                      action?.product?.suppMinFee
                    ) : action?.product?.buyerMinFee || "0" <= action?.totalScfFeeAmount ? (
                      <IconCheck size={20} color="green" />
                    ) : (
                      <IconX size={20} color="red" />
                    )}
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {/* {tugrug(action?.minFeeAmount || "0")} */}
                      {tugrug(action?.type === "SUPPLIER" ? action?.product?.suppMinFee || "0" : action?.product?.buyerMinFee || "0")}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Эргэн төлөх хугацаа:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {dateSecFormat(action?.repaymentDate)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Буцаан дуудах эсэх:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.recourseTerm ? "Тийм" : "Үгүй"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Хугацаа хэтрэх алданги:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.penaltyPercent || "0"} {"%"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Алдангийн арга:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {productSuppPenaltyTypes.find((c: IReference) => c.code === action?.penaltyType)?.name || "-"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="БУСАД ШАЛГУУРУУД" />
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэх доод үлдэгдэл:">
                  <Flex gap="xs" align="center">
                    {<IconCheck size={20} color="green" />}
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {tugrug(action?.minInvBalance || "0")}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэх тенор:">
                  <Flex gap="xs" align="center">
                    {action?.minTenor < action?.maxTenor ? <IconCheck size={20} color="green" /> : <IconX size={20} color="red" />}
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.minTenor || "0"} {"-"} {action?.maxTenor || "0"}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}></Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэхийн хугацаа:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.invConfirmedDays || "0"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэхийн хугацаа:">
                  <Flex gap="xs" align="center">
                    {action?.minDays <= action?.maxDays ? <IconCheck size={20} color="green" /> : <IconX size={20} color="red" />}
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.minDays || "0"} {"-"} {action?.maxDays || "0"}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Тенорийн шаардлага:">
                  <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                    <Group>
                      <Checkbox checked={action?.tenorAcceptable ? true : false} onChange={() => {}} /> {action?.tenorAcceptable ? "Тийм" : "Үгүй"}
                    </Group>
                  </Text>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Санхүүжилт авах данс:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action?.requestedBusinessAcc?.number || "-"}, {action?.requestedBusinessAcc?.name}, {action?.requestedBusinessAcc?.bankName}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Хавсралт файл: ">
                  <Flex gap="md" justify="flex-start" align="center" direction="row" wrap="wrap">
                    {(action?.contractFiles || []).map((item: any, index: number) => {
                      return (
                        <Flex gap={5} justify="flex-start" align="center" direction="row" wrap="wrap" key={index}>
                          <Text size="lg" c="#457be8">{`Файл ${index + 1}`}</Text>
                          <ActionIcon variant="outline" style={{ border: `1px solid #457be8` }} size={26}>
                            <IconDownload size={16} color="#4263EB" onClick={() => onExport(item.url)} />
                          </ActionIcon>
                        </Flex>
                      );
                    })}
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Гэрээ баталгаажилт:">
                  <Box style={{ alignItems: "center" }}>
                    <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {"Баталгаажсан"}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} />
              </Grid.Col>
            </Grid>
            <Flex direction="row" justify="space-between">
              <Button
                variant="default"
                onClick={() => {
                  setTabIndex("1");
                }}>
                Буцах
              </Button>
              <Flex gap="md">
                <Button
                  variant="filled"
                  onClick={() => {
                    setTabIndex("3");
                  }}
                  type="submit">
                  Үргэлжлүүлэх
                </Button>
              </Flex>
            </Flex>
          </Flex>
        );
      }}
    </Form>
  );
}
