import { Alert, Avatar, Button, Divider, Flex, Grid, Group, LoadingOverlay, Text, UnstyledButton } from "@mantine/core";
import { IconCirclePlus, IconInfoCircle, IconTrash, IconUserPlus } from "@tabler/icons";
import React from "react";
import { useSelector } from "react-redux";
import useSwr from "swr";
import * as yup from "yup";
import { ApprovelApi } from "../../apis";
import noimage from "../../assets/no-image.png";
import { IApprovelRuleLevel } from "../../interfaces/IApprovelRuleLevel";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { MultiSelectField } from "../form/multi-select-field";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { TextareaField } from "../form/textarea-field";

type LevelType = {
  level: number;
  userIds: string[];
};

const schema = yup.object({
  module: yup.string().required("Заавал бөглөнө үү!").nullable(),
  systemAction: yup.string().required("Заавал бөглөнө үү!").nullable(),
  name: yup.string().max(100, "Хэтэрхий урт байна!").required("Заавал бөглөнө үү!").nullable(),
  levels: yup.array().of(
    yup.object().shape({
      userIds: yup.string().required("Заавал бөглөнө үү!").nullable().typeError(""),
    }),
  ),
});

type Props = {
  action: any[];
  setAction: any;
  reload: any;
};

type IFormdata = {
  module: string;
  systemAction: string;
  name: string;
  description: string;
  levels: IApprovelRuleLevel[];
};

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
  position: string;
  item?: any;
}

export function ApprovelRuleForm({ action, reload, setAction }: Props) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { actions, modules, users } = useSelector((state: { general: IGeneral }) => state.general);

  const [data] = React.useState<IFormdata>({
    module: "",
    systemAction: "",
    name: "",
    description: "",
    levels: [],
    ...(action && action[0] === "detail"
      ? {
          ...action[1],
        }
      : {
          levels: [{ level: 1, userIds: "" }],
        }),
  });

  const { data: levelOne } = useSwr(`/api/user/approvel/rules/data/level-one/${action && action[1]?.id}`, async () => {
    if (action && action[0] !== "detail") {
      return [];
    }

    let res = await ApprovelApi.get(action && action[1]?.id);
    let levelOne = (res?.levels || []).filter((c: any) => c.level === 1);
    return levelOne;
  });
  const { data: levelTwo } = useSwr(`/api/user/approvel/rules/data/level-two/${action && action[1]?.id}`, async () => {
    if (action && action[0] !== "detail") {
      return [];
    }

    let res = await ApprovelApi.get(action && action[1]?.id);
    let levelTwo = (res?.levels || []).filter((c: any) => c.level === 2);
    return levelTwo;
  });

  const { data: levelThree } = useSwr(`/api/user/approvel/rules/data/level-three/${action && action[1]?.id}`, async () => {
    if (action && action[0] !== "detail") {
      return [];
    }

    let res = await ApprovelApi.get(action && action[1]?.id);
    let levelThree = (res?.levels || []).filter((c: any) => c.level === 3);
    return levelThree;
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      await ApprovelApi.create({
        module: data.module,
        systemAction: data.systemAction,
        name: data.name,
        description: data.description,
        levels: data.levels,
      });
      setAction([]);
      setLoading(false);
      Message.success("Амжилттай хяналтын дүрмийг нэмлээ!");
      reload();
    } catch (err) {
      Message.error((err as HttpHandler)?.message! || "Алдаа гарлаа");
      setLoading(false);
    }
  };

  const SelectItem = React.forwardRef<HTMLDivElement, ItemProps>(({ image, label, position, item, ...others }: ItemProps, ref) => (
    <Flex align={"center"} gap={8} ref={ref} {...others}>
      <Group noWrap>
        <Avatar src={item?.image} />
        <div>
          <Text size="sm">{label}</Text>
          <Text size="xs" opacity={0.65}>
            {position}
          </Text>
        </div>
      </Group>
    </Flex>
  ));

  const onRemove = (index: any, result: [], setFieldValue: any) => {
    result.splice(index, 1);
    const results = result.map((c: any, i: number) => ({
      level: i + 1,
      userIds: c?.userIds,
    }));

    setFieldValue("levels", results);
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values, setFieldValue, errors }) => {
        return (
          <Flex sx={{ position: "relative" }} direction="column" gap="md">
            <LoadingOverlay visible={loading} />
            <Divider color="gray" />
            <Grid>
              <Grid.Col span={4}>
                <SelectField
                  name="module"
                  label="Системийн модуль:"
                  placeholder="Сонгох"
                  options={(modules || []).map((c: IReference) => ({ label: c.name, value: c.code }))}
                  disabled={action[0] === "detail"}
                  withAsterisk={action[0] === "detail" ? false : true}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <SelectField
                  name="systemAction"
                  label="Системийн үйлдэл:"
                  placeholder="Сонгох"
                  options={(actions || []).map((c: any) => ({ label: c.name, value: c.module }))}
                  disabled={action[0] === "detail"}
                  withAsterisk={action[0] === "detail" ? false : true}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextField
                  name="name"
                  label="Дүрмийн нэр:"
                  placeholder="Дүрмийн нэрийг оруулна уу."
                  disabled={action[0] === "detail"}
                  withAsterisk={action[0] === "detail" ? false : true}
                />
              </Grid.Col>

              <Grid.Col span={8}>
                <TextareaField
                  name="description"
                  placeholder="Тайлбар оруулах"
                  label="Тайлбар оруулах"
                  disabled={action[0] === "detail"}
                  maxLength={255}
                  withAsterisk={false}
                />
              </Grid.Col>
              <Grid.Col span={4}></Grid.Col>

              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ХЯНАЛТЫН ДҮРЭМ" />
              </Grid.Col>

              {values?.levels?.length === 3 && (
                <>
                  <Grid.Col span={4}>
                    <Alert
                      icon={<IconInfoCircle size="1rem" />}
                      title="Санамж!"
                      color="yellow"
                      styles={{ message: { color: "#44566c", fontWeight: 500 } }}
                      withCloseButton={false}>
                      <Text fz="xs">
                        Хэрэглэгч та хамгийн ихдээ гурван түвшин нэмэх боломжтой. Мөн өмнөх түвшинд сонгогдсон хэрэглэгчийг дахин сонгож болохгүйг анхаарна уу
                        баярлалаа.
                      </Text>
                    </Alert>
                  </Grid.Col>
                  <Grid.Col span={8}></Grid.Col>
                </>
              )}
              {action && action[0] === "new" ? (
                values?.levels?.map((level: LevelType, index: number) => (
                  <Grid.Col span={4} key={index}>
                    <Flex justify="flex-start" direction="row">
                      <Button color="#536ded" fullWidth mr="xs">
                        {index + 1}-р түвшин
                      </Button>
                      {level?.level === values?.levels?.length && values?.levels?.length !== 3 ? (
                        <UnstyledButton
                          onClick={() => {
                            setFieldValue("levels", [
                              ...values.levels,
                              {
                                level: values.levels.length + 1,
                                userIds: "",
                              },
                            ]);
                          }}>
                          <IconCirclePlus size={35} stroke={1} color="#536ded" />
                        </UnstyledButton>
                      ) : null}

                      {level?.level === values?.levels?.length && values?.levels?.length !== 1 ? (
                        <UnstyledButton
                          onClick={() => {
                            onRemove(index, values.levels, setFieldValue);
                          }}
                          disabled={values?.levels?.length === 1}>
                          <IconTrash size={35} stroke={1} color="#536ded" />
                        </UnstyledButton>
                      ) : (
                        ""
                      )}
                    </Flex>
                    <Text c="dimmed" size="xs" style={{ textAlign: "justify" }} mt="sm">
                      Хяналтыг нэг түвшинд хийх бол нэмэх шаардлагагүй. Давхар хяналт хийх бол "+" сонгож хяналтын түвшинг нэмнэ үү. Сүүлчийнх бол "Stop"
                      сонгоно уу.
                    </Text>
                    <Flex gap="md" justify="flex-start" direction="row">
                      <IconUserPlus stroke={1} color="#536ded" />
                      <UnstyledButton>
                        <Text color="#536ded">Хянах ажилтан</Text>
                      </UnstyledButton>
                    </Flex>
                    <MultiSelectField
                      label={`${level.level}. Ажилтан`}
                      name={`levels[${index}].userIds`}
                      options={users
                        .filter((c: any) => {
                          return (
                            !values.levels
                              .map((l: any) => l.userIds)
                              .flat()
                              .includes(c.id) || (values.levels[index].userIds || []).includes(c.id)
                          );
                        })
                        .map((item: any) => {
                          return { value: item.id, label: `${item?.firstName}, ${item?.lastName}`, item };
                        })}
                      placeholder="Сонгох"
                      itemComponent={SelectItem}
                      filter={(value: string, selected: boolean, item: any) => {
                        return (
                          !selected &&
                          (item.label.toLowerCase().includes(value.toLowerCase().trim()) || item.description.toLowerCase().includes(value.toLowerCase().trim()))
                        );
                      }}
                    />
                  </Grid.Col>
                ))
              ) : (
                <>
                  {(levelOne || [])?.length > 0 && (
                    <Grid.Col span={4}>
                      <Flex justify="flex-start" direction="row">
                        <Button color="#536ded" fullWidth mr="xs">
                          1-р түвшин
                        </Button>
                      </Flex>
                      <Flex gap="md" justify="flex-start" direction="row" mt={5}>
                        <IconUserPlus stroke={1} color="#536ded" />
                        <UnstyledButton>
                          <Text color="#536ded">Хянах ажилтан</Text>
                        </UnstyledButton>
                      </Flex>
                      <Flex gap={2} justify="flex-start" align="flex-start" direction="column" wrap="wrap">
                        {(levelOne || []).map((item: any, key: number) => {
                          return (
                            <Group>
                              <Text size="sm" weight={500} c="#44566C">
                                {key + 1}.
                              </Text>
                              <Avatar src={!item?.user?.avatar || !item?.user ? noimage : item?.user?.avatar} />
                              <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
                                <Text size="sm" weight={500} c="#44566C">
                                  {item?.user?.lastName || "-"}
                                </Text>
                                <Text size="sm" weight={500} c="#44566C">
                                  {item?.user?.firstName || "-"}
                                </Text>
                              </Flex>
                            </Group>
                          );
                        })}
                      </Flex>
                    </Grid.Col>
                  )}
                  <>
                    {(levelTwo || [])?.length > 0 && (
                      <Grid.Col span={4}>
                        <Flex justify="flex-start" direction="row">
                          <Button color="#536ded" fullWidth mr="xs">
                            2-р түвшин
                          </Button>
                        </Flex>
                        <Flex gap="md" justify="flex-start" direction="row" mt={5}>
                          <IconUserPlus stroke={1} color="#536ded" />
                          <UnstyledButton>
                            <Text color="#536ded">Хянах ажилтан</Text>
                          </UnstyledButton>
                        </Flex>
                        <Flex gap={2} justify="flex-start" align="flex-start" direction="column" wrap="wrap">
                          {(levelTwo || []).map((item: any, key: number) => {
                            return (
                              <Group>
                                <Text size="sm" weight={500} c="#44566C">
                                  {key + 1}.
                                </Text>
                                <Avatar src={!item?.user?.avatar || !item?.user ? noimage : item?.user?.avatar} />
                                <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
                                  <Text size="sm" weight={500} c="#44566C">
                                    {item?.user?.lastName || "-"}
                                  </Text>
                                  <Text size="sm" weight={500} c="#44566C">
                                    {item?.user?.firstName || "-"}
                                  </Text>
                                </Flex>
                              </Group>
                            );
                          })}
                        </Flex>
                      </Grid.Col>
                    )}
                    {(levelThree || [])?.length > 0 && (
                      <Grid.Col span={4}>
                        <Flex justify="flex-start" direction="row">
                          <Button color="#536ded" fullWidth mr="xs">
                            3-р түвшин
                          </Button>
                        </Flex>
                        <Flex gap="md" justify="flex-start" direction="row" mt={5}>
                          <IconUserPlus stroke={1} color="#536ded" />
                          <UnstyledButton>
                            <Text color="#536ded">Хянах ажилтан</Text>
                          </UnstyledButton>
                        </Flex>
                        <Flex gap={2} justify="flex-start" align="flex-start" direction="column" wrap="wrap">
                          {(levelThree || []).map((item: any, key: number) => {
                            return (
                              <Group>
                                <Text size="sm" weight={500} c="#44566C">
                                  {key + 1}.
                                </Text>
                                <Avatar src={!item?.user?.avatar || !item?.user ? noimage : item?.user?.avatar} />
                                <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap">
                                  <Text size="sm" weight={500} c="#44566C">
                                    {item?.user?.lastName || "-"}
                                  </Text>
                                  <Text size="sm" weight={500} c="#44566C">
                                    {item?.user?.firstName || "-"}
                                  </Text>
                                </Flex>
                              </Group>
                            );
                          })}
                        </Flex>
                      </Grid.Col>
                    )}
                  </>
                </>
              )}
            </Grid>
            <Divider color="gray" />
            <Flex direction="row" justify="space-between">
              <Button variant="default" onClick={() => setAction([])}>
                Болих
              </Button>
              <Flex gap="md">
                {action[0] !== "detail" && (
                  <Button type="submit" variant="outline">
                    Хадгалах
                  </Button>
                )}
              </Flex>
            </Flex>
          </Flex>
        );
      }}
    </Form>
  );
}
