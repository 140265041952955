import React from "react";
import { Badge, Divider, Grid, Group, Input, Text, createStyles } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { tugrug } from "../../utils";
import { IconCalendar } from "@tabler/icons";
import { ColumnType, Table } from "../table";
import { TransactionApi } from "../../apis";
import { useParams } from "react-router";
import { dateTimeFormat } from "../../utils/date";

export function AccountStatementList({ data, url }: { data: any; url: string }) {
  const params = useParams();
  const { theme } = useStyle();
  const nowMoment = new Date();
  var pastDate = nowMoment;
  var weekend = new Date(new Date().setDate(pastDate.getDate() - 7));
  var minDay = new Date(new Date().setDate(nowMoment.getDate() - 92));
  const [valueStart, setValueStart] = React.useState<Date>(weekend);
  const [valueEnd, setValueEnd] = React.useState<Date>(nowMoment);
  const [filters, setFilter] = React.useState<any>({
    accountId: `${params.id}`,
    inOutType: "ALL",
    startDate: weekend,
    endDate: nowMoment,
  });

  const columns = useHeader();

  return (
    <>
      {url === "TRUE" && (
        <Grid>
          <Grid.Col xs={12} sm={6} lg={4}>
            <Input.Wrapper label="Сонгосон дансны дугаар:">
              <Input readOnly value={`${data?.number || "-"}, ${data?.name || "-"}`} placeholder="Данс, дугаар" />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} lg={4}>
            <DatePicker value={nowMoment} onChange={() => {}} disabled label="Эхлэх огноо:" placeholder="сонгох" icon={<IconCalendar size={16} />} />
          </Grid.Col>
          <Grid.Col xs={12} sm={6} lg={4}>
            <DatePicker value={nowMoment} onChange={() => {}} disabled label="Дуусах огноо:" placeholder="сонгох" icon={<IconCalendar size={16} />} />
          </Grid.Col>

          <Grid.Col xs={12} sm={6} lg={4}>
            <Input.Wrapper label="Банкны нэр:">
              <Text fw={500} color={theme.primaryColor}>
                {data?.bankName || "-"}
              </Text>
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} lg={4}>
            <Input.Wrapper label="Эцсийн үлдэгдэл:">
              <Text fw={500} color={theme.primaryColor}>
                {tugrug(parseInt(data?.balance || 0))}
              </Text>
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} lg={4}></Grid.Col>
        </Grid>
      )}
      {url === "FALSE" && (
        <Grid>
          <Grid.Col xs={12} sm={6} lg={4}>
            <Input.Wrapper label="Сонгосон дансны дугаар:">
              <Input readOnly value={`${data?.number || "-"}, ${data?.name || "-"}`} placeholder="Данс, дугаар" />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} lg={4}>
            <DatePicker
              maxDate={valueEnd !== null && undefined ? weekend : valueEnd}
              value={filters.startDate}
              minDate={minDay}
              onChange={(e: any) => {
                setFilter({ ...filters, startDate: e });
                setValueStart(e);
              }}
              label="Эхлэх огноо:"
              placeholder="сонгох"
              icon={<IconCalendar size={16} />}
            />
          </Grid.Col>
          <Grid.Col xs={12} sm={6} lg={4}>
            <DatePicker
              minDate={valueStart}
              maxDate={nowMoment}
              value={filters.endDate}
              onChange={(e: any) => {
                setFilter({ ...filters, endDate: e });
                setValueEnd(e);
              }}
              label="Дуусах огноо:"
              placeholder="сонгох"
              icon={<IconCalendar size={16} />}
            />
          </Grid.Col>

          <Grid.Col xs={12} sm={6} lg={4}>
            <Input.Wrapper label="Банкны нэр:">
              <Text fw={500} color={theme.primaryColor}>
                {data?.bankName || "-"}
              </Text>
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} lg={4}>
            <Input.Wrapper label="Эцсийн үлдэгдэл:">
              <Text fw={500} color={theme.primaryColor}>
                {tugrug(parseInt(data?.balance || 0))}
              </Text>
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} lg={4}></Grid.Col>

          <Grid.Col xs={12} sm={6} lg={12}>
            <Divider />
          </Grid.Col>

          <Grid.Col xs={12} sm={6} lg={12}>
            <Table name="account.statement.list" filters={filters} columns={columns} loadData={(data) => TransactionApi.statList(data!)} pagination={false} />
          </Grid.Col>
        </Grid>
      )}
    </>
  );
}

const useHeader = (): ColumnType<any>[] => [
  {
    title: "Огноо, цаг",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 150 }}>
          {dateTimeFormat(record?.tranDate)}
        </Text>
      );
    },
  },
  {
    title: "Лавлах код",
    sorter: true,
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.tranId || "-"}
        </Text>
      );
    },
  },
  {
    title: "Орлогын гүйлгээ",
    sorter: true,
    render: (record) => {
      return (
        <Group position="right" mr={10}>
          {record?.drOrCr === "Credit" ? (
            <Text size="sm" weight={500} c="#40C057">
              {tugrug(record?.tranAmount || 0)}
            </Text>
          ) : (
            <Text size="sm" weight={500} c="#44566C">
              <Group position="right">{"-"}</Group>
            </Text>
          )}
        </Group>
      );
    },
  },
  {
    title: "Зарлагын гүйлгээ",
    sorter: true,
    render: (record) => {
      return (
        <Group position="right" mr={10}>
          {record?.drOrCr === "Debit" ? (
            <Text size="sm" weight={500} c="#FA5252">
              {tugrug(record?.tranAmount || 0)}
            </Text>
          ) : (
            <Text size="sm" weight={500} c="#44566C">
              <Group position="right">{"-"}</Group>
            </Text>
          )}
        </Group>
      );
    },
  },
  {
    title: "Эцсийн үлдэгдэл",
    sorter: true,
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          <Group position="right" mr={10}>
            {tugrug(record?.balance || 0)}
          </Group>
        </Text>
      );
    },
  },

  {
    title: "Гүйлгээний төрөл",
    sorter: true,
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline" color={record?.drOrCr === "Debit" ? "red" : "green"}>
          {record?.drOrCr === "Debit" ? "Зарлага" : "Орлого"}
        </Badge>
      );
    },
  },
  {
    title: "Гүйлгээний утга",
    sorter: true,
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.tranDesc || "-"}
        </Text>
      );
    },
  },
  {
    title: "Харьцсан дансны дугаар",
    sorter: true,
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.accNum || "-"}
        </Text>
      );
    },
  },
  {
    title: "Харьцсан дансны нэр",
    sorter: true,
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.accName || "-"}
        </Text>
      );
    },
  },
];

const useStyle = createStyles((theme) => ({}));
