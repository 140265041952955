import { Button, CloseButton, Divider, Flex, Grid, Input, LoadingOverlay, Text } from "@mantine/core";
import React from "react";
import useSwr from "swr";
import * as yup from "yup";
import { LbfLimit } from "../../apis";
import { tugrug } from "../../utils";
import { dateFormat } from "../../utils/date";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { FormLayout } from "../layout";

const schema = yup.object({});

type IFormData = {
  name: string;
};

export function BuyerLedForm({ action, onCancel, title, subTitle }: { action: any; onCancel: () => void; title: string; subTitle: string }) {
  const { data: limitData, isLoading } = useSwr(
    `/api/lbf/line_limit/check_limit/clientRegNumber=${action && action[1]}`,
    async () => {
      if (action && !action[1]) {
        return [];
      }
      try {
        let res = await LbfLimit.check({ clientRegNumber: action && action[1] });
        return res;
      } catch (err) {
        Message.error((err as HttpHandler)?.message!);
      }
      onCancel();
    },
    {},
  );

  const [data] = React.useState<IFormData>({
    name: "",
  });

  return (
    <Form validationSchema={schema} onSubmit={() => {}} initialValues={data}>
      {() => {
        return (
          <FormLayout title={title} subTitle={subTitle} my={0} extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
            <LoadingOverlay visible={isLoading} />
            <Divider mb={10} />
            <Grid>
              <Grid.Col span={4}>
                <Input.Wrapper label="Харилцагч байгууллагын нэр: ">
                  <Text size="sm" weight={500} c="#3e166e">
                    {(limitData && limitData[0]?.limitDesc) || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}></Grid.Col>
              <Grid.Col span={4}></Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Баталсан лимит:  ">
                  <Text size="sm" weight={500} c="#3e166e">
                    {tugrug(limitData && limitData[0]?.origSanctLim) || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Ашигласан лимит:  ">
                  <Text size="sm" weight={500} c="#3e166e">
                    {tugrug(limitData && limitData[0]?.liab) || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Бусад ашиглалт:  ">
                  <Text size="sm" weight={500} c="#3e166e">
                    {"-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Үүсгэсэн огноо:  ">
                  <Text size="sm" weight={500} c="#3e166e">
                    {dateFormat(limitData && limitData[0]?.limSanctDate)}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Дуусах огноо:  ">
                  <Text size="sm" weight={500} c="#3e166e">
                    {dateFormat(limitData && limitData[0]?.limExpDate)}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Лимит дугаар:  ">
                  <Text size="sm" weight={500} c="#3e166e">
                    {(limitData && limitData[0]?.limPrefix) || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
            </Grid>
            <Divider mt={10} mb={10} />
            <Flex direction="row" justify="space-between">
              <Button onClick={() => onCancel()} variant="default">
                Болих
              </Button>
            </Flex>
          </FormLayout>
        );
      }}
    </Form>
  );
}
