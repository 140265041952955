import { Alert, Badge, Box, Button, Divider, Flex, Grid, Input, Modal, Paper, Text } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import useSwr from "swr";
import * as yup from "yup";
import { LbfProductApi, SectorApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IBusinessSectors, IReference } from "../../models/General";
import { dateSecFormat } from "../../utils/date";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { DatePickerField } from "../form/date-picker-field";
import { NumberCurrencyField } from "../form/number-currency-field";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { TextareaField } from "../form/textarea-field";
import { FundingProgramBuyerLedAncorForm } from "../funding-program-buyer/ancor-form";

const schema = yup.object({
  name: yup.string().required("Заавал бөглөнө!").max(255, "Хэт урт байна!").nullable(),
  resolutionNo: yup.string().max(45, "Хэтэрхий урт байна.").required("Заавал бөглөнө!").nullable(),
  resolutionDate: yup.string().required("Заавал бөглөнө!").nullable(),
  sector1Id: yup.string().required("Заавал бөглөнө!").nullable(),
  sector2Id: yup.string().required("Заавал бөглөнө!").nullable(),
  sector3Id: yup.string().required("Заавал бөглөнө!").nullable(),
  lbfProgramLimit: yup
    .string()
    .when("businessId", (businessId, field) =>
      businessId
        ? yup
            .number()
            .max(9999999999999, "13-с бага оронтой дүн оруулна уу!")
            .min(0, "0-с их дүн оруулна уу!")
            .typeError("Зөвхөн тоо оруулна уу!")
            .required("Заавал бөглөнө!")
            .nullable()
        : field,
    ),
});

export type LbfProgramData = {
  type: "";
  lbfProductId: string;
  name: string;
  resolutionNo: string;
  resolutionDate: string | any;
  description: string;
  sector1Id: string;
  sector2Id: string;
  sector3Id: string;
  businessId: string;
  lbfProgramLimit: string;
  complete: boolean;

  programStatus?: string;
  registrationStatus?: string;
};

export function FundingProgramSupplierLedInformationForm({
  action,
  onSubmit: _onSubmit,
}: {
  action: any;
  onSubmit: (values: LbfProgramData, type: boolean) => void;
}) {
  const params = useParams();
  const navigate = useNavigate();
  const nowMoment = new Date();
  const [type, setType] = React.useState<boolean>(false);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { programRegistrationStatus, programStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [anchorAdd, setAnchorAdd] = React.useState<string[]>([]);
  const [firstSector, setFirstSector] = React.useState<string>(action[0] === "update" ? action[1]?.sector1Id : "");
  const [lastSector, setLastSector] = React.useState<string>(action[0] === "update" ? action[1]?.sector2Id : "");
  const [data] = React.useState<LbfProgramData>({
    type: "",
    lbfProductId: "",
    name: "",
    resolutionNo: "",
    resolutionDate: "",
    description: "",

    sector1Id: [],
    sector2Id: [],
    sector3Id: [],
    businessId: "",
    lbfProgramLimit: "",
    programStatus: "INACTIVE",
    registrationStatus: "DRAFT",
    complete: false,

    ...(action && action[0] === "update"
      ? {
          ...action[1],

          lbfProgramLimit: parseInt(action && action[1]?.lbfProgramLimit !== 0 ? action[1]?.lbfProgramLimit : ""),
          businessId: action && action[1]?.anchor?.businessId,
          sector1Id: action && action[1]?.sector1Id,
          sector2Id: action && action[1]?.sector2Id,
          sector3Id: action && action[1]?.sector3Id,
          operation: action && action[1]?.programStatus,
        }
      : {}),
  });

  const { data: productData } = useSwr(`/api/lbf/product/select/${params?.refCode}`, async () => {
    if ((user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_PROGRAM_MANAGEMENT_SL" && c.isCreate).length > 0) {
      try {
        let res = await LbfProductApi.select({ query: `${params?.refCode}`, type: "SUPPLIER_LED", category: "", limit: "" });
        return res;
      } catch (err) {
        Message.error((err as HttpHandler)?.message!);
      }
    }
    return [];
  });

  const { data: mainCategoryData } = useSwr(`/program/reference/economical_sector/select/main-form/SUPPLIER_LED`, async () => {
    if ((user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_REFERENCE").length > 0) {
      try {
        let res = await SectorApi.select({ parentId: "", level: "1", query: "", limit: "" });
        return res;
      } catch (err) {
        Message.error((err as HttpHandler)?.message!);
      }
    }
    return [];
  });

  const { data: sectorData } = useSwr(`/funding_program/reference/economical_sector/select/SUPPLIER_LED/${firstSector}`, async () => {
    if ((user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_REFERENCE").length > 0) {
      try {
        if (!firstSector) {
          return [];
        }
        let res = await SectorApi.select({ parentId: firstSector, level: "2", query: "", limit: "" });
        return res;
      } catch (err) {
        Message.error((err as HttpHandler)?.message!);
      }
    }
    return [];
  });

  const { data: subSectorData } = useSwr(`/funding_program/reference/economical_sector/select/SUPPLIER_LED/${lastSector}`, async () => {
    if ((user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_REFERENCE").length > 0) {
      try {
        if (!lastSector) {
          return [];
        }
        let res = await SectorApi.select({ parentId: lastSector, level: "3", query: "", limit: "" });
        return res;
      } catch (err) {
        Message.error((err as HttpHandler)?.message!);
      }
    }
    return [];
  });

  const onSubmit = (values: LbfProgramData) => {
    _onSubmit(values, type);
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ setFieldValue, values, errors }) => {
        return (
          <>
            <Grid>
              <Grid.Col span={12} mt={10}>
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="СИСТЕМИЙН МЭДЭЭЛЭЛ" />
              </Grid.Col>

              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Хөтөлбөрийн код:">
                  <Text fw={500} color="indigo" fz={"sm"}>
                    {values?.refCode || "Системээс үүсгэнэ"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Төрөл:">
                  <Flex align="center" gap={8}>
                    <Text fw={500} color="indigo" fz={"sm"}>
                      Supplier-LED,
                    </Text>
                    <Text fz="sm" fw={500} color="dimmed" component="div">
                      Зээл суурьтай
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_PROGRAM_MANAGEMENT_SL" && c.isCreate).length > 0 && (
                <Grid.Col md={6} lg={4}>
                  <Input.Wrapper label="Санхүүжилтийн бүтээгдэхүүн:">
                    <Flex align="center" gap={8}>
                      <Text fw={500} color="indigo" fz={"sm"}>
                        {(productData && productData[0]?.name) || "-"}, #{(productData && productData[0]?.refCode) || "-"}
                      </Text>
                      <Text fz="sm" fw={500} color="dimmed" component="div">
                        ({"SL"})
                      </Text>
                    </Flex>
                  </Input.Wrapper>
                </Grid.Col>
              )}

              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Бүртгэсэн ажилтан:">
                  <Flex align="center" gap={8}>
                    {action && action[0] === "create" ? (
                      <Text fw={500} color="indigo" fz={"sm"}>
                        {user?.lastName !== null ? <>{user?.lastName[0]}.</> : ""} {user?.firstName || "-"}
                      </Text>
                    ) : (
                      <Text fw={500} color="indigo" fz={"sm"}>
                        {values?.regUser?.lastName !== null ? <>{values?.regUser?.lastName[0]}.</> : ""} {values?.regUser?.firstName || "-"}
                      </Text>
                    )}

                    {action && action[0] === "create" ? (
                      <Text fz="sm" fw={500} color="dimmed" component="div">
                        {dateSecFormat(nowMoment)}
                      </Text>
                    ) : (
                      <Text fz="sm" fw={500} color="dimmed" component="div">
                        {dateSecFormat(values?.createdAt)}
                      </Text>
                    )}
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Бүртгэлийн статус:">
                  <Box style={{ alignItems: "center" }}>
                    <Badge
                      size="md"
                      radius="sm"
                      variant="outline"
                      color={(programRegistrationStatus || []).find((item: IReference) => item.code === values?.registrationStatus)?.color}>
                      {(programRegistrationStatus || []).find((item: IReference) => item.code === values?.registrationStatus)?.name}
                    </Badge>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Хөтөлбөрийн статус:">
                  <Box style={{ alignItems: "center" }}>
                    <Badge
                      size="md"
                      radius="sm"
                      variant="outline"
                      color={(programStatus || []).find((item: IReference) => item.code === values.programStatus)?.color}>
                      {(programStatus || []).find((item: IReference) => item.code === values.programStatus)?.name}
                    </Badge>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ХӨТӨЛБӨРИЙН МЭДЭЭЛЭЛ" />
              </Grid.Col>

              <Grid.Col md={6} lg={4}>
                <TextField name="name" label="Хөтөлбөрийн нэр:" placeholder="Нэр оруулах" />
              </Grid.Col>
              <Grid.Col md={6} lg={4}>
                <TextField name="resolutionNo" label="Хорооны шийдвэрийн дугаар:" placeholder="Тушаалын дугаарыг оруулна уу" />
              </Grid.Col>
              <Grid.Col md={6} lg={4}>
                <DatePickerField name="resolutionDate" label="Шийдвэрийн огноо:" placeholder="Огноо сонгох" required />
              </Grid.Col>

              <Grid.Col span={12}>
                <TextareaField name="description" placeholder="Тайлбар оруулах" label="Тайлбар оруулах:" maxLength={1000} withAsterisk={false} />
              </Grid.Col>

              {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_REFERENCE").length > 0 && (
                <Grid.Col md={6} lg={4}>
                  <SelectField
                    label="Үндсэн ангилал: "
                    name="sector1Id"
                    placeholder="сонгох"
                    options={(mainCategoryData || []).map((item: IBusinessSectors) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                    onChange={(e) => {
                      setFirstSector(e as string);
                      setFieldValue("sector2Id", null);
                      setFieldValue("sector3Id", null);
                    }}
                  />
                </Grid.Col>
              )}
              {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_REFERENCE").length > 0 && (
                <Grid.Col md={6} lg={4}>
                  <SelectField
                    label="Сектор: "
                    name="sector2Id"
                    placeholder="сонгох"
                    options={(sectorData || []).map((item: IBusinessSectors) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                    disabled={!values?.sector1Id}
                    withAsterisk={values?.sector1Id ? true : false}
                    onChange={(e) => {
                      setLastSector(e as string);
                      setFieldValue("sector3Id", null);
                    }}
                  />
                </Grid.Col>
              )}
              {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_REFERENCE").length > 0 && (
                <Grid.Col md={6} lg={4}>
                  <SelectField
                    label="Дэд сектор: "
                    name="sector3Id"
                    placeholder="сонгох"
                    options={(subSectorData || []).map((item: IBusinessSectors) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                    disabled={!values?.sector2Id}
                    withAsterisk={values?.sector2Id ? true : false}
                  />
                </Grid.Col>
              )}

              <Grid.Col span={12}>
                <Divider h={"lg"} />
                <Flex align="center" justify="space-between" pb={12}>
                  <Text color="dimmed" tt="uppercase" fw={500}>
                    АНКОРЫН МЭДЭЭЛЭЛ
                  </Text>

                  <>
                    {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_PROGRAM_MANAGEMENT_SL" && c.isCreate).length > 0 &&
                    values.hasAnchor ? (
                      <Button
                        onClick={() => {
                          setFieldValue("businessId", undefined);
                          setFieldValue("anchor", undefined);
                          setFieldValue("bankAccounts", undefined);
                          setFieldValue("checkedAnchor", undefined);
                          setFieldValue("hasAnchor", false);
                          setFieldValue("lbfProgramLimit", "");
                        }}
                        variant="outline">
                        анкор хасах
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          setAnchorAdd(["anchor_add", values as any]);
                        }}
                        variant="outline">
                        Анкор нэмэх
                      </Button>
                    )}
                  </>
                </Flex>
                {!values.hasAnchor && (
                  <Paper>
                    <Alert
                      hidden={showAlert}
                      withCloseButton={true}
                      onClose={() => {
                        setShowAlert(true);
                      }}
                      styles={{ message: { color: "#44566c", fontWeight: 500 } }}
                      icon={<IconAlertCircle size={16} />}
                      title="Таны хянах мэдээлэл!"
                      color="indigo">
                      Анкорын мэдээллийг оруулна уу.
                    </Alert>
                  </Paper>
                )}
              </Grid.Col>
              {values.hasAnchor && (
                <>
                  <Grid.Col md={6} lg={4}>
                    <Input.Wrapper label="Анкор партнерийн нэр:">
                      <Text color="indigo">
                        {values?.anchor?.partnerName || values?.anchor?.partner?.businessName || "-"}, #{values?.anchor?.refCode || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col md={6} lg={4}>
                    <Input.Wrapper label="Анкор бизнесийн нэр:">
                      <Text color="indigo">
                        {values?.anchor?.profileName || "-"}, #{values?.anchor?.refCode || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col md={6} lg={4}>
                    <Input.Wrapper label="Хөтөлбөр дэх анкорын роль:">
                      <Text color="indigo">{values?.anchor?.refCode?.match("BU") ? "Худалдан авагч" : "Нийлүүлэгч"}</Text>
                    </Input.Wrapper>
                  </Grid.Col>

                  <Grid.Col md={6} lg={4}>
                    <Input.Wrapper label="Харилцагч CIF">
                      <Text color="indigo">{values?.anchor?.clientCode || "-"}</Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col md={6} lg={4}>
                    <Input.Wrapper label="Нэхэмжлэх батлах санхүү">
                      <Text color="indigo">
                        {values?.anchor?.finUser?.lastName || "-"}, {values?.anchor?.finUser?.firstName || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col md={6} lg={4}>
                    <NumberCurrencyField
                      precision={2}
                      placeholder="0.00"
                      min={0}
                      max={999999999999}
                      name="lbfProgramLimit"
                      label="Хөтөлбөрийн лимит дүн:"
                      required
                    />
                  </Grid.Col>
                </>
              )}
            </Grid>
            <Divider h="lg" mt="lg" />
            <Flex direction="row" justify="space-between">
              <Button onClick={() => navigate("/cf/funding-program/SUPPLIER")} variant="default">
                Болих
              </Button>
              <Flex gap="md">
                <Button
                  type="submit"
                  variant="outline"
                  onClick={() => setType(false)}
                  disabled={values?.registrationStatus === "REGISTERED" || values?.registrationStatus === "REVIEWED"}>
                  Хадгалах
                </Button>
                <Button
                  type="submit"
                  onClick={() => setType(true)}
                  disabled={values?.registrationStatus === "REGISTERED" || values?.registrationStatus === "REVIEWED"}>
                  Бүртгэсэн
                </Button>
              </Flex>
            </Flex>
            <Modal
              opened={anchorAdd[0] === "anchor_add"}
              onClose={() => {
                setAnchorAdd([]);
                setFieldValue("businessId", undefined);
              }}
              withCloseButton={false}
              size="xxl"
              centered>
              <FundingProgramBuyerLedAncorForm setFieldValue={setFieldValue} action={anchorAdd} setAction={setAnchorAdd} values={values} type="SUPPLIER_LED" />
            </Modal>
          </>
        );
      }}
    </Form>
  );
}
