import { Avatar, Box, Button, Divider, Flex, Grid, Group, Input, Text, Tooltip } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { IconInfoSquare, IconSearch } from "@tabler/icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { AncorApi } from "../../apis";
import noimage from "../../assets/no-image.png";
import { IAncor } from "../../interfaces/IAncor";
import { IAuth } from "../../interfaces/IAuth";
import { IBusiness } from "../../interfaces/IBusiness";
import { IGeneral } from "../../interfaces/IGeneral";
import { IPermissions } from "../../interfaces/IPermissions";
import { IReference } from "../../models/General";
import { dateSecFormat } from "../../utils/date";
import { renderName } from "../../utils/render-name";
import { ColumnType, Table } from "../table";
import RenderText from "../ui/render-text";

export function SmeList() {
  const [query, setQuery] = useDebouncedState("", 600);
  const navigate = useNavigate();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { golomtLegalEntityTypes } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({
    user,
    golomtLegalEntityTypes,
    onClick: (key, record) => {
      switch (key) {
        case "detail": {
          navigate(`/customer/sme-customer/detail/${record.id}`);
          break;
        }
        default:
      }
    },
  });

  return (
    <Flex direction={"column"} gap="xs" sx={{ minHeight: "60vh", height: "100%" }}>
      <Grid>
        <Grid.Col span={3}>
          <Input
            iconWidth={120}
            onChange={(e) => setQuery(e.currentTarget.value)}
            placeholder="хайлт хийх"
            rightSection={<IconSearch size={18} style={{ display: "block", opacity: 0.5 }} />}
            icon={
              <Group w={"calc(100% - 20px)"} align="center" position="apart" noWrap>
                <Text size="sm" weight={450} c="#44566C">
                  {"Бизнес нэр"}
                </Text>
                <Text>:</Text>
              </Group>
            }
            key={1}
          />
        </Grid.Col>
      </Grid>
      <Divider />
      <Table
        name="sme.business.tabs.list"
        columns={columns}
        filters={{
          query: query,
          type: "SME",
        }}
        loadData={(data) => AncorApi.business(data!)}
      />
    </Flex>
  );
}

const useHeader = ({
  user,
  onClick,
  golomtLegalEntityTypes,
}: {
  user: any;
  onClick: (key: string, record: IAncor) => void;
  golomtLegalEntityTypes: IReference[];
}): ColumnType<IAncor>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => {
      return <RenderText text={index + 1} />;
    },
  },
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="xs">
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "CLIENT" && c.code === "CLIENT_SME").length > 0 ? (
            <Tooltip label="Дэлгэрэнгүй" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
                <IconInfoSquare />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="Дэлгэрэнгүй" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("", record)} disabled>
                <IconInfoSquare />
              </Button>
            </Tooltip>
          )}
        </Flex>
      );
    },
  },
  {
    title: "Лого",
    sorter: true,
    dataIndex: "logo",
    render: (record) => {
      return <Avatar src={record?.logo !== null ? record?.logo : noimage} alt="" color="indigo" />;
    },
  },
  {
    title: "ТТД",
    sorter: true,
    dataIndex: "regNumber",
    render: (record) => {
      return <RenderText text={record?.regNumber || "-"} />;
    },
  },
  {
    title: "Код",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return <RenderText text={record?.clientCode || "-"} />;
    },
  },
  {
    title: "Харилцагчийн нэр",
    sorter: true,
    dataIndex: "regNumber",
    render: (record) => {
      return <RenderText text={record?.clientName || "-"} />;
    },
  },
  {
    title: "deHUB код, нэр",
    sorter: true,
    dataIndex: "partner",
    render: (record) => {
      return (
        <Box>
          <RenderText text={record?.businessName || "-"} />
          <RenderText text={record?.refCode || "-"} color="#144e9d" />
        </Box>
      );
    },
  },
  {
    title: "Худалдан авагч бизнес",
    sorter: true,
    dataIndex: "partner",
    render: (record) => {
      return (
        <Box>
          <RenderText text={(record?.businesses || []).find((c: IBusiness) => c.type === "BUYER")?.profileName || "-"} />
          <RenderText text={(record?.businesses || []).find((c: IBusiness) => c.type === "BUYER")?.refCode || "-"} color="#144e9d" />
        </Box>
      );
    },
  },
  {
    title: "Нийлүүлэгч бизнес",
    sorter: true,
    dataIndex: "partner",
    render: (record) => {
      return (
        <Box>
          <RenderText text={(record?.businesses || []).find((c: IBusiness) => c.type === "SUPPLIER")?.profileName || "-"} />
          <RenderText text={(record?.businesses || []).find((c: IBusiness) => c.type === "SUPPLIER")?.refCode || "-"} color="#144e9d" />
        </Box>
      );
    },
  },
  {
    title: "ААН хэлбэр",
    sorter: true,
    dataIndex: "legalEntityType",
    render: (record) => {
      return <RenderText text={(golomtLegalEntityTypes || []).find((c: IReference) => c.code === record?.legalEntityType)?.name || "-"} />;
    },
  },
  {
    title: "Бүртгэсэн",
    sorter: true,
    dataIndex: "businessSubSector",
    render: (record) => {
      return <RenderText text={dateSecFormat(record.createdAt) || "-"} />;
    },
  },
  {
    title: "Зөвшөөрсөн",
    sorter: true,
    dataIndex: "partner",
    render: (record) => {
      return <RenderText text={renderName(record?.approvedUser)} />;
    },
  },
];
