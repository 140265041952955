import { Button, CloseButton, Divider, Flex, LoadingOverlay } from "@mantine/core";
import React from "react";
import * as yup from "yup";
import { BankProductApi } from "../../apis";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { TextField } from "../form/text-field";
import { FormLayout } from "../layout";

type IFormData = {
  name: string;
  code: string;
};

const schema = yup.object({
  name: yup.string().max(255, "Хэт урт байна!").required("Заавал бөглөнө!").nullable(),
  code: yup.string().max(45, "Хэт урт байна!").required("Заавал бөглөнө!").nullable(),
});

export function ReferenceForm({ onCancel, action, reload }: { onCancel?: () => void; action: any; reload: () => void }) {
  const [loading, setLoading] = React.useState(false);
  const [data] = React.useState<IFormData>({
    name: "",
    code: "",
    ...(action && action[0] === "update"
      ? {
          ...action[1],
          name: action[1].name,
          code: action[1].code,
        }
      : {}),
  });

  const onSubmit = async (value: IFormData) => {
    setLoading(true);
    try {
      if (action && action[0] === "update") {
        await BankProductApi.update(action[1].id, { name: value.name, code: value.code });
        Message.success("Амжилттай төрөл засварлан хадгалсан!");
      } else {
        await BankProductApi.create({ name: value.name, code: value.code });
        Message.success("Амжилттай төрөл нэмлээ!");
      }

      onCancel && onCancel();
      reload();
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {() => {
        return (
          <FormLayout
            title="Банк бүтээгдэхүүн"
            subTitle={action && action[0] !== "update" ? "Банк бүтээгдэхүүн нэмэх" : "Банк бүтээгдэхүүн засварлах"}
            my={0}
            extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
            <LoadingOverlay visible={loading} />
            <Divider mb="xs" />
            <Flex gap={15} direction="column">
              <TextField name="name" label="Нэр" placeholder="Нэр" />
              <TextField name="code" label="Код" placeholder="Код" />
            </Flex>
            <Divider mb="xs" mt="xs" />
            <Flex justify="space-between">
              <Button key={1} variant="default" onClick={() => onCancel && onCancel()}>
                Болих
              </Button>
              <Button key={2} type="submit">
                Хадгалах
              </Button>
            </Flex>
          </FormLayout>
        );
      }}
    </Form>
  );
}
