import { ActionIcon } from "@mantine/core";
import { IconCheck, IconMinus } from "@tabler/icons";
import React from "react";
import { ObservationApi } from "../../apis";
import { IActivityLog } from "../../interfaces/IActivityLog";
import { dateSecFormat } from "../../utils/date";
import { renderName } from "../../utils/render-name";
import { ColumnType, Table } from "../table";
import RenderText from "../ui/render-text";

type Props = {
  action: any;
};

export function PaymentObservationActionLogList({ action }: Props) {
  const [filters] = React.useState({ repaymentId: action && action[1]?.id });

  const columns = useHeader();

  return <Table name="payment-observation-action-log.list" columns={columns} filters={filters} loadData={(data) => ObservationApi.repayment(data!)} />;
}

const useHeader = (): ColumnType<IActivityLog>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    title: "",
    dataIndex: "logType",
    render: (record) => {
      return (
        <>
          {record.logType === "OVERDUE" ? (
            <ActionIcon color="red.5" size="md" radius="xl" variant="filled">
              <IconMinus size="1rem" />
            </ActionIcon>
          ) : (
            <ActionIcon color="green.5" size="md" radius="xl" variant="filled">
              <IconCheck size="1rem" />
            </ActionIcon>
          )}
        </>
      );
    },
  },
  {
    title: "Огноо, цаг",
    sorter: true,
    dataIndex: "createdAt",
    render: (record) => {
      return <RenderText text={dateSecFormat(record.createdAt)} />;
    },
  },
  {
    title: "Төрөл",
    sorter: true,
    dataIndex: "logType",
    render: (record) => {
      return <RenderText text={record.logType ?? "-"} />;
    },
  },
  {
    title: "Холбоотой хэрэглэгч",
    sorter: true,
    dataIndex: "actionUserId",
    render: (record) => {
      return <RenderText text={renderName(record?.actionUser)} />;
    },
  },
  {
    title: "Тайлбар",
    sorter: true,
    dataIndex: "description",
    render: (record) => {
      return <RenderText text={record.description || "-"} />;
    },
  },
];
