import { Button, Divider, Flex, Grid, Text } from "@mantine/core";
import React from "react";
import { useNavigate, useParams } from "react-router";
import * as yup from "yup";
import { AuthApi, ProgramApi } from "../../apis";
import { IScfProgram } from "../../interfaces/IScfProgram";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { OtpField } from "../form/otp-field";
import { TextareaField } from "../form/textarea-field";

const schema = yup.object({
  pin: yup.string().min(6, "Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  pin?: string;
  reviewDesc: string;
  review: boolean;
};

export function BuyerProgramReviewForm({ data, reload, onCancel, type }: { data: IScfProgram; reload: () => void; onCancel: () => void; type: string }) {
  const [loading, setLoading] = React.useState(false);
  const params = useParams();
  const navigate = useNavigate();

  const [initialData] = React.useState<IFormData>({
    pin: "",
    reviewDesc: "",
    review: false,
  });

  const review = async (values: IFormData) => {
    setLoading(true);

    try {
      await ProgramApi.review(`${params.id}`, {
        review: values.review,
        reviewDesc: values.reviewDesc,
      });

      if (values?.review) {
        Message.success("Таны хүсэлт амжилттай хөтөлбөрийг хянасан.");
      } else {
        Message.success("Таны хүсэлт амжилттай хөтөлбөрийг татгалзсан.");
      }
      reload();
      onCancel();

      if (type === "buyer_led") {
        navigate(`/scf-dcf/dcf-program`);
      } else {
        navigate(`/scf-dcf/scf-program`);
      }
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      let res = await AuthApi.checkPin({ pin: values.pin });
      if (res?.success) {
        review({
          review: values.review,
          reviewDesc: values.reviewDesc,
        });
      }
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <Form validationSchema={schema} onSubmit={() => {}} initialValues={initialData}>
      {({ values }) => {
        return (
          <Grid>
            <Grid.Col span={12}>
              <Divider />
            </Grid.Col>
            <Grid.Col span={12}>
              <Text ta="center">Та "{data.refCode}" дугаартай Supply Chain Finance хөтөлбөрийн үндсэн бүртгэл хянах гэж байна.</Text>
            </Grid.Col>
            <Grid.Col span={12}>
              <OtpField name="pin" label="Баталгаажуулах пин:" placeholder="******" />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextareaField name="reviewDesc" label="Тайлбар:" placeholder="Тайлбар энд бичих" withAsterisk={false} maxLength={255} />
            </Grid.Col>

            <Grid.Col span={12}>
              <Divider />
            </Grid.Col>

            <Grid.Col span={12}>
              <Flex direction="row" justify="space-between">
                <Button
                  color="red"
                  variant="light"
                  loading={loading}
                  onClick={() => {
                    if (values.pin) {
                      onSubmit({ ...values, review: false });
                    }
                  }}>
                  Татгалзах
                </Button>
                <Button
                  variant="light"
                  type="submit"
                  loading={loading}
                  key={2}
                  onClick={() => {
                    if (values.pin) {
                      onSubmit({ ...values, review: true });
                    }
                  }}>
                  Баталгаажуулах
                </Button>
              </Flex>
            </Grid.Col>
          </Grid>
        );
      }}
    </Form>
  );
}
