import { Badge, Box, Flex, Grid, Group, Input, Text } from "@mantine/core";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { tugrug } from "../../utils";
import { dateSecFormat } from "../../utils/date";
import { Form } from "../form";

const schema = yup.object({});

export function BuyerLedProgramReviewInformationForm({
  data,
  reload,
  basicSector,
  sector,
  subSector,
}: {
  data: any;
  reload: () => void;
  basicSector: any;
  sector: any;
  subSector: any;
}) {
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { productTypes, programTypes, programRegistrationStatus, programStatus } = useSelector((state: { general: IGeneral }) => state.general);

  return (
    <Form validationSchema={schema} onSubmit={() => {}} initialValues={data}>
      {() => {
        return (
          <>
            <Grid gutter={24} mt={5}>
              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Хөтөлбөрийн код:">
                  <Text fw={500} color="indigo" fz={"sm"}>
                    {data?.refCode || "Системээс үүсгэнэ"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Хөтөлбөрийн төрөл:">
                  <Text fw={500} color="indigo" fz={"sm"}>
                    {(programTypes || []).find((item) => item.code === data?.type)?.name || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Санхүүжилтийн бүтээгдэхүүн:">
                  <Flex align="center" gap={8}>
                    <Text fw={500} color="indigo" fz={"sm"}>
                      {data?.product?.name}, #{data?.product?.refCode}
                    </Text>
                    <Text fz="sm" fw={500} color="dimmed" component="div">
                      ({(productTypes || []).find((item) => item.code === data?.productType)?.name || "-"})
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Бүртгэсэн:">
                  <Text fw={500} color="indigo" fz={"sm"}>
                    <Flex gap={5} wrap="wrap">
                      <span>{data?.regUser?.lastName || user?.lastName || "-"}</span>
                      <span>{data?.regUser?.firstName || user?.firstName || "-"}</span>
                    </Flex>
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Бүртгэсэн огноо:">
                  <Text fw={500} color="indigo" fz={"sm"}>
                    <Flex gap={5} wrap="wrap">
                      <span>{data?.createdAt ? dateSecFormat(data?.createdAt) : "-"}</span>
                    </Flex>
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Бүртгэлийн статус:">
                  <Box style={{ alignItems: "center" }}>
                    <Badge
                      size="md"
                      radius="sm"
                      variant="outline"
                      color={(programRegistrationStatus || []).find((item: IReference) => item.code === data?.registrationStatus)?.color}>
                      {(programRegistrationStatus || []).find((item: IReference) => item.code === data?.registrationStatus)?.name}
                    </Badge>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Хөтөлбөрийн статус:">
                  <Box style={{ alignItems: "center" }}>
                    {
                      <Badge
                        size="md"
                        radius="sm"
                        variant="outline"
                        color={(programStatus || []).find((item: IReference) => item.code === data?.programStatus)?.color}>
                        {(programStatus || []).find((item: IReference) => item.code === data?.programStatus)?.name}
                      </Badge>
                    }
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Хөтөлбөрийн нэр:">
                  <Text fw={500} color="indigo" fz={"sm"}>
                    {data?.name || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Хорооны шийдвэрийн дугаар:">
                  <Text fw={500} color="indigo" fz={"sm"}>
                    {data?.resolutionNo || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col md={6} lg={4}>
                {(basicSector || []).length !== 0 && (
                  <Input.Wrapper label="Үндсэн сектор:">
                    <Group mt={"xs"}>
                      {(basicSector || []).map((item: any, index: number) => {
                        return (
                          <Badge key={index} radius="sm" variant="outline">
                            {item?.economicalSector?.name}
                          </Badge>
                        );
                      })}
                    </Group>
                  </Input.Wrapper>
                )}
              </Grid.Col>

              <Grid.Col md={6} lg={4}>
                {(sector || []).length !== 0 && (
                  <Input.Wrapper label="Сектор:">
                    <Group mt={"xs"}>
                      {(sector || []).map((item: any, index: number) => {
                        return (
                          <Badge key={index} radius="sm" variant="outline">
                            {item?.economicalSector?.name}
                          </Badge>
                        );
                      })}
                    </Group>
                  </Input.Wrapper>
                )}
              </Grid.Col>
              <Grid.Col md={6} lg={4}>
                {(subSector || []).length !== 0 && (
                  <Input.Wrapper label="Дэд сектор:">
                    <Group mt={"xs"}>
                      {(subSector || []).map((item: any, index: number) => {
                        return (
                          <Badge key={index} radius="sm" variant="outline">
                            {item?.economicalSector?.name}
                          </Badge>
                        );
                      })}
                    </Group>
                  </Input.Wrapper>
                )}
              </Grid.Col>

              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Санхүүжилт шилжүүлэх банк данс (From):">
                  <Text fw={500} color="indigo" fz={"sm"}>
                    {data?.disburseAcc?.shortName || "-"}, {data?.disburseAcc?.number || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Шимтгэлийн орлого төвлөрүүлэх данс (To):">
                  <Text fw={500} color="indigo" fz={"sm"}>
                    {data?.feeAcc?.shortName || "-"}, {data?.feeAcc?.number || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Эргэн төлөлтийг авах банк данс (To):">
                  <Text fw={500} color="indigo" fz={"sm"}>
                    {data?.repayAcc?.shortName || "-"}, {data?.repayAcc?.number || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Санхүүжилтийн лимит:">
                  <Text fw={500} color="indigo" fz={"sm"}>
                    {tugrug(data?.programLimit || "0")}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
            </Grid>
          </>
        );
      }}
    </Form>
  );
}
