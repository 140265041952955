import { Alert, Avatar, Badge, Box, Button, CloseButton, Divider, Flex, Grid, Input, Text } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons";
import React from "react";
import { useSelector } from "react-redux";
import useSwr from "swr";
import * as yup from "yup";
import { FundingIntroductionApi } from "../../apis";
import noimage from "../../assets/no-image.png";
import { IChildren } from "../../interfaces/IChildren";
import { IFundingIntroduction } from "../../interfaces/IFundingIntroduction";
import { IGeneral } from "../../interfaces/IGeneral";
import { IRepayment } from "../../interfaces/IRepayment";
import { tugrug } from "../../utils";
import { dateSecFormat } from "../../utils/date";
import { Form } from "../form";
import { FormLayout } from "../layout";
import { ColumnType, Table } from "../table";
import RenderText from "../ui/render-text";

const schema = yup.object({});

type Props = {
  action: any;
  setAction: any;
  reload: any;
};

type IFormData = {
  approve: boolean;
};

export function FundingIntroductionForm({ action, setAction, reload }: Props) {
  const { currencies } = useSelector((state: { general: IGeneral }) => state.general);
  const [datas] = React.useState<IFormData>({
    approve: false,
  });

  const { data: fundingData } = useSwr(`/api/scf/funding_instruction/`, async () => {
    if (!action[1].id) {
      return [];
    }

    let res = await FundingIntroductionApi.get(action[1].id);
    return res;
  });

  const fundingInstructionTypes = (val: string) => {
    switch (val) {
      case "DISBURSEMENT": {
        return (
          <Badge size="md" radius="sm" variant="outline" color="blue">
            {"Олголтын гүйлгээ"}
          </Badge>
        );
      }
      case "BANK_FEE": {
        return (
          <Badge size="md" radius="sm" variant="outline" color="blue">
            {"Банк шимтгэл"}
          </Badge>
        );
      }
      case "FEE": {
        return (
          <Badge size="md" radius="sm" variant="outline" color="blue">
            {"SCF шимтгэл"}
          </Badge>
        );
      }

      default: {
        return (
          <Badge size="md" radius="sm" variant="outline">
            {"Хоосон"}
          </Badge>
        );
      }
    }
  };

  const columns = useHeader({
    currencies,
    fundingInstructionTypes,
    onClick: (key, record) => {},
  });

  const columns2 = useHeader2({
    currencies,
    onClick: (key, record) => {},
  });

  const onSubmit = async (values: any, send: boolean) => {
    if (send) {
      setAction(["pin", { approve: true }, action[1].id, action[1]]);
    } else {
      setAction(["pin", { approve: false }, action[1].id, action[1]]);
    }
  };

  return (
    <Form validationSchema={schema} onSubmit={() => {}} initialValues={datas}>
      {({ values }) => {
        return (
          <FormLayout title="Олголтын гүйлгээ хийх зөвшөөрөл" subTitle="" my={0} extra={[<CloseButton key="cancel" onClick={() => setAction([])} />]}>
            <Flex sx={{ position: "relative" }} direction="column">
              <Grid>
                <Grid.Col span={12}>
                  <Divider color="gray" />
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Санхүүжилтийн хүсэлт:">
                    <Box color="indigo" mt={4}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {fundingData?.scfRequestRef || "-"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Санхүүжилт авах партнер:">
                    <Box color="indigo" mt={4}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {fundingData?.requestedBusiness?.partner?.refCode || "-"}, {fundingData?.requestedBusiness?.partner?.businessName || "-"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Санхүүжилт авах бизнес:">
                    <Box color="indigo" mt={4}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {fundingData?.requestedBusiness?.refCode || "-"}, {fundingData?.requestedBusiness?.profileName || "-"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Баталсан ажилтан:">
                    <Box color="indigo" mt={4}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {fundingData?.respondedUser?.lastName || "-"}, {fundingData?.respondedUser?.firstName || "-"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Баталсан огноо:">
                    <Box color="indigo" mt={4}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {dateSecFormat(fundingData?.respondedDate)}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Шийдвэрийн дугаар:">
                    <Box color="indigo" mt={4}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {fundingData?.resolutionNo || "-"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Олголт хийх дансны нэр:">
                    <Box color="indigo" mt={4}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {fundingData?.account?.name || "-"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Олголтын данснаас хасагдах дүн:">
                    <Box color="indigo" mt={4}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {tugrug(fundingData?.trxAmount)}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Үлдэгдэл хүрэлцэх байдал:">
                    <Box color="indigo">
                      {fundingData?.isSufficient ? (
                        <Text color="green" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                          {"Үлдэгдэл хүрэлцэнэ"}
                        </Text>
                      ) : (
                        <Text color="red" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                          {"Үлдэгдэл хүрэлцэхгүй"}
                        </Text>
                      )}
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col span={12}>
                  <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} my="xs" label="САНХҮҮЖИЛТ ОЛГОЛТЫН МЭДЭЭЛЭЛ" />
                  <Table name="children.table" columns={columns} filters={{}} dataSource={fundingData?.children} pagination={false} />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Divider color="gray" />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} my="xs" label="ЭРГЭН ТӨЛӨЛТИЙН НЭХЭМЖЛЭХ" />
                  {fundingData?.fundingInstructionStatus === "DISBURSED" ? (
                    <Table name="children.tables" columns={columns2} filters={{}} dataSource={[fundingData?.repayment]} pagination={false} />
                  ) : (
                    <Alert
                      icon={<IconInfoCircle size="1rem" />}
                      title="Санамж!"
                      color="yellow"
                      styles={{ message: { color: "#44566c", fontWeight: 500 } }}
                      withCloseButton={false}>
                      <Text size="sm" weight={500} c="#44566C">
                        Олголт хийгдсэний дараа эргэн төлөлтийн нэхэмжлэх үүснэ.
                      </Text>
                    </Alert>
                  )}
                </Grid.Col>
                <Grid.Col span={12}>
                  <Divider color="gray" />
                </Grid.Col>
              </Grid>
              <Flex direction="row" justify="space-between" mt={10}>
                <Button variant="default" onClick={() => setAction([])}>
                  Буцах
                </Button>
                <Flex gap="md">
                  <Button
                    variant="outline"
                    type="submit"
                    color="red"
                    disabled={fundingData?.fundingInstructionStatus === "DISBURSED"}
                    onClick={() => {
                      onSubmit(values, false);
                    }}>
                    Цуцлах
                  </Button>
                  <Button
                    variant="outline"
                    type="submit"
                    disabled={fundingData?.fundingInstructionStatus === "DISBURSED"}
                    onClick={() => {
                      onSubmit(values, true);
                    }}>
                    Олгох
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </FormLayout>
        );
      }}
    </Form>
  );
}

const useHeader = ({
  onClick,
  currencies,
  fundingInstructionTypes,
}: {
  onClick: (key: string, record: IFundingIntroduction) => void;
  fundingInstructionTypes: (key: string) => JSX.Element;
  currencies: any;
}): ColumnType<IChildren>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    title: "Хүсэлт",
    render: (record) => {
      return <RenderText text={record?.scfRequestRef || "-"} />;
    },
  },
  {
    title: "Төрөл",
    render: (record) => {
      return <div>{fundingInstructionTypes(record?.trxType)}</div>;
    },
  },
  {
    title: "Лавлах",
    render: (record) => {
      return <RenderText text={record?.disbursementRef} />;
    },
  },
  {
    title: "Гүйлгээний дүн",
    render: (record) => {
      return <RenderText text={tugrug(record?.trxAmount || 0)} isAmount />;
    },
  },
  {
    title: "Гүйлгээний утга",
    render: (record) => {
      return <RenderText text={record?.description || "-"} />;
    },
  },
  {
    title: "Валют",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline">
          {currencies.find((c: any) => c.code === record?.trxCurrency)?.name ?? "-"}
        </Badge>
      );
    },
  },
  {
    title: "Хүлээн авах данс",
    render: (record) => {
      return <RenderText text={record?.creditorAccNum || "-"} />;
    },
  },
  {
    title: "Хүлээн авах дансны нэр",
    render: (record) => {
      return <RenderText text={record?.creditorAccName || "-"} />;
    },
  },
  {
    title: "Банк",
    render: (record) => {
      return <RenderText text={record?.creditorBankName || "-"} />;
    },
  },
  {
    title: "Шилжүүлэх данс",
    render: (record) => {
      return <RenderText text={record?.debtorAccNum || "-"} />;
    },
  },
  {
    title: "Шилжүүлэх данс нэр",
    render: (record) => {
      return <RenderText text={record?.debtorAccName || "-"} />;
    },
  },
  {
    title: "Банк",
    render: (record) => {
      return <RenderText text={record?.debtorBankName || "-"} />;
    },
  },
  {
    title: "Нэхэмжлэх",
    render: (record) => {
      return <RenderText text={record?.invRef || "-"} />;
    },
  },
];

const useHeader2 = ({ onClick, currencies }: { onClick: (key: string, record: IRepayment) => void; currencies: any }): ColumnType<IRepayment>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    title: "Хүсэлт",
    render: (record) => {
      return <RenderText text={record?.scfRequestRefCode || "-"} />;
    },
  },
  {
    title: "Нэхэмжлэх",
    render: (record) => {
      return <RenderText text={record?.invRefCode || "-"} />;
    },
  },
  {
    title: "Эргэн төлөх нэхэмжлэх",
    render: (record) => {
      return <RenderText text={record?.refCode || "-"} />;
    },
  },
  {
    title: "Эргэн төлөх дүн",
    render: (record) => {
      return <RenderText text={tugrug(record?.repaymentAmount)} isAmount />;
    },
  },
  {
    title: "Эргэн төлөлт хариуцагч",
    render: (record) => {
      return (
        <Flex align="center" gap="xs">
          <Avatar src={record?.payerBusiness?.logo !== null ? record?.payerBusiness?.logo : noimage} />
          <Box>
            <RenderText text={record?.payerBusiness?.refCode} />
            <RenderText text={record?.payerBusiness?.profileName} color="#141517" />
          </Box>
        </Flex>
      );
    },
  },
  {
    title: "Нийлүүлэгч бизнес",
    render: (record) => {
      return (
        <Box>
          <RenderText text={record?.payerBusiness?.partner?.refCode} />
          <RenderText text={record?.payerBusiness?.partner?.businessName} color="#141517" />
        </Box>
      );
    },
  },
  {
    title: "Эргэн төлөлт хүлээн авах данс",
    render: (record) => {
      return <RenderText text={record?.receiverAcc?.number || "-"} />;
    },
  },
  {
    title: "Дансны нэр",
    render: (record) => {
      return <RenderText text={record?.receiverAcc?.name || "-"} />;
    },
  },
  {
    title: "Банк",
    render: (record) => {
      return <RenderText text={record?.receiverAcc?.bankName || "-"} />;
    },
  },
  {
    title: "Валют",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline">
          {currencies.find((f: any) => f.code === record?.receiverAcc?.currency)?.name ?? "-"}
        </Badge>
      );
    },
  },
  {
    title: "Олголт лавлах",
    render: (record) => {
      return <RenderText text={tugrug(record?.amountToPay ?? 0)} isAmount />;
    },
  },
];
