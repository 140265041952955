import { Alert, Badge, Box, Button, Divider, Flex, Grid, Input, Text } from "@mantine/core";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import useSwr from "swr";
import * as yup from "yup";
import { BankProductApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { IBankProductType } from "../../interfaces/IBankProductType";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { percent } from "../../utils";
import { dateSecFormat } from "../../utils/date";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { NumberCurrencyField } from "../form/number-currency-field";
import { NumberPercentField } from "../form/number-percent-field";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { TextareaField } from "../form/textarea-field";
import { LbfProductInformationData } from "../product-buyer/information-form";

const schema = yup.object({
  name: yup.string().required("Заавал бөглөнө!").max(255, "Хэт урт байна!").nullable(),
  resolutionNo: yup.string().max(45, "Хэт урт байна!").required("Заавал бөглөнө!").nullable(),
  bankProductTypeId: yup.string().required("Заавал бөглөнө!").nullable(),
  minInvAmount: yup.string().typeError("Тоо оруулна уу!").required("Заавал бөглөнө!").nullable(),
  financeMinLimit: yup.string().typeError("Тоо оруулна уу!").required("Заавал бөглөнө!").nullable(),
  maxOfInv: yup.string().matches(percent, "Та дахин нэг шалгана уу!").typeError("Тоо оруулна уу.").required("Заавал бөглөнө!"),
  minOfInv: yup
    .number()
    .typeError("Тоо оруулна уу.")
    .when("maxOfInv", (maxOfInv, field) =>
      maxOfInv > 0 ? field.lessThan(maxOfInv, "Нэхэмжлэхийн Мах хувиас бага оруулна уу!").required("Заавал бөглөнө!") : field,
    ),
  maxTenor: yup.number().typeError("Тоо оруулна уу!").max(999, "Та 999-с бага утга уруулна уу!").required("Заавал бөглөнө!").nullable(),
  minTenor: yup
    .number()
    .typeError("Тоо оруулна уу!")
    .when("maxTenor", (maxTenor, field) =>
      maxTenor > 0 ? field.lessThan(maxTenor, "Нэхэмжлэхийн Max тенороос бага оруулна уу!").required("Заавал бөглөнө!") : field,
    ),
  maxDays: yup.number().typeError("Тоо оруулна уу!").max(999, "Та 999-с бага утга уруулна уу!").required("Заавал бөглөнө!").nullable(),
  minDays: yup
    .number()
    .typeError("Тоо оруулна уу!")
    .when("maxDays", (maxDays, field) =>
      maxDays > 0 ? field.lessThan(maxDays, "Та нэхэмжлэхийн Мax хугацаанаас бага утга оруулна уу!").required("Заавал бөглөнө!") : field,
    ),
});

export function ProductSupplierLedInformationForm({
  onSubmit: _onSubmit,
  action,
}: {
  onSubmit: (values: LbfProductInformationData, type: boolean) => void;
  action: any;
}) {
  const navigate = useNavigate();
  const nowMoment = new Date();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [type, setType] = React.useState<boolean>(false);
  const { programRegistrationStatus, productStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [data] = React.useState<any>({
    category: "Зээл суурьтай",
    type: "SUPPLIER_LED",
    name: "",
    resolutionNo: "",
    description: "",
    minInvAmount: "",
    minOfInv: "",
    maxOfInv: "",
    minTenor: "",
    maxTenor: "",
    financeMinLimit: "",
    minDays: "",
    maxDays: "",
    bankProductTypeId: "",
    ...(action && action[0] === "update" ? { ...action[1], category: "Зээл суурьтай" } : {}),
  });

  const onSubmit = (values: LbfProductInformationData) => {
    _onSubmit(values, type);
  };

  const { data: bankProductType } = useSwr(`/api/lbf/reference/bank_product_type/select/BUYER-LED`, async () => {
    if ((user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_REFERENCE").length > 0) {
      try {
        let res = await BankProductApi.select({ query: "", limit: 20 });
        return res;
      } catch (error) {
        Message.error((error as HttpHandler)?.message!);
      }
    }
    return [];
  });

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values }) => {
        return (
          <Flex sx={{ position: "relative" }} direction="column" gap="md">
            <Grid>
              <Grid.Col span={12} mt="xs">
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="СИСТЕМИЙН МЭДЭЭЛЭЛ" />
              </Grid.Col>

              <Grid.Col span={4}>
                <TextField name="refCode" label="Бүтээгдэхүүний код:" placeholder="Системээс авто үүснэ" disabled withAsterisk={false} />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextField name="type" label="Бүтээгдэхүүний төрөл:" placeholder="Төрөл" disabled withAsterisk={false} />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextField name="category" label="Бүтээгдэхүүний ангилал:" placeholder="Төрөл" disabled withAsterisk={false} />
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Бүртгэсэн ажилтан:">
                  <Box style={{ alignItems: "center" }}>
                    {action && action[0] === "create" ? (
                      <>
                        <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                          {user?.lastName !== null ? <>{user?.lastName[0]}.</> : ""} {user?.firstName || "-"}
                        </Text>
                      </>
                    ) : (
                      <>
                        <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                          {values?.regUser?.lastName !== null ? <>{values?.regUser?.lastName[0]}.</> : ""} {values?.regUser?.firstName || "-"}
                        </Text>
                      </>
                    )}
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Бүртгэсэн:">
                  <Box style={{ alignItems: "center" }}>
                    <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                      {action && action[0] === "create" ? dateSecFormat(nowMoment) : dateSecFormat(values?.createdAt)}
                    </Text>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}></Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Бүртгэлийн статус:">
                  <Box style={{ alignItems: "center" }}>
                    <Badge
                      size="md"
                      radius="sm"
                      variant="outline"
                      color={
                        (programRegistrationStatus || []).find((item: IReference) =>
                          item.code === action && action[0] === "update" ? values.registrationStatus : "DRAFT",
                        )?.color
                      }>
                      {
                        (programRegistrationStatus || []).find((item: IReference) =>
                          item.code === action && action[0] === "update" ? values.registrationStatus : "DRAFT",
                        )?.name
                      }
                    </Badge>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Бүтээгдэхүүний төлөв:">
                  <Box style={{ alignItems: "center" }}>
                    <Badge
                      size="md"
                      radius="sm"
                      variant="outline"
                      color={
                        (productStatus || []).find((item: IReference) =>
                          item.code === action && action[0] === "update" ? values.lbfProductStatus : "INACTIVE",
                        )?.color
                      }>
                      {
                        (productStatus || []).find((item: IReference) =>
                          item.code === action && action[0] === "update" ? values.lbfProductStatus : "INACTIVE",
                        )?.name
                      }
                    </Badge>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}></Grid.Col>

              <Grid.Col span={12} mt="xs">
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="БАНКНЫ АЖИЛТНЫ БҮРТГЭХ МЭДЭЭЛЭЛ" />
              </Grid.Col>

              <Grid.Col span={4}>
                <TextField name="name" label="Бүртээгдэхүүний нэр:" placeholder="Бүтээгдэхүүнд тохирох нэрийг оруулна уу." />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextField name="resolutionNo" label="Тушаалын дугаар:" placeholder="Бүтээгдэхүүн баталсан тушаалын дугаар" />
              </Grid.Col>
              <Grid.Col span={4}>
                <SelectField
                  name="bankProductTypeId"
                  label="Банк бүтээгдэхүүн: "
                  placeholder="сонгох"
                  options={(bankProductType || [])?.map((item: IBankProductType) => {
                    return {
                      label: item.name,
                      value: item.id,
                    };
                  })}
                  searchable
                />
              </Grid.Col>

              <Grid.Col span={12}>
                <TextareaField name="description" placeholder="Тайлбар оруулах" label="Тайлбар оруулах" withAsterisk={false} maxLength={255} />
              </Grid.Col>

              <Grid.Col span={12} mt="xs">
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ШАЛГУУР НӨХЦӨЛҮҮД" />
              </Grid.Col>

              <Grid.Col span={4}>
                <NumberCurrencyField
                  name="minInvAmount"
                  label="Нэхэмжлэхийн доод үлдэгдэл:"
                  placeholder="Үнийн дүнг оруулна уу"
                  precision={2}
                  min={0}
                  max={999999999999}
                  required
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <NumberPercentField name="maxOfInv" label="Нэхэмжлэхийн Мах хувь:" placeholder="Хувийг оруулна уу" max={100} min={0} precision={2} required />
              </Grid.Col>
              <Grid.Col span={4}>
                <NumberPercentField name="minOfInv" label="Нэхэмжлэхийн Мin хувь:" placeholder="Хувийг оруулна уу" max={100} min={0} precision={2} required />
              </Grid.Col>

              <Grid.Col span={4}>
                <TextField
                  name="maxTenor"
                  label="Нэхэмжлэхийн Мах тенор:"
                  placeholder="Хоногийг оруулна уу"
                  rightSection={
                    <Text c="dimmed" style={{ marginRight: "25px", fontSize: 15 }}>
                      {"Хоног"}
                    </Text>
                  }
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextField
                  name="minTenor"
                  label="Нэхэмжлэхийн Мin тенор:"
                  placeholder="Хоногийг оруулна уу"
                  rightSection={
                    <Text c="dimmed" style={{ marginRight: "25px", fontSize: 15 }}>
                      {"Хоног"}
                    </Text>
                  }
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <NumberCurrencyField
                  name="financeMinLimit"
                  label="Санхүүжилтийн доод лимит:"
                  placeholder="Үнийн дүнг оруулна уу"
                  precision={2}
                  min={0}
                  max={999999999999}
                  required
                />
              </Grid.Col>

              <Grid.Col span={4}>
                <TextField
                  name="maxDays"
                  label="Нэхэмжлэхийн Мax хугацаа:"
                  placeholder="Хоногийг оруулна уу"
                  rightSection={
                    <Text c="dimmed" style={{ marginRight: "25px", fontSize: 15 }}>
                      {"Хоног"}
                    </Text>
                  }
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextField
                  name="minDays"
                  label="Нэхэмжлэхийн Мin хугацаа:"
                  placeholder="Хоногийг оруулна уу"
                  rightSection={
                    <Text c="dimmed" style={{ marginRight: "25px", fontSize: 15 }}>
                      {"Хоног"}
                    </Text>
                  }
                />
              </Grid.Col>
              <Grid.Col span={4}></Grid.Col>

              <Grid.Col span={12}>
                <Alert
                  styles={{ message: { color: "#44566c", fontWeight: 500 } }}
                  radius="md"
                  color="indigo"
                  withCloseButton={false}
                  title="Таны анхаарах зүйл">
                  Тушаалаар батлагдсан мэдээллийг үндэслэн үндсэн мэдээллийг оруулж дуусаад "Үргэлжлүүлэх" сонголтыг хийнэ үү.
                </Alert>
              </Grid.Col>
            </Grid>
            <Divider color="gray" />
            <Flex direction="row" justify="space-between">
              <Button variant="default" onClick={() => navigate("/cf/product/SUPPLIER")}>
                Болих
              </Button>
              <Flex gap="md">
                <Button
                  variant="outline"
                  type="submit"
                  onClick={() => {
                    setType(false);
                  }}>
                  Хадгалах
                </Button>
                <Button
                  type="submit"
                  onClick={() => {
                    setType(true);
                  }}>
                  Үргэжлүүлэх
                </Button>
              </Flex>
            </Flex>
          </Flex>
        );
      }}
    </Form>
  );
}
